import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'
import { handleClose, openReserveCalendarItem, openReserveResource } from '@reservedb/popup-handler';
import { getAmpm, getFullTimetextDb, getTimetext } from '@reservedb/disp-helper';
import modalHelper from '@appbase/util/modal-helper';
import { IEDate } from '@appbase/util';

import OccurrenceSelect from '@/modules/reservedb/components/OccurrenceSelectDb.vue';
import { Dictionary } from 'vue-router/types/router';

@Component({
    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        isDailyEventLoading: types.CALENDAR_DAILYEVENT_LOADING,
        colorMapItems: types.RESOURCE_COLOR_MAPITEM,
    }),

    methods: mapActions({
        selectDate: types.CALENDAR_DATE_SELECT
    })
})
export default class MonthItemDb extends Vue {
    @Prop({ type: Object }) item!: reservedb.DateItem;


    colorMapItems!: Array<reservedb.ColorMapItem>
    selectDate!: (payload: any) => Promise<any>;

    isLoading!: boolean;
    isDailyEventLoading!: boolean;

    eventsModel: Array<reservedb.ResponseBookingItem> = [];
    dayTextHash: any = {
        0: this.$t("일요일").toString(), 1: this.$t("월요일").toString(), 2: this.$t("화요일").toString(), 3: this.$t("수요일").toString(), 4: this.$t("목요일").toString(), 5: this.$t("금요일").toString(), 6: this.$t("토요일").toString()
    }

    @Watch('item')
    itemChanged(newVal: reservedb.DateItem) {


        this.eventsModel =  <Array<reservedb.ResponseBookingItem>>newVal.events

        this.eventsModel = this.eventsModel.slice(0, 2)


    }

    created() {
        this.dayTextHash = {
            0: this.$t('W_Schedule_Sunday').toString(),
            1: this.$t('W_Schedule_Monday').toString(),
            2: this.$t('W_Schedule_Tuesday').toString(),
            3: this.$t('W_Schedule_Wednesday').toString(),
            4: this.$t('W_Schedule_Thursday').toString(),
            5: this.$t('W_Schedule_Friday').toString(),
            6: this.$t('W_Schedule_Saturday').toString()
        }

        if (this.item != null)
            this.itemChanged(this.item);
    }

    getDate() {
        return this.item.date.getDate();
    }

    getTimetext(event: reservedb.ResponseBookingItem, type: string) {
        const strStartDatetime = `${event.startDate}T${event.startTime}+09:00`
        const strEndDatetime = `${event.endDate}T${event.endTime}+09:00`
        return getTimetext(type == "start" ? strStartDatetime : strEndDatetime);
    }

    getAmpm(event: reservedb.ResponseBookingItem, type: string) {
        const strStartDatetime = `${event.startDate}T${event.startTime}+09:00`
        const strEndDatetime = `${event.endDate}T${event.endTime}+09:00`
        return getAmpm(type == "start" ? strStartDatetime : strEndDatetime);
    }

    getFullTimetext(event: reservedb.ResponseBookingItem) {
        const strStartDatetime = `${event.startDate}T${event.startTime}+09:00`
        const strEndDatetime = `${event.endDate}T${event.endTime}+09:00`
        return getFullTimetextDb(strStartDatetime, strEndDatetime)
    }

    onSelectDateClick() {

        if (this.isDailyEventLoading)
            return;

        this.selectDate(this.item);
    }

    //일에 더블클릭이 왜있는거지
    // onSelectDblClick() {
    //     if(this.isLoading)
    //         return;

    //     var today = new Date();
    //     var openDate = IEDate(this.item.date);
    //     openDate.setHours(today.getHours());

    //     openReserveResource(openDate);
    // }

    onEventDblClick(event: reservedb.ResponseBookingItem) {
        if (event.isRecursive == true) {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: event });
        } else {
            openReserveCalendarItem(event);
        }
    }

    getColorClass(event: reservedb.ResponseBookingItem) {
        var colorClass: any = {};

        const mapItems = this.colorMapItems.filter(mapItem => {
            return mapItem.resourceId == event.resourceId
        })

        if (mapItems.length > 0) {
            colorClass[mapItems[0].colorClass] = true;
        }

        return colorClass;
    }

    onMoreClick() {
        const queryStr = <Dictionary<string>>this.$route.query
        this.selectDate(this.item).then(res => {

            handleClose(queryStr);
        });
        
        this.$router.push({
            name: "reservedb-day",
            query : queryStr
            
        })
    }

    isAllDayEvent(event: reservedb.ResponseBookingItem) {

        if (event.startTime == "00:00:00" && event.endTime == "23:59:59") return true
        return false
    }


    getMoreCount(cnt: number) {

        return cnt - 2
    }
}