import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import * as types from '@reserve/store/types'

import ViewHeader from '@reserve/components/ViewHeader.vue'
import RightPane from '@reserve/components/RightPane.vue'
import ListView from '@reserve/components/ListView/ListView.vue'
import { LoadingBar } from '@appbase/components/loading'
import SideMenu from '@reserve/components/SideMenu/SideMenu.vue'

@Component({
    components: {
        ViewHeader,
        RightPane,
        ListView,
        LoadingBar,
        SideMenu
    },

    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        listViewOpen: types.MAIN_LISTVIEW_OPEN
    })
})
export default class CalendarMaster  extends Vue {
    isLoading!: boolean;
    listViewOpen!: boolean;
}