import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as types from '@reservedb/store/types'

import MiniCalendar from '@reservedb/components/SideMenu/MiniCalendar.vue'
import ResourceGroupDb from '@reservedb/components/SideMenu/ResourceGroupDb.vue'
import * as userTypes from '@account/store/types'
import api from '@reservedb/api'
import ResourceDetailModalView from '@reservedb/views/Modal/ResourceDetailModalView.vue'
import * as evtBus from '@appbase/util/eventBus';

import * as permissionHelper from '@appbase/util/permission-helper'


@Component({
    components: {
        MiniCalendar,
        ResourceGroupDb,
        ResourceDetailModalView
    },

    computed: mapGetters({


        current: userTypes.CURRENT
    }),

    methods: {
        ...mapMutations({
            selectedResourceIds: types.RESOURCE_SELECTED_RESOURCEIDS,
            setColorMapItems: types.RESOURCE_COLOR_MAPITEM
        })
    }
})
export default class SideMenuDb extends Vue {
    setColorMapItems!: (items: Array<reservedb.ColorMapItem>) => void
    selectedResourceIds!: (ids: Array<number>) => void
    current!: account.TCurrent

    groupItems: Array<reservedb.ResourceGroupItem> = []
    // reserveBoards: Array<reservedb.ResponseResourceBoardItem> = []
    // resourceFolders : Array<reservedb.ResponseResourceFolderItem> = []
    //selectedResourceIds : Array<number> = []
    showModal: boolean = false
    modalData: any = null

    initReserveBoardData() {
        //데이터 초기화
        this.groupItems = []
        //sysid 가 boardid
        const boardId = parseInt(<string>this.$route.query.SYSID)
        const comcode = this.current.company.comCode

        //권한 정보 조회
        api.resourcedb.getResourcePermissions(boardId).then(permissionResult => {

            //자원폴더 가져오기
            api.resourcedb.getResourceFolder(boardId).then(folderResult => {
                ///폴더가 있으면 그다음 자원 가져오기


                if (folderResult.length > 0) {
                    // this.resourceFolders = folderResult

                    api.resourcedb.getResourceBoards(comcode, boardId).then(result => {


                        //권한정보 처리
                        let resourcePermissions: Array<reservedb.ResourcePermission> = []


                        if (result.length > 0) {

                            //개수가 일치하는 경우
                            if (resourcePermissions.length == result[0].mapItems.length) {

                                //읽기 권한이 있는 리소스만 필터링
                                //필터안함
                                // hasReadPermissionResources = permissionResult.filter(item => {
                                //     return item.read == true
                                // })
                                resourcePermissions = permissionResult 


                            } else { //권한정보가 없는 경우는 모두 허용이기 때문에 조회된 데이터랑 다름
                                resourcePermissions = result[0].mapItems.map(mapItem => {
                                    const findVal = permissionResult.filter(filterItem => {
                                        return filterItem.resourceId == mapItem.reserveResourceId
                                    })

                                    return {

                                        resourceId: mapItem.reserveResourceId,
                                        read: findVal.length > 0 ? findVal[0].read : true,
                                        write: findVal.length > 0 ? findVal[0].write : true


                                    }
                                })
                            }

                            //관리자 권한인 경우 우선 구현만하고 주석처리
                            // if (false) {
                            if (permissionHelper.IsSuperadmin() == true) {
                                resourcePermissions = resourcePermissions.map(mapItem => {
                                    return {
                                        ...mapItem,
                                        read: true,
                                        write: true
                                    }
                                })

                            }
                            

                            

                        }

                        //읽기 권한이 있는 자원만 필터링 함
                        let hasPermissionResources :Array<reservedb.ResponseResourceBoardMapItem> = []
                        result[0].mapItems.forEach(item=>{
                            var filterItem = resourcePermissions.filter(filterItem=>{
                                return filterItem.resourceId == item.reserveResourceId && filterItem.read == true
                            })

                            if(filterItem.length >0){
                                hasPermissionResources.push(item)
                            }
                        })

                        //자원 색상 MAPPING
                        //색상(class)정보는 DB app_config 에 저장되어있음


                        let colorMapItems: Array<reservedb.ColorMapItem> = []
                        if (hasPermissionResources.length > 0) {

                            if (hasPermissionResources.length > 0) {
                                let arrayColors = JSON.parse(<string>this.getAppConfig("ReserveColorArray"))

                                hasPermissionResources.forEach(mapItem => {
                                    let inputData = {
                                        resourceId: mapItem.reserveResourceId,
                                        colorClass: arrayColors.shift()
                                    }

                                    colorMapItems.push(inputData)

                                })

                                this.setColorMapItems(colorMapItems)
                            }

                        }




                        folderResult.forEach(folder => {

                            folder.selected = true

                            let filterItems = hasPermissionResources.filter(resource => {
                                return resource.guid == folder.guid
                            })

                            if(filterItems.length > 0){
                                this.groupItems.push({
                                    itemInfo: filterItems.map(item => {
                                        return {
                                            ...item,
                                            selected: true
                                        }
                                    }),
                                    folderInfo: folder
    
                                })
                            }
                            
                        })
                    })
                }

            })
        })




    }

    created() {

        this.initReserveBoardData();


        //create event bus
        const callBack = (data: any) => {
            //여기에서 자원 상세 정보까지 조회해서 전달하는 걸로해야될듯 
            api.resourcedb.getResourceDetail(data).then(async result => {
                if (result) {
                    this.modalData = result
                    this.showModal = true
                } else {
                    await (window as any).alertAsync('자원_상세조회에 실패하였습니다.')
                }
            })

        }
        evtBus.createEventBus(window, types.EVTBUS_RESOURCE_DETAIL_SHOW, callBack)

    }

    destroyed() {
        evtBus.removeEventBus(window, types.EVTBUS_RESOURCE_DETAIL_SHOW, () => { })
    }

    goWrite() {
        this.$router.push({
            name: 'reservedb-write',
            query: {
                SYSID: this.$route.query.SYSID,
                MENUID: this.$route.query.MENUID
            }

        }).catch(() => { })
    }

    CloseModal() {
        this.showModal = false
    }

    selectAll(data: any) {
        // debugger
        // return
        var targetFolder = this.groupItems.filter(item => {
            return item.folderInfo.guid == data.id
        })


        if (targetFolder.length > 0) {
            // this.reserveBoards = this.reserveBoards.filter(board=>{
            //     return board.reserveBoardId != data.reserveBoardId
            // })



            targetFolder[0].itemInfo = targetFolder[0].itemInfo.map(mapItem => {
                return {
                    ...mapItem,
                    selected: data.selected
                }
            })

            // this.reserveBoards.push(targetBoard[0])
        }
    }

    //mapItems 에 selected 속성까지 확인하기 위해서 deep 옵션 사용
    @Watch("groupItems",
        {
            deep: true
        })
    onChangedReserveBoards() {
        let selectList: Array<number> = []


        this.groupItems.forEach(item => {
            item.itemInfo.forEach(mapItem => {
                if (mapItem.selected == true) {
                    selectList.push(mapItem.reserveResourceId)
                }
            })
        })

        // this.selectedResourceIds = selectList
        this.selectedResourceIds(selectList)
    }

    @Watch("selectedResourceIds")
    onChangedSelectedResourceId() {
        //alert('자원 선택 변경!') 


    }

    @Watch("$route.query.SYSID")
    onChangedBoardId() {
        //alert('게시판 변경')
        this.initReserveBoardData()
    }




}