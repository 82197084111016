import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { getAmpm, getFullTimetext, getFullTimetextDb, getTimetext } from '@reservedb/disp-helper';
import { openReserveCalendarItem } from '@reservedb/popup-handler';
import modalHelper from '@appbase/util/modal-helper';
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reservedb/store/types'
import OccurrenceSelect from '@/modules/reservedb/components/OccurrenceSelectDb.vue';

@Component({
    computed: mapGetters({
       
     
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
    }),

})
export default class DayItemDb  extends Vue {
    @Prop({ type: Object }) item!: reservedb.ResponseBookingItem;

    colorMapItems! : Array<reservedb.ColorMapItem>
     

    created() {
         
        // this.colorClass[this.item.color] = true;
        //this.colorClass = this.getColorClass(this.item)
    }

    getColorClass(event: reservedb.ResponseBookingItem) {
        var colorClass: any = {};
        
        const mapItems = this.colorMapItems.filter(mapItem=>{
            return mapItem.resourceId == event.resourceId
        })

        if(mapItems.length > 0){
            colorClass[mapItems[0].colorClass] = true;
        }

        return colorClass;
    }

    getTimetext( ) {
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        return getTimetext(strStartDatetime);
    }

    getAmpm( ) {
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        return getAmpm(strStartDatetime);
    }

    getFullTimetext() {        
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        const strEndDatetime = `${this.item.endDate}T${this.item.endTime}+09:00`
        return getFullTimetextDb(strStartDatetime, strEndDatetime)
    }

    getItemHeight(){
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        const strEndDatetime = `${this.item.endDate}T${this.item.endTime}+09:00`

        const gapMin = ((new Date(strEndDatetime)).getTime() - (new Date(strStartDatetime)).getTime()) / 1000
        
        const diffMin = gapMin / 60
        
        return diffMin / 30 
    }

    onEventDblClick() {
        if (this.item.isRecursive == true) {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: this.item });
        } else {
            openReserveCalendarItem(this.item);
        }  
    }
}