import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import TimePicker from '@reserve/components/TimePicker.vue'
import { uGetDateFormat } from '@appbase/util'

@Component({
    components: {
        ModalLayout,
        DatePicker,
        TimePicker
    }
})
export default class RecurringOption  extends Vue {    
    @Prop({ type: Object }) initOption!: reserve.CalendarItemRecurrence | null;

    recurringStart: string = uGetDateFormat('yyyy-MM-dd', new Date());
    recurringEnd: string = uGetDateFormat('yyyy-MM-dd', new Date());
    recurringType: string = "1";    
    reccuringEndType: string = "1";
    numberOfRecurrence: string = "";
    daysOfTheWeekArray: boolean[] = [false, false, false, false, false, false, false];
    weekStringArray: string[] = ["일", "월", "화", "수", "목", "금", "토"];
    dayOfMonth: string = "";
    monthValue: string = "1";
    interval: string = "";
    
    created() {
        const subtext = (id: string) => {
            const text = this.$t(id).toString()
            if(this.$i18n.locale === 'cn') {
                return text.substring(text.length -1)
            } else {
                return text.substring(0, 1)
            }
        }
        this.weekStringArray = [
            subtext('W_Schedule_Sunday'),
            subtext('W_Schedule_Monday'),
            subtext('W_Schedule_Tuesday'),
            subtext('W_Schedule_Wednesday'),
            subtext('W_Schedule_Thursday'),
            subtext('W_Schedule_Friday'),
            subtext('W_Schedule_Saturday')
        ]

        var timeNow = new Date();
        timeNow.setMonth(timeNow.getMonth() + 1);
        this.recurringEnd = uGetDateFormat('yyyy-MM-dd', timeNow);
        this.mountOption();       
    }  

    mountOption() {
        if(this.initOption != null) {
            this.recurringStart = this.initOption.startDate;
            this.recurringEnd = this.initOption.endDate || uGetDateFormat('yyyy-MM-dd', new Date());
            this.reccuringEndType = this.initOption.ui_endType;
            this.recurringType = this.initOption.ui_recurringType.toString();
            this.numberOfRecurrence = this.initOption.numberOfOccurrences ? 
                this.initOption.numberOfOccurrences.toString() : "";
            this.dayOfMonth = this.initOption.dayOfMonth ?
                this.initOption.dayOfMonth.toString() : "";
            this.monthValue = this.initOption.monthValue ?
                this.initOption.monthValue.toString() : "";
            this.interval = this.initOption.interval.toString();
            
            if(this.initOption.daysOfTheWeekArray) {
                this.initOption.daysOfTheWeekArray.forEach(d => {
                    this.daysOfTheWeekArray[d] = true;
                });
            }
        }
    }

    async validateOption() {    
        if(this.recurringType === '1') {
            if(this.interval.trim().length == 0 || Number(this.interval) == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_LowInterval').toString());
                return false;
            }
        }         
        
        if(this.recurringType === '2') {
            if(this.interval.trim().length == 0 || Number(this.interval) == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_LowInterval').toString());
                return false;
            }

            if(this.daysOfTheWeekArray.filter(d => d).length == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_SelectLowDay').toString());
                return false;
            }
        }

        if(this.recurringType === '3') {
            if(this.interval.trim().length == 0 || Number(this.interval) == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_LowInterval').toString());
                return false;
            }

            if(this.dayOfMonth.trim().length == 0 || Number(this.dayOfMonth) == 0 || Number(this.dayOfMonth) > 31) {
                await (window as any).alertAsync(this.$t('M_Schedule_SelectMonthDay').toString());
                return false;
            }
        }

        if(this.recurringType === '4') {
            if(this.dayOfMonth.trim().length == 0 || Number(this.dayOfMonth) == 0 || Number(this.dayOfMonth) > 31) {
                await (window as any).alertAsync(this.$t('M_Schedule_SelectMonthDay').toString());
                return false;
            }
        }

        return true;
    }

    async onSaveClick() {
        var returnOption: reserve.CalendarItemRecurrence = {
            startDate: this.recurringStart,
            endDate: this.reccuringEndType === '3' ? this.recurringEnd : null,
            ui_endType: this.reccuringEndType,
            ui_useRecurringButton: true,
            ui_useDefaultDate: false,
            ui_useRecurringDisplay: true,
            ui_useRecurring: true,
            ui_recurringType: Number(this.recurringType),            
            monthValue: Number(this.monthValue),
            firstDayOfWeekValue: 1,
            daysOfTheWeekArray: this.daysOfTheWeekArray.map((d, idx) => d ? idx : -1).filter(f => f >= 0),
            dayOfMonth: Number(this.dayOfMonth),
            interval: Number(this.interval),
        } as reserve.CalendarItemRecurrence;

        if(this.reccuringEndType === '2') {
            returnOption.numberOfOccurrences = Number(this.numberOfRecurrence);
        }

        if(await this.validateOption()) {
            this.$emit('save', returnOption);     
        }           
    }

    onCancelClick() {
        this.$emit('close');
    }
}