import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import * as orgapi from '@orgmap/api'
import { openPopup } from '@appbase/util';
import { createEventBus, removeEventBus } from '@appbase/util/eventBus';
import * as accountTypes from '@account/store/types'
import { mapGetters } from 'vuex';

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items? : orgmap.TWishContainerItem[]
}

export interface TWishContainerOptions {
    addableDept: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const EVENT_ORG_POPUP = '$reserve/org/popup'

@Component({
    computed: mapGetters({
        user: accountTypes.USER
    })
})
export default class RecipientFieldDb  extends Vue {
    @Prop({ type: Boolean, default: false }) readOnly!: boolean;
    @Prop({ type: String, default: "" }) fieldGuid!: string;
    @Prop({ type: String, default: "" }) placeholder!: string;
    @PropSync('recipients', { type: Array }) recipientsSync!: orgmap.UserItem[];

    user!: account.User;

    onSearching: boolean = false;
    searchText: string = "";
    autoCompletesModel: orgmap.UserItem[] = [];

    created() {
        createEventBus(window, `${EVENT_ORG_POPUP}/${this.fieldGuid}`, this.handlePopupResult);
    }

    beforeDestroy() {
        removeEventBus(window, `${EVENT_ORG_POPUP}/${this.fieldGuid}`, this.handlePopupResult);
    }

    handlePopupResult(data: any) {
        console.log('popupResult', data);
        if(data && data.length > 0) {
            var popupResult: any[] = data[0].items;
            popupResult.forEach(p => {
                if(!(this.recipientsSync.findIndex(r => p.id === r.id) >= 0)) {
                    this.recipientsSync.push({
                        id: p.id,
                        deptName: p.deptName,
                        displayName: p.name,
                        email: p.value,
                        titleName: p.title,
                        comCode: '',
                        deptCode: p.deptCode,
                        parentDeptCode: '',
                        personCode: p.id,
                        selected: false                     
                    })
                }
            })
        }           
    }

    onKeyDown(e: KeyboardEvent) {        
        if(e.key.toLocaleLowerCase() === 'enter') {
            this.searchAction();        
        } else if (e.key.toLocaleLowerCase() === 'backspace') {
            if(this.recipientsSync.length > 0 && this.searchText.length == 0) {
                this.recipientsSync.splice(this.recipientsSync.length - 1, 1);
            }
        }        
    }

    onKeyBlur() {
        var _this = this;
        setTimeout(() => {
            _this.autoCompletesModel = [];
        }, 150);                
    }

    setAutoCompleteBoxPos() {
        var inpElem = this.$refs.inpText as HTMLInputElement;
        var inpElemRect = inpElem.getBoundingClientRect();
        var ulElem = this.$refs.ulCompleteBox as HTMLUListElement;
         
        if(ulElem) {
            ulElem.style.top = `${inpElemRect.top - 10}px`;
            ulElem.style.left = `${inpElem.offsetLeft + 55}px`;
        }        
    }

    searchAction() {
         
        if(this.searchText.trim().length < 2)
            return;

        orgapi.default.hr.searchOrg({
            query: this.searchText,            
            size: 10
        }, this.user.comCode).then(res => {
            if(res.items.length == 1) {
                var rec = res.items[0];
                this.handleUserPush(rec);                                                
            } else if (res.items.length >= 2) {
                this.setAutoCompleteBoxPos();
                this.autoCompletesModel = res.items;
            }
        });
    }

    autoCompleteSelect(user: orgmap.UserItem) {
        this.handleUserPush(user);        
    }

    handleUserPush(user: orgmap.UserItem) {
        if(!(this.recipientsSync.findIndex(m => m.id === user.id) >= 0)) {
            // this.recipientsSync.push(user);
            this.recipientsSync.push({
                id: user.id,
                deptName: user.deptName,
                displayName: <string>user.displayName,
                email: user.email,
                titleName: user.titleName,
                comCode: '',
                deptCode: user.deptCode,
                parentDeptCode: '',
                personCode: user.id,
                selected: false                     
            })
        }

        this.searchText = "";
        this.autoCompletesModel = [];
    }

    onDeleteClick(user: orgmap.UserItem) {
        var idx = this.recipientsSync.findIndex(m => m.id === user.id);

        if(idx >= 0) {
            this.recipientsSync.splice(idx, 1);
        }
    }    

    onOrgmapClick() {
        const orgmapOption: TWishContainerOptions = {
            addableDept: true,
            containers: [
               { id: 0, name: this.$t('참석자').toString(), height: '1' }               
            ] 
        }

        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode', 'title'],
        }

        openPopup('reserveRecipients', '/orgmap', 
            { event: `${EVENT_ORG_POPUP}/${this.fieldGuid}`, option: orgmapOption, props }, 
            { width: 1024, height: 768 });
    }
}