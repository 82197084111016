import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { IEDate, uGetDateFormat } from '@appbase/util';
import { openReserveResource } from '@reserve/popup-handler';

import DayItem from './DayItem.vue'

@Component({
    components: {
        DayItem
    }
})
export default class DayRawItem  extends Vue {
    @Prop({ type: Object }) item!: reserve.DateItem;

    eventsModel: reserve.CalendarItem[] = [];

    @Watch('item')
    itemChanged(newVal: reserve.DateItem) {
        if(newVal.events) {
            this.eventsModel = newVal.events.filter(e => e.id !== 'null');         
        }
    }

    created() {
        if(this.item != null)
            this.itemChanged(this.item);
    }

    onSelectDblClick() {
        var target = IEDate(this.item.date);
        openReserveResource(target);
    }
}