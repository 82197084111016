import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reserve/store/types'
import { IEDate, uGetDateFormat } from '@appbase/util';
import { openReserveCalendarItem } from '@reserve/popup-handler';
import modalHelper from '@appbase/util/modal-helper';
import ReserveHelper from '@reserve/reserve-helper';

import OccurrenceSelect from '@reserve/components/OccurrenceSelect.vue';

@Component({
    computed: mapGetters({
        dailyEvents: types.CALENDAR_DAILY_EVENT_LIST,
        selectedResources: types.SELECTED_RESOURCE_LIST
    }),

    methods: mapActions({
        loadDailyEvents: types.CALENDAR_DAILY_EVENT_LOAD
    })
})
export default class ResourceEventList  extends Vue {
    loadDailyEvents!: () => Promise<any>;

    dailyEvents!: reserve.CalendarItem[];
    selectedResources!: ewsResource.EwsResourceItem[];

    eventsModel: reserve.CalendarItem[] = [];

    @Watch('dailyEvents')
    onEventsChanged(newVal: reserve.CalendarItem[]) {
        this.eventsModel = newVal.filter(m => m.id !== 'null');
    }

    @Watch('selectedResources')
    onSelectedResourceChanged(newVal: ewsResource.EwsResourceItem[]) {
        this.loadDailyEvents();
    }

    created() {
        this.loadDailyEvents();
    }

    getAmpm(eventTime: string) {
        var dateTime = IEDate(eventTime);
        return dateTime.getHours() > 12 ? this.$t('W_PM').toString() : this.$t('W_AM').toString();
    }

    getTimetext(event: reserve.CalendarItem) {
        if(event.isAllDayEvent) {
            return this.$t('W_Schedule_Allday').toString();
        } else {
            return `${this.getAmpm(event.start)} ${uGetDateFormat('H:i', IEDate(event.start))}`;
        }        
    }

    getDuration(event: reserve.CalendarItem) {
        if(event.isAllDayEvent) {
            return "";
        } else {
            var start = IEDate(event.start);
            var end = IEDate(event.end);
            var duration = end.getTime() - start.getTime();

            return `${(duration / (1000 * 60 * 60)).toFixed(1)} ${this.$t('W_Hour').toString()}`;
        }        
    }

    getColorClass(event: reserve.CalendarItem) {
        var colorClass: any = {};
        colorClass[event.color] = true;
        return colorClass;
    }

    async onEventDblClick(event: reserve.CalendarItem) {
        var isUseAuth = await ReserveHelper.checkDetailViewAuth(event.id, event.resourceUpn);
        if (isUseAuth){
            if (event.appointmentType == 'Occurrence' || event.appointmentType == 'Exception') {
                modalHelper.show({ component: OccurrenceSelect, force: true, value: event });
            } else {
                openReserveCalendarItem(event);
            }        
        }
    }
}