import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reserve/store/types'
import modalHelper from '@appbase/util/modal-helper';

import SearchPanel from '@reserve/components/SearchView/SearchPanel.vue'
import GridView from '@appbase/components/GridView/GridView.vue'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';
import { getAmpm, getDayText } from '@reserve/disp-helper';
import { openReserveCalendarItem } from '@reserve/popup-handler';
import OccurrenceSelect from '@reserve/components/OccurrenceSelect.vue';

@Component({
    components: {
        SearchPanel,
        GridView
    },

    computed: mapGetters({
        events: types.CALENDAR_SEARCH_EVENTS
    }),

    methods: mapActions({
        searchEvents: types.CALENDAR_SEARCH_EVENTS_LOAD
    })
})
export default class SearchView  extends Vue {
    searchEvents!: (searchSet: reserve.ReserveSearchSet) => Promise<any>;

    events!: reserve.CalendarItem[];

    reserveModel: gridView.ModelItem<reserve.CalendarItem>[] = [];
    reserveHeaders: gridView.Header[] = [];

    @Watch('events')
    onEventsChanged(newVal: reserve.CalendarItem[]) {
        this.reserveModel = newVal;
    }

    created() {     
        this.reserveHeaders = [
            { displayName: this.$t('제목').toString(), rowKey: 'subject', align: 'left', slotName: 'reserveTitle' },
            { displayName: this.$t('예약자명').toString(), rowKey: 'organizer', align: 'left', slotName: 'reserveOrganizer', width: '250' },
            { displayName: this.$t('W_Schedule_Start').toString(), rowKey: 'start', align: 'left', width: '250', slotName: 'reserveStart' },
            { displayName: this.$t('W_Schedule_End').toString(), rowKey: 'end', align: 'left', width: '250', slotName: 'reserveEnd' },
            { displayName: this.$t('W_Schedule_Location').toString(), rowKey: 'location', align: 'left', width: '300' },
            { displayName: this.$t('파일첨부').toString(), rowKey: 'hasAttachments', align: 'left', width: '120', slotName: 'reserveAttachment' }
        ]
        
        if(this.events != null) {
            this.onEventsChanged(this.events);
        }
    }

    getColorClass(event: reserve.CalendarItem) {
        var colorClass: any = {};
        colorClass[event.color] = true;
        return colorClass;
    }

    doSearch(searchSet: reserve.ReserveSearchSet) {
        this.searchEvents(searchSet);
    }

    getTimeText(reserveTime: string) {
        var datetime = IEDate(reserveTime);
        return `${uGetDateFormat('yyyy-MM-dd', datetime)} (${getDayText(reserveTime)[0]}) ${getAmpm(reserveTime)} ${uGetDateFormat('H:i', datetime)}`;
    }

    onReserveDblClick(item: gridView.ModelItem<reserve.CalendarItem>) {
        if (item.appointmentType == 'Occurrence' || item.appointmentType == 'Exception') {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: item });
        } else {
            openReserveCalendarItem(item);
        }          
    }
}