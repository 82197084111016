import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'

import ResourceItemDb from './ResourceItemDb.vue';


@Component({
    components: {
        ResourceItemDb
    },

    computed: mapGetters({
       
    }),

    methods: mapActions({
         
    })
})
export default class ResourceGroupDb  extends Vue {
    @Prop({ type: Object }) item!: reservedb.ResourceGroupItem;

    selected : boolean = false
    isLoading : boolean = false
    isCollapse : boolean = false

    // selectChanged(data:any){
    //     //전체 선택
    //     let mapItems : Array<any> = []
    //     this.item.mapItems.forEach(resource =>{
    //         mapItems.push({
    //             id : resource.reserveResourceId,
    //             selected : this.selected
    //         })
    //     })

    //     this.updateSelectedResourceId(mapItems)
           
    // }

    collapseBoard(){
        
        this.isCollapse = !this.isCollapse
    }

    updateSelectedResourceId(data: Array<any>){
        this.$emit("update",data)
        //data.selected = true / false
        //data.id = 1 (selected resource id)
    }

    
}