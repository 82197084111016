import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as types from '@reservedb/store/types'

import * as usertypes from '@account/store/types';
import { MasterTemplate } from '@appbase/templates/'
import ApprovalGrid from './ApprovalGrid.vue'
import ApprovalSearchFilter from './ApprovalSearchFilter.vue'
import api from '@reservedb/api'
import { ApprovalStatus, SearchFilter } from '@reservedb/types/enums'

import ResourceApprovalModalView from '@reservedb/views/Modal/ResourceApprovalModalView.vue'
import { LoadingBar } from '@appbase/components/loading';
import approval from '../../api/approval';

export interface SearchPayload {
    keyword: string
    filter: SearchFilter
    status: ApprovalStatus
}

@Component({
    components: {
        LoadingBar,
        MasterTemplate,
        ApprovalGrid,
        ApprovalSearchFilter,
        ResourceApprovalModalView
    },

    computed: mapGetters({
        isApprover: types.RESOURCE_CHECK_APPROVER,
        userCurrent: usertypes.CURRENT
    }),

    methods: mapMutations({
        setIsApprover: types.RESOURCE_SET_ISAPPROVER
    })
})
export default class ApprovalView extends Vue {
    userCurrent!: account.TCurrent;
    isApprover!: boolean | null
    setIsApprover!: (data: boolean) => void
    //시스템관리자 flag
    isSuerAdmin: boolean = false
    showModal: boolean = false
    gridRowCount: number = 10
    gridPageRange: number = 5
    selectedPageNumber = 1

    //searchFilter
    selectedStatus: number = 999
    selectedFilter: number = 0
    searchKeyword: string = ""

    pageData: reservedb.ResponseApprovalPageSet | null = null

    selecredRequestId: number = 0


    //로딩 처리
    isProgressing = false
    progressingMsg = this.$t("처리중입니다__잠시만_기다려주세요").toString() 

    created() {
        //타이틀 set
        this.setPage({
            title: this.$t("자원_예약승인").toString()  
        })

        const cur = this.userCurrent
        if (cur.permissions.isSuperAdmin == true) {
            this.isSuerAdmin = true
            //슈퍼관리자인경우 승인자로 간주
            this.setIsApprover(true)

            //데이터 초기화는 요기!
            this.initApprovalData()
        } else {

            //관리자가 아닌 흐름
            if (this.isApprover == null) {
                //조회가 안된 상태니깐 api 콜
                api.approval.isApprover().then(result => {
                    this.setIsApprover(result)

                    if (result == true) {
                        //데이터 초기화는 요기!
                        this.initApprovalData()
                    } else {
                        this.notApprover()
                    }
                })

            } else {
                if (this.isApprover == false) {
                    this.notApprover()
                }
            }
        }



    }

    async notApprover() {
        await (window as any).alertAsync(this.$t("자원_승인자가_아닙니다").toString())
        
        this.$router.go(-1)
    }

    initApprovalData(pageNum?: number) {
        
        //초기값 우선

        if (pageNum == null) {
            this.selectedPageNumber = 1
        } else {
            this.selectedPageNumber = pageNum
        }

        let param: reservedb.RequestApprovalPageSet = {
            approvalStatus: this.selectedStatus,
            searchFilter: this.selectedFilter,
            searchKeyword: this.searchKeyword,
            rowCount: this.gridRowCount,
            pageNumber: pageNum ? pageNum : 1
        }

        api.approval.getApprovals(param).then(result => {
            if (result != null) {
                this.pageData = result

                this.pageData.data = this.pageData.data.map(mapItem => {
                    return {
                        ...mapItem,
                        selected: false,
                        approvalStatus: <ApprovalStatus>this.convertStatus(<string>mapItem.approvalStatus)
                    }
                })
            }
        })
    }

    convertStatus(input: string) {
        switch (input.toLocaleLowerCase()) {
            case "wating":
                return 0
            case "accept":
                return 1
            case "reject":
                return 2
        }

    }

    pageMove(pageNum: number) {
        //this.selectedPageNumber = pageNum
        this.initApprovalData(pageNum)
    }

    // @Watch("isApprover")
    // onChangedIsApprover(){
    //     debugger
    //     if(this.isApprover && this.isApprover == true){
    //         alert("데이터 초기화")
    //     }
    // }
    onClickSearchBtn(payload: SearchPayload) {
        //alert(`${payload.keyword}\n${payload.filter}\n${payload.status}`)

        if (payload) {
            this.searchKeyword = payload.keyword
            this.selectedFilter = payload.filter
            this.selectedStatus = payload.status
        }

        this.initApprovalData()
    }

    selectAll(value: boolean) {

        //대기중 상태만 처리 
        //이미 승인, 반려가 된 아이템들은 선택 안되도록~
        const inputData = this.pageData?.data.map(mapItem => {
            if (mapItem.approvalStatus == 0) {
                return {
                    ...mapItem,
                    selected: value
                }
            } else {
                return {
                    ...mapItem
                }
            }

        })

        if (this.pageData) {
            this.pageData.data = <Array<reservedb.ApprovalPageData>>inputData
        }
    }


    async handleModal(value: boolean, isSingle?: boolean) {

        //싱글인경우 bypass~
        if (isSingle != true) {
            if (await this.checkSelectedItems() == false) return
        }

        this.showModal = value
    }

    async checkSelectedItemsWithData(isUpdate: boolean) {

        let targets: Array<number> = []

        if (this.pageData) {

            const selectedItems = this.pageData.data.filter(item => {
                return item.selected == true
            })

            if (selectedItems.length == 0) {
                await (window as any).alertAsync(this.$t("선택된_승인건이_없습니다").toString())
                
                return []
            }

            selectedItems.forEach(item => {
                //item.approvalStatus
                //결재 업데이트건인 경우는 대기중 상태인 아이템만 대상으로 리턴
                let inputItem = null
                if (isUpdate) {
                    if (item.approvalStatus == ApprovalStatus.Wating)
                        inputItem = <number>item.reserveRequestId

                } else {
                    inputItem = <number>item.reserveRequestId
                }

                if (inputItem) {
                    targets.push(inputItem)
                }
            })


        }

        return targets

        //여기서 그냥 대상 request id 들을 반환
        //체크는 length 0 이면 대상 없는 걸로 간주
    }

    async checkSelectedItems() {
        if (this.pageData) {

            const selectedItems = this.pageData.data.filter(item => {
                return item.selected == true
            })

            if (selectedItems.length == 0) {
                await (window as any).alertAsync(this.$t("선택된_승인건이_없습니다").toString())
                
                return false
            }
        }


        return true

        //여기서 그냥 대상 request id 들을 반환
        //체크는 length 0 이면 대상 없는 걸로 간주
    }

    async doApproval(msg: string, status: ApprovalStatus) {

        //로딩바 표시 
        this.isProgressing = true

        let approvalItems: Array<reservedb.ApprovalItem> = []

        //하단 선택승인으로 시작되는경우
        if (this.selecredRequestId == 0) {
            const targets = await this.checkSelectedItemsWithData(true)
            if (targets.length == 0) {
                // alert("이미 승인처리가 된 건은 다시 진행 할 수 없습니다.")
                //메세지 처리하는게 아니라 아예 선택을 못하게 변경해야겠네
                return
            }
            //메세지처리 우선 보류 선택된 아이템이 있다고해도 대기 상태건만 처리가 가능함
            //처리된건만 선택될 수 있는 케이스가 발생 할 수 있음
            //alert("이미 승인처리가 된 건은 다시 진행 할 수 없습니다.")

            targets.forEach(id => {
                approvalItems.push({
                    approvalMessage: msg,
                    approvalStatus: status,
                    reserveRequestId: id
                })
            })
        } else {//그리드 로우에 승인버튼에서 시작되는 경우
            approvalItems.push({
                approvalMessage: msg,
                approvalStatus: status,
                reserveRequestId: this.selecredRequestId
            })

        }

        let successRequest: Array<reservedb.ApprovalItem> = []
        let failRequest :Array<reservedb.ApprovalItem> = []
        

        //여러개라 개별적으로 요청하면 타이밍이슈 발생
        //PromiseAll 로 간다
        // foreach 로 가야됨 , 동일 시간 요청이 여러건 대기 중일 때는 모두 유효하다고 판단하기 때문에(등록전상태)
        //한건씩 유효성체크 -> 승인처리 flow 로 처리 해야 될듯
        for(let i=0; i <approvalItems.length; i++){
            if (this.pageData) {
                let filterItem = this.pageData.data.filter(pageItem => {
                    return pageItem.reserveRequestId == approvalItems[i].reserveRequestId
                })

                if (filterItem.length > 0) {

                   

                    //requestId 가 있는 경우 수정 건으로 처리되어 flow 에서 예외처리 되버림
                    //requestId 제외하고 요청
                   await api.reqeustdb.validationReserveRequest({
                        // reserveRequestId: filterItem[0].reserveRequestId,
                        reserveResourceId: filterItem[0].reserveResourceId,
                        startDatetime: new Date(`${filterItem[0].startDate}T${filterItem[0].startTime}`),
                        endDatetime: new Date(`${filterItem[0].endDate}T${filterItem[0].endTime}`),
                        recurringOption: filterItem[0].option ? filterItem[0].option : null
                    }).then(async result => {
                        //여기서 반려인경우는 유효성 체크 안하고 넘겨야됨
                        //예외처리 반려인경우에는 유효성 강제 통과 시키고 반려처리함
                        if(approvalItems[i].approvalStatus == ApprovalStatus.Reject){
                            result = true
                        }
                            
                        if(result == true){
                            await api.approval.updateApprovals([approvalItems[i]]).then(async updateResult => {
                                 
                                if(updateResult == true){
                                    successRequest.push(approvalItems[i])
                                }else{
                                    failRequest.push(approvalItems[i])
                                }
                             }) 
                        }else{
                            failRequest.push(approvalItems[i])
                        }
                    })
                }
            }
        }

         
        //결과 처리 안내
        let resultMessage = ""
        if(successRequest.length == approvalItems.length){
            
            resultMessage =  this.$t("총_COUNT_처리완료").toString().replace("{{COUNT}}",approvalItems.length.toString())
            //모두 성공인 경우
        }else{ //실패건이 1건이라도 존재하는 경우
            // "총 12건 처리중 성공 5건, 실패 5건이 처리 되었습니다."
            resultMessage = this.$t("총_COUNT_처리완료_DETAIL").toString().replace("{{TOTAL_COUNT}}",approvalItems.length.toString()).replace("{{SUC_COUNT}}",successRequest.length.toString()).replace("{{FAIL_COUNT}}",failRequest.length.toString())
            // resultMessage =  `총 ${approvalItems.length} 건 처리 중 성공 ${successRequest.length} 건, 실패 ${failRequest.length} 건이 처리 되었습니다.`
            let failedId = ""

            const lastItem = failRequest[failRequest.length-1]
            failRequest.forEach(req=>{
                if(lastItem == req){
                    failedId += `${req.reserveRequestId}`
                }else{
                    failedId += `${req.reserveRequestId}, `
                }
            })
            resultMessage += `\n\n${this.$t("요청_실패_ID").toString()} : [ ${failedId} ]`
        }
       
        

        await (window as any).alertAsync(resultMessage)
       

        //처리 완료 후 초기화 진행
        this.initApprovalData()
        this.isProgressing = false
        //this.unCheckSelecAll()
        
        this.selecredRequestId = 0
 
     
         
        // await api.approval.updateApprovals(approvalItems).then(result => {
        //     if (result == true) {
        //         alert(`승인 건 총 ${approvalItems.length} 가 처리되었습니다.`)
        //         this.initApprovalData()
        //     } else {
        //         alert("승인 처리가 실패하였습니다.")
        //     }

        //     //처리후 check모두 해제
        //     this.unCheckSelecAll()

        // }).finally(() => {
        //     this.isProgressing = false
        // })

        // //로우에서 바로 버튼 눌러서 승인하는 경우 modal pop시 id 를 기록하는데
        // // 해당 부분을 초기화 시켜줌
        // this.selecredRequestId = 0

    }

    unCheckSelecAll() {

        //하위 컴포넌트 직접 접근해서 변경 처리함 예외적인 상황
        (<any>this.$refs.approvalGrid).isSelectAll = false
    }

    async deleteApproval() {
        const targets = await this.checkSelectedItemsWithData(false)
        if (targets.length == 0) return

        if (await(window as any).confirmAsync(this.$t("선택하신_승인건을_삭제하시겠습니까").toString())) {
            //선택건 추출

            api.approval.deleteApprovals(targets).then(async result => {
                if (result == true) {
                    // alert(`승인 건 총 ${targets.length} 가 삭제되었습니다.`)
                    await (window as any).alertAsync( this.$t("승인건_COUNT_삭제완료").toString().replace("{{COUNT}}",targets.length.toString()))
                    this.initApprovalData()
                } else {
                    await (window as any).alertAsync(this.$t("승인건_삭제가_실패하였습니다").toString())
                    
                }
                //처리후 check모두 해제
                this.unCheckSelecAll()
            })
        }


    }


    setSelectedId(id: number) {

        this.selecredRequestId = id
    }
}