import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reservedb/store/types'

import MonthItem from './MonthItemDb.vue'
import { EnumCalendarViewType } from '@reservedb/types'
import { debounce } from '@appbase/util'
import { BookingRequestType } from '@reservedb/types/enums'
import * as dateutil from '@appbase/util/date-helper';

@Component({
    components: {
        MonthItem
    },

    computed: mapGetters({
        viewMode: types.CALENDAR_VIEWMODE,
        currentDate: types.CALENDAR_DATE_CURRENT,
        calendarSetting: types.CALENDAR_SETTING,
        monthMatrix: types.CALENDAR_MONTH_MATRIX_LIST,
        selectedResourceIds: types.RESOURCE_SELECTED_RESOURCEIDS,
        
    }),

    methods: mapActions({
        getBookingList: types.RESOURCE_BOOKING_LIST,
        setViewMode: types.CALENDAR_VIEWMODE
    })
})
export default class MonthViewDb extends Vue {

    setViewMode!: (viewType: EnumCalendarViewType) => void;
    getBookingList!: (param: reservedb.RequestBookingParam) => Array<Promise<Array<reservedb.ResponseBookingItem>>>
    viewMode!: EnumCalendarViewType
    currentDate!: reservedb.DateItem;
 


    calendarSetting!: reservedb.CalendarSetting;
    monthMatrix!: reservedb.DateItem[][];
    // selectedResources!: ewsResource.EwsResourceItem[];

    weekLabel: any = {
        0: { key: 0, text: this.$t("일").toString() },
        1: { key: 1, text: this.$t("월").toString() },
        2: { key: 2, text: this.$t("화").toString() },
        3: { key: 3, text: this.$t("수").toString() },
        4: { key: 4, text: this.$t("목").toString() },
        5: { key: 5, text: this.$t("금").toString() },
        6: { key: 6, text: this.$t("토").toString() }
    }

    weeklist: any[] = [];
    //database 
    selectedResourceIds!: Array<number>
    //


    @Watch('selectedResourceIds')
    @Watch('currentDate.date')
    @Watch('viewMode')
    onSelectedResourcesChanged() {
        //여기서 계속 로드되는군 날짜 체크 진행

      
        this.getBookingItems(this.selectedResourceIds, this.currentDate.date)
         
        
    }


    getBookingItems = debounce((ids: Array<number>, date: Date) => {

        this.getBookingList({
            resourceIds: ids,
            requestType: BookingRequestType.Monthly,
            baseDatetime: date ? date : new Date()

        })

    }, 200)


    created() {
        this.setViewMode(EnumCalendarViewType.Month);
        this.mountWeekList();

    }

    mountWeekList() {
        this.weeklist = [];
        this.weekLabel = {
            0: { key: 0, text: this.$t('W_Schedule_Sunday').toString().substr(0, 1) },
            1: { key: 1, text: this.$t('W_Schedule_Monday').toString().substr(0, 1) },
            2: { key: 2, text: this.$t('W_Schedule_Tuesday').toString().substr(0, 1) },
            3: { key: 3, text: this.$t('W_Schedule_Wednesday').toString().substr(0, 1) },
            4: { key: 4, text: this.$t('W_Schedule_Thursday').toString().substr(0, 1) },
            5: { key: 5, text: this.$t('W_Schedule_Friday').toString().substr(0, 1) },
            6: { key: 6, text: this.$t('W_Schedule_Saturday').toString().substr(0, 1) }
        }

        for (var i = 0; i < 7; i++) {
            var idx = (this.calendarSetting.firstDay + i) % 7;
            this.weeklist.push(this.weekLabel[idx]);
        }
    }
}