import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
 
import { ModalLayout } from '@appbase/templates'
import api from '@reservedb/api'
import ResourceOptionItem from '@reservedb/components/ReserveResource/ResourceDetailOptionItem.vue'

@Component({
    components: {
        ModalLayout,
  
        ResourceOptionItem
    },

})
export default class ResourceDetailModalView extends Vue {
    @Prop({ type: Boolean }) show!: boolean
    @Prop({ type: Object }) value!: reservedb.ResponseResourceDetailItem

    imgDownloadUrl : string = ""
    
    created() {
        // alert(`resourceId is ${this.value}`)   
         
    }

     
    close() {

        this.$emit('close')


    }

    convertUseApproval(data:boolean){
        if(data == true){
            
            return this.$t("필요").toString()
        }else{
            return this.$t("불필요").toString()
        }
    }

    getImgAddr(fileCode : string){
        api.resourcedb.getResourceImageFileInfo(fileCode).then(result=>{
            if(result.length > 0){
                this.imgDownloadUrl = result[0].downloadUrl
            }
        })
    }

    @Watch("value")
    onChangevalue(){
        if(this.value){
            if(this.value.resourceImgFileCode == ''){
                this.imgDownloadUrl = ''
            }else{

                this.getImgAddr(this.value.resourceImgFileCode)
            }
        }
    }
    
}
