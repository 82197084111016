import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import api from '@reservedb/api'
import { uGetDateFormat, uGetTimeArray, IEDate } from '@appbase/util/date-helper'
import { FlowFileMeta } from '@/modules/@appbase/components/common/FlowFileUploader/flow-file-meta'
import { invalidateUrl } from '@/modules/@appbase/util';
import * as types from '@reservedb/store/types'
import * as accountTypes from '@account/store/types'
import { ReserveResourceMode } from '@reservedb/types';
import { getFullTimetext, getFullTimetextDb } from '@reservedb/disp-helper';
import { FlowFile } from '@/modules/@appbase/libs/flowjs';

import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import TimePicker from '@reservedb/components/TimePicker.vue'
import RecipientField from '@/modules/reservedb/components/RecipientFieldDb.vue';
import RecurringOption from './RecurringOptionDb.vue';
import Attachments from './AttachmentsDb.vue';
import { FlowFileUploader } from '@appbase/components/common'
import { LoadingBar } from '@appbase/components/loading';
import { handleClose, openReserveWindow } from '../../popup-handler';
import { EumEditor } from '@appbase/components/editor'
import { Dictionary } from 'vue-router/types/router';

import { ReserveRecuringType, ReserveRecuringEndType, ReserveCancelTarget } from '@reservedb/types/enums'

import appBaseApi from '@appbase/api'
import flowHelper from '@appbase/components/common/FlowFileUploader/flow-helper';

import * as permissionHelper from '@appbase/util/permission-helper'

@Component({
    components: {
        DatePicker,
        TimePicker,
        RecipientField,
        FlowFileUploader,
        RecurringOption,
        EumEditor,
        Attachments,
        LoadingBar
    },

    computed: mapGetters({
        user: accountTypes.USER,
        bookingList: types.RESOURCE_BOOKING_LIST,
        selectedDate: types.CALENDAR_DATE_CURRENT,

    }),

    methods: mapActions({

    })
})
export default class ReserveResourceDb extends Vue {
    @Prop({ type: Boolean, default: false }) isPopup!: boolean;

    requestInfo: reservedb.ResponseRequestItem | null = null
    user!: account.User;
    selectedDate!: reservedb.DateItem;


    mode: ReserveResourceMode = ReserveResourceMode.Write;
    contentLoaded: boolean = true;
    showRecurringOption: boolean = false;
    currentReccuringOption: reservedb.CalendarItemRecurrence | null = null;
    recurrenceDesc: string = "";
    recurrenceIntervalDesc: string = "";
    dispResourceName: string = "";
    confSubject: string = "";

    myCalendarFolderId: string | null = null;
    currentItem: reservedb.CalendarItem | null = null;
    // curResourceGroup: number = 0;
    curResourceFolderGuid: string = ""
    curResourceItem: number | null = null
    curResourceUseApproval: boolean = false

    resourceGroup: reservedb.ResponseResourceFolderItem[] = [];
    filteredResourceGroup: reservedb.ResponseResourceFolderItem[] = [];
    resourceItems: reservedb.ResponseResourceBoardMapItem[] = [];
    filteredResourceItems: reservedb.ResponseResourceBoardMapItem[] = [];



    startTimeArray: any[] = [];
    endTimeArray: any[] = [];

    isAlldayEvent: boolean = false;

    eventStart: string = uGetDateFormat('yyyy-MM-dd', new Date());
    eventStartTime: string = "";
    eventEnd: string = uGetDateFormat('yyyy-MM-dd', new Date());
    eventEndTime: string = "";

    isOccurrence: boolean = false
    isPreview: boolean = false
    isFromSearch : boolean = false


    // requiredRecipients: Array<reservedb.ResponseRequestTargetItem> = [];
    // optionalRecipients: Array<reservedb.ResponseRequestTargetItem> = [];

    requiredRecipients: Array<any> = [];
    optionalRecipients: Array<any> = [];

    uploadUrl: string = invalidateUrl('/cm/file/store/flow');
    deleteUrl: string = invalidateUrl('/cm/file/store/deleteFile');
    sizeLimit: number | null = null;
    bannedExtentions: string[] = [];
    files: FlowFile[] = [];

    isFileUploading: boolean = false;
    uploaderConfig: FlowFileMeta = {
        code: '',
        codeValues: [],
        type: 'file'
    };

    reserveContent: string = "";
    tinyopts: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,
        height: 500,
        inline: false,
        menubar: false,
        language: 'ko_KR',
    }
    dext5opts: editor.dext5.DEXT5Config = {
        EditorBorder: "0",

    }

    getEditorBody() {


        const editor: editor.IEditorProxy = this.$refs.refEditor as any
        return editor.getEditor().getBodyValue()
    }


    convertFulltimeText() {
        if (this.requestInfo) {
            var strStartDatetime = `${this.requestInfo.startDate}T${this.requestInfo.startTime}+09:00`
            var strEndDatetime = `${this.requestInfo.endDate}T${this.requestInfo.endTime}+09:00`
            return getFullTimetextDb(strStartDatetime, strEndDatetime)
        }

    }

    initResourceData(boardId: number) {
        //권한 정보 조회
        api.resourcedb.getResourcePermissions(boardId).then(permissionResult => {
            api.resourcedb.getResourceFolder(boardId).then(folderResult => {
                this.resourceGroup = folderResult

                if (folderResult.length > 0) {
                    //this.curResourceFolderGuid = folderResult[0].guid

                    api.resourcedb.getResourceBoards(this.user.comCode, boardId).then(result => {
                        //읽기 권한이 있는 자원만 필터링 함
                        let hasPermissionResources: Array<reservedb.ResponseResourceBoardMapItem> = []

                        if (result.length > 0) {

                            //권한정보 처리
                            let resourcePermissions: Array<reservedb.ResourcePermission> = []

                            //개수가 일치하는 경우
                            if (resourcePermissions.length == result[0].mapItems.length) {

                                //읽기 권한이 있는 리소스만 필터링
                                //필터안함
                                // hasReadPermissionResources = permissionResult.filter(item => {
                                //     return item.read == true
                                // })
                                resourcePermissions = permissionResult


                            } else { //권한정보가 없는 경우는 모두 허용이기 때문에 조회된 데이터랑 다름
                                resourcePermissions = result[0].mapItems.map(mapItem => {
                                    const findVal = permissionResult.filter(filterItem => {
                                        return filterItem.resourceId == mapItem.reserveResourceId
                                    })

                                    return {

                                        resourceId: mapItem.reserveResourceId,
                                        read: findVal.length > 0 ? findVal[0].read : true,
                                        write: findVal.length > 0 ? findVal[0].write : true


                                    }
                                })
                            }

                            //관리자 권한인 경우 우선 구현만하고 주석처리
                            // if (false) {
                            if (permissionHelper.IsSuperadmin() == true) {
                                resourcePermissions = resourcePermissions.map(mapItem => {
                                    return {
                                        ...mapItem,
                                        read: true,
                                        write: true
                                    }
                                })

                            }
                            //읽기 권한이 있는 자원만 필터링 함
                            //let hasPermissionResources: Array<reservedb.ResponseResourceBoardMapItem> = []
                            result[0].mapItems.forEach(item => {
                                var filterItem = resourcePermissions.filter(filterItem => {
                                    return filterItem.resourceId == item.reserveResourceId && filterItem.write == true
                                })

                                if (filterItem.length > 0) {
                                    hasPermissionResources.push(item)
                                }
                            })


                            /////// 폴더 내 자원 없으면 삭제 해버림
                            folderResult.forEach(folder => {
                                let folderGuid = folder.guid

                                let filtered = hasPermissionResources.filter(res => {
                                    return folderGuid == res.guid
                                })

                                if (filtered.length > 0) {
                                    this.filteredResourceGroup.push(folder)
                                }

                            })

                            this.curResourceFolderGuid = this.filteredResourceGroup[0].guid


                            //원본 넣고
                            this.resourceItems = hasPermissionResources

                            //첫번째 선택 된 mapItems filter
                            this.filteredResourceItems = hasPermissionResources.filter(item => {
                                return item.guid == this.curResourceFolderGuid
                            })

                            if (this.filteredResourceItems.length > 0)
                                this.curResourceItem = this.filteredResourceItems[0].reserveResourceId


                        }



                    })
                }




            })
        })

        //폴더부터 조회함

    }

    onEditBtnClick() {
        this.mode = ReserveResourceMode.Edit

    }

    async wrongAccess() {
        
        await (window as any).alertAsync(this.$t("잘못_된_접근입니다").toString())
        this.onCancelClick()
    }

    showEditBtn() {
        //승인게시판에서 preview 형태로 오는 경우가 있어 조건 추가
        if (this.mode == ReserveResourceMode.Read &&
            this.requestInfo &&
            this.requestInfo.createdId.toLowerCase() == this.user.personCode.toLowerCase() &&
            this.isPreview == false) {
            return true
        }

        return false
    }
    created() {


        //SYSID 가 없는 경우는 잘못된 옳바르지 못함 경로에서 유입된 것으로 간주하고 home 으로 보냄
        const boardId = this.$route.query.SYSID
        const { bookingId, requestId, open, from } = this.$route.query;

        //검색컴포넌트에서 인입된 건이닞 확인 후 flag 처리
        //뒤로 가기 처리 
        if(from == 'search'){
            this.isFromSearch = true
        }

        // alert(`bookingId ${bookingId}\n requestId:${requestId}\nboardid: ${boardId}`)
        //예외처리 requestId 가 있는 경우 prview(승인 게시판에서 사용)
        if ((!boardId) && (!requestId)) {
            this.wrongAccess()
        }

        //승인게시판에서 오는 prview 인경우 flag 기록
        //수정 버튼 숨김처리
        if (requestId) {
            this.isPreview = true
        }

        //반복일정에서 단건으로 처리되는 경우 falg 기록
        if (open == "occurrence") {
            this.isOccurrence = true
        }

        this.initFileUploader()
        this.initResourceData(parseInt(<string>boardId))



        if (bookingId || requestId) {
            //최초 접근시에는 무조건 읽기모드로 변경
            this.mountReadMode(requestId ? parseInt(<string>requestId) : parseInt(<string>bookingId), requestId ? true : false)

            //예외처리 popup으로 생성되는 경우에는 querystr으로 확인
            //단 작성자가 아닌 경우가 querystr 입력해서 들어 올 수 있기 때문에 관련 부분도 함께 체크
            //비동기라 mountreadmode finaly 에서 체크
        } else {
            //view 에서 선택된 날짜로 set
            this.setDate()
            this.mountWriteMode()
        }

    }

    setDate() {


        if (this.isPopup) {
            const queryBaseDate = this.$route.query.baseDate
            //예외처리 읽기, 수정 모드에서는 baseDate 없음
            if (queryBaseDate != null) {
                this.eventStart = <string>queryBaseDate
                this.eventEnd = <string>queryBaseDate
            }

        } else {
            const baseDatetime = new Date(this.selectedDate.date)
            this.eventStart = uGetDateFormat('yyyy-MM-dd', baseDatetime)
            this.eventEnd = uGetDateFormat('yyyy-MM-dd', baseDatetime)
        }


    }

    initFileUploader() {
        const uid = `uid-${Date.now()}`;
        this.uploaderConfig.codeValues = [this.user.comCode, 'reservedb', this.user.email, uid];
        this.uploaderConfig.code = `RESERVEDB_${this.user.id}_${uid}`;
    }

    mountWriteMode() {
        this.mode = ReserveResourceMode.Write;


        this.mountTimeArray()

    }

    mountReadMode(bookingId: number, isPreview: boolean) {
        this.contentLoaded = false


        const isMaster = this.$route.query.open == "master" ? true : false


        //아이템 정보 조회해서 넣고 종료
        api.reqeustdb.getReserveRequest(bookingId, isMaster, isPreview).then(result => {
            this.mode = ReserveResourceMode.Read
            this.requestInfo = result
            this.reserveContent = result.body

            this.curResourceItem = result.reserveResourceId
            this.eventStart = result.startDate
            this.eventStartTime = result.startTime
            this.eventEnd = result.endDate
            this.eventEndTime = result.endTime
            this.confSubject = result.title

            const converWeekArray = (data: string) => {
                let numArray: Array<number> = []

                if (data != "" && data) {
                    // 2,3,4
                    const strSplit = data.split(",")

                    strSplit.forEach(str => {
                        let parsedInt = parseInt(str)
                        if (!isNaN(parsedInt)) {
                            numArray.push(parseInt(str))
                        }

                    })

                }

                return numArray
            }

            const convertEndType = (data: string) => {
                if (data != "" && data) {
                    if (data.toLowerCase() == "notset")
                        return "1"

                    if (data.toLowerCase() == "setcount")
                        return "2"
                    if (data.toLowerCase() == "setdate")
                        return "3"
                }
            }



            const convertRecurringType = (data: string) => {
                if (data != "" && data) {
                    if (data.toLowerCase() == "daily")
                        return 1

                    if (data.toLowerCase() == "weekly")
                        return 2
                    if (data.toLowerCase() == "monthly")
                        return 3
                }
            }

            // this.currentReccuringOption.ui_recurringType = (result.option as reservedb.RequestRequestOption ).RecuringType

            if (result.isRecursive == true && result.option != null && !this.isOccurrence) {
                this.currentReccuringOption = {
                    ui_recurringType: <number>convertRecurringType(<string>(result.option as reservedb.RequestRequestOption).recuringType),
                    startDate: <string>(result.option as reservedb.RequestRequestOption).recuringStartDate,
                    endDate: <string>(result.option as reservedb.RequestRequestOption).recuringEndDate,
                    interval: <number>(result.option as reservedb.RequestRequestOption).recuringInterval,
                    numberOfOccurrences: (result.option as reservedb.RequestRequestOption).recuringCount,
                    daysOfTheWeekArray: <number[]>converWeekArray(<string>(result.option as reservedb.RequestRequestOption).recuringDayOfWeekArray),
                    ui_endType: <string>convertEndType(<string>(result.option as reservedb.RequestRequestOption).recuringEndType),
                    monthValue: <number>(result.option as reservedb.RequestRequestOption).recuringMonth,
                    dayOfMonth: <number>(result.option as reservedb.RequestRequestOption).recuringDayOfMonth,

                }

                this.updateRecurrenceDesc()
            }

            if (result.startTime == "00:00:00" && result.endTime == "23:59:59") {
                this.isAlldayEvent == true
            }

            //첨부파일
            this.uploaderConfig = {
                ...this.uploaderConfig,
                code: result.fileCode
            };

            let fileCode = result.fileCode
            if (fileCode != "") {
                appBaseApi.file.getFiles(fileCode).then(result => {

                    this.files = result.map(item => flowHelper.convertToFlowFile({
                        ...item,
                        uniqueIdentifier: item.id,
                        type: 'Database',
                    }))
                })
            }


            this.requiredRecipients = result.targets.filter(filterItem => { return filterItem.isOptional == false }).map(mapItem => {

                return {

                    displayName: mapItem.displayName,
                    titleName: mapItem.titleName,
                    deptName: mapItem.deptName,
                    personCode: mapItem.personCode,
                    isDepartment: mapItem.isDepartment,
                    isOptional: mapItem.isOptional
                }

            })

            this.optionalRecipients = result.targets.filter(filterItem => { return filterItem.isOptional == true }).map(mapItem => {
                if (mapItem.isOptional == true) {
                    return {

                        displayName: mapItem.displayName,
                        titleName: mapItem.titleName,
                        deptName: mapItem.deptName,
                        personCode: mapItem.personCode,
                        isDepartment: mapItem.isDepartment,
                        isOptional: mapItem.isOptional
                    }
                }
            })



        }).catch((err) => {

            this.wrongAccess()
        }).finally(() => {
            this.contentLoaded = true

            //예외처리 popup으로 생성되는 경우에는 querystr으로 확인
            //단 작성자가 아닌 경우가 querystr 입력해서 들어 올 수 있기 때문에 관련 부분도 함께 체크
            //비동기라 mountreadmode finaly 에서 체크

            const queryStrMode = this.$route.query.mode
            if (this.requestInfo && this.isPopup == true && queryStrMode == 'edit') {
                if (this.requestInfo.createdId.toLowerCase() == this.user.personCode.toLowerCase()) {
                    this.onEditBtnClick()
                }
                else {
                    this.wrongAccess()

                }

            }
        })




    }

    // mountEditCallback(mountItem: any, content: string) {
    //     this.mountTimeArray(mountItem);
    //     this.mountAttendees(mountItem);

    //     if (typeof (mountItem.recurrence) === 'object') {
    //         this.mountRecurrence(mountItem.recurrence);
    //     }

    //     this.reserveContent = content;
    //     this.contentLoaded = true;
    // }


    mountTimeArray() {
        this.startTimeArray = uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString()
        ]);
        this.endTimeArray = uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString()
        ]);



        var timeNow = new Date();

        if (timeNow.getMinutes() >= 30) {
            this.eventStartTime = uGetDateFormat("H:30:00", timeNow);
            timeNow.setHours(timeNow.getHours() + 1);
            this.eventEndTime = uGetDateFormat("H:00:00", timeNow);
        } else {
            this.eventStartTime = uGetDateFormat("H:00:00", timeNow);
            this.eventEndTime = uGetDateFormat("H:30:00", timeNow);
        }


    }

    // mountAttendees(item: reservedb.CalendarItem) {

    //     this.requiredRecipients = item.requiredAttendees.map(a => <orgmap.UserItem>{
    //         displayName: a.name,
    //         titleName: "",
    //         deptName: a.address,
    //         email: a.address
    //     });

    //     this.optionalRecipients = item.optionalAttendees.map(a => <orgmap.UserItem>{
    //         displayName: a.name,
    //         titleName: "",
    //         deptName: a.address,
    //         email: a.address
    //     });
    // }

    mountRecurrence(recurrence: any) {
        var recurringEndType = '1';

        if (recurrence.hasEnd) {
            if (recurrence.numberOfOccurrences != null) {
                recurringEndType = '2'
            } else {
                recurringEndType = '3'
            }
        }

        this.currentReccuringOption = <reservedb.CalendarItemRecurrence>{
            daysOfTheWeekArray: recurrence.daysOfTheWeekArray,
            startDate: uGetDateFormat('yyyy-MM-dd', IEDate(recurrence.startDate)),
            endDate: recurrence.endDate != null ? uGetDateFormat('yyyy-MM-dd', IEDate(recurrence.endDate)) : null,
            interval: recurrence.interval,
            numberOfOccurrences: recurrence.numberOfOccurrences,
            ui_endType: recurringEndType,
            ui_recurringType: this.currentItem?.pidLidRecurrenceType || 1,
            dayOfMonth: recurrence.dayOfMonth || "",
            monthValue: recurrence.monthValue || 1
        }

        this.updateRecurrenceDesc();
    }

    fileUploadStatusChaged(data: any) {
        if (data === 'uploading') {
            this.isFileUploading = true
        } else {
            this.isFileUploading = false
        }
    }

    onCancelClick() {
        //닫기 취소 버튼
        const quertStr = <Dictionary<string>>this.$route.query

        const filteredQuery = {
            MENUID: quertStr.MENUID,
            SYSID: quertStr.SYSID
        }
        handleClose(filteredQuery, this.isPopup);
    }

    async onReserveClick() {




        //시간이 좀 걸려서 로딩 상태 추가
        this.contentLoaded = false


        //에디터 content 입력
        this.reserveContent = this.getEditorBody()
        // let options = this.currentReccuringOption
        // debugger
        // return
        //예약 시작 유효청 체크
        //시작일시는 종료일시보다 크거나 같을 수 없음
        if (await this.valueValidation() == false) {
            this.contentLoaded = true
            return
        }

        let startDate = this.eventStart
        let startTime = this.eventStartTime
        let endDate = this.eventEnd
        let endTime = this.eventEndTime


        let isRecursive = false
        let recurringOption: reservedb.RequestRequestOption | null = null
        //반복설정 
        //반복설정이 된 경우
        if (this.currentReccuringOption != null) {

            isRecursive = true

            //weekArray 가 타입이 다름 
            //database 에 row 로 입력되기 때문에 sperator(",") 추가해서 입력해야됨
            //string[] 로 변경해서 전달
            let weekArray = ""
            if (this.currentReccuringOption.daysOfTheWeekArray &&
                this.currentReccuringOption.daysOfTheWeekArray.length > 0) {
                const lastItem = this.currentReccuringOption.daysOfTheWeekArray[this.currentReccuringOption.daysOfTheWeekArray.length - 1]
                this.currentReccuringOption.daysOfTheWeekArray.forEach(week => {

                    //마지막 아이템인 경우 , sperator 생략
                    weekArray += week.toString() + (week == lastItem ? "" : ",")
                })
            }

            recurringOption = {
                recuringType: <ReserveRecuringType>this.currentReccuringOption.ui_recurringType,
                recuringEndType: <ReserveRecuringEndType>parseInt(this.currentReccuringOption.ui_endType),
                recuringStartDate: this.currentReccuringOption.startDate,
                recuringEndDate: this.currentReccuringOption.endDate,
                recuringInterval: this.currentReccuringOption.interval,
                recuringCount: this.currentReccuringOption.numberOfOccurrences,
                recuringDayOfMonth: this.currentReccuringOption.dayOfMonth,
                recuringDayOfWeekArray: weekArray,
                recuringMonth: this.currentReccuringOption.monthValue
            }

        }


        //종일인경우 체크해야됨
        if (this.isAlldayEvent == true) {
            //종일 옵션이 체크된 경우 값 변경 해야됨
            //시작시작은 00:00:00 으로 
            //종료시간은 23:59:59 로
            startTime = "00:00:00"
            endTime = "23:59:59"
        }



        //수정 할 때 RequestId 필요 
        //동일 시간대 인지 체크해서 예외처리 함
        api.reqeustdb.validationReserveRequest({
            reserveRequestId: this.requestInfo?.reserveRequestId,
            reserveResourceId: <number>this.curResourceItem,
            startDatetime: new Date(`${startDate}T${startTime}`),
            endDatetime: new Date(`${endDate}T${endTime}`),
            recurringOption: recurringOption ? recurringOption : null
        }).then(async result => {

            if (result == false) {
                await (window as any).alertAsync('선택하신 시간에는 이미 예약되어 있습니다.')
                this.contentLoaded = true
            } else {

                let requestTargets = this.requiredRecipients.map(item => {
                    let isDepartment = false
                    if (item.displayName == item.deptName) isDepartment = true

                    return {
                        personCode: item.personCode,
                        isOptional: false,
                        isDepartment: isDepartment
                    }
                })

                let requestOptionalTargets = this.optionalRecipients.map(item => {
                    let isDepartment = false
                    if (item.displayName == item.deptName) isDepartment = true

                    return {
                        personCode: item.personCode,
                        isOptional: true,
                        isDepartment: isDepartment
                    }
                })
                // recurrenceDesc:"매1일 마다 오전 03:00 ~ 오전 03:30 (0.5시간) 일정이 반복됩니다."
                // recurrenceIntervalDesc:"2022-02-26 부터 계속 반복합니다."

                const resName =  this.resourceItems.filter(res => {
                    return res.reserveResourceId == this.curResourceItem
                })[0].displayName

                let inputData = {
                    recursiveDescription: this.recurrenceDesc + "\n" + this.recurrenceIntervalDesc,
                    reserveResourceId: this.curResourceItem,
                    title: this.confSubject,
                    body: this.reserveContent,
                    fileCode: this.files.length > 0 ? this.uploaderConfig.code : "",
                    isRecursive: isRecursive,
                    startDate: startDate,
                    startTime: startTime,
                    endDate: endDate,
                    endTime: endTime,
                    requestPersonCode: this.user.personCode,
                    isCancel: false,
                    approvalStatus: 0,
                    option: recurringOption,
                    targets: [...requestTargets, ...requestOptionalTargets],
                    resourceName : resName
                } as reservedb.RequestRequestItem

                //업데이트의 경우 requestId 추가
                if (this.requestInfo != null) {
                    if (this.requestInfo.reserveRequestId) {
                        inputData.reserveRequestId = this.requestInfo.reserveRequestId
                    }

                    //반복일정 중 단건 처리 시 필요 param
                    var openQueryVal = this.$route.query.open
                    if (this.requestInfo.isRecursive == true && this.isOccurrence == true) {
                        inputData.bookingId = parseInt(<string>this.$route.query.bookingId)
                        inputData.isOccurrence = true
                    }

                }


                api.reqeustdb.createUpdateReserveRequest(inputData).then(async result => {
                    if (result == true) {

                        let msg = this.$t("자원예약이_등록되었습니다").toString()
                        
                        if (this.curResourceUseApproval) {
                            //결재 사용 자원인경우 등록 할 때 메세지 추가 처리
                            
                            msg += `\n${this.$t("자원예약_승인_이후에_자원이_예약됩니다").toString()}`
                        }
                        await (window as any).alertAsync(msg)

                    } else {
                        await (window as any).alertAsync( this.$t("자원예약_등록이_실패하였습니다").toString())
                       

                    }



                }).finally(() => {
                    this.contentLoaded = true

                    this.onCancelClick()
                })
            }


        })





    }

    updateRecurrenceDesc() {
        if (this.currentReccuringOption != null) {
            var constMonthArray: string[] = [
                this.$t('W_1M').toString(),
                this.$t('W_2M').toString(),
                this.$t('W_3M').toString(),
                this.$t('W_4M').toString(),
                this.$t('W_5M').toString(),
                this.$t('W_6M').toString(),
                this.$t('W_7M').toString(),
                this.$t('W_8M').toString(),
                this.$t('W_9M').toString(),
                this.$t('W_10M').toString(),
                this.$t('W_11M').toString(),
                this.$t('W_12M').toString()
            ];

            var constWeekArray: string[] = [
                this.$t('W_Schedule_Sunday').toString(),
                this.$t('W_Schedule_Monday').toString(),
                this.$t('W_Schedule_Tuesday').toString(),
                this.$t('W_Schedule_Wednesday').toString(),
                this.$t('W_Schedule_Thursday').toString(),
                this.$t('W_Schedule_Friday').toString(),
                this.$t('W_Schedule_Saturday').toString()
            ];

            var dispTimeArray = uGetTimeArray([
                this.$t('W_AM').toString(),
                this.$t('W_PM').toString()
            ]);

            var idxStartTime = dispTimeArray.findIndex(t => t.value === this.eventStartTime);
            var idxEndTime = dispTimeArray.findIndex(t => t.value === this.eventEndTime);
            var dispHour = (idxEndTime - idxStartTime) * 0.5;
            var timeSpan = this.isAlldayEvent ?
                this.$t('W_Schedule_Allday').toString() :
                `${dispTimeArray[idxStartTime].text} ~ ${dispTimeArray[idxEndTime].text} (${dispHour}${this.$t('W_Hour').toString()})`;
            var prefix = "";

            switch (this.currentReccuringOption.ui_recurringType) {
                case 1:
                    prefix = this.$t('매N일마다').toString().replace('{INTERVAL}', this.currentReccuringOption.interval.toString());
                    break;
                case 2:
                    var dayJoin = this.currentReccuringOption.daysOfTheWeekArray?.map(i => constWeekArray[i]).join(",");
                    prefix = this.$t('매N주').toString()
                        .replace('{INTERVAL}', this.currentReccuringOption.interval.toString())
                        .replace('{DAYJOINS}', dayJoin || "");
                    break;
                case 3:
                    prefix = this.$t('매N월').toString()
                        .replace('{INTERVAL}', this.currentReccuringOption.interval.toString())
                        .replace('{DAYOFMONTH}', this.currentReccuringOption.dayOfMonth?.toString() || "");
                    break;
                case 4:
                    prefix = this.$t('매년N월').toString()
                        .replace('{MONTH}', constMonthArray[this.currentReccuringOption.monthValue - 1])
                        .replace('{DAYOFMONTH}', this.currentReccuringOption.dayOfMonth?.toString() || "");
                    break;
            }

            this.recurrenceDesc = this.$t('반복일정_설명').toString().replace('{PREFIX}', prefix).replace('{TIMESPAN}', timeSpan);

            switch (this.currentReccuringOption.ui_endType) {
                case "1":
                    this.recurrenceIntervalDesc = this.$t('N부터_계속반복됩니다').toString().replace('{START}', this.currentReccuringOption.startDate);
                    break;
                case "2":
                    this.recurrenceIntervalDesc = this.$t('N회_반복후종료').toString().replace('{OCCURRENCE_NUMBER}', this.currentReccuringOption.numberOfOccurrences?.toString() || "");
                    break;
                case "3":
                    this.recurrenceIntervalDesc = `${this.currentReccuringOption.startDate} ~ ${this.currentReccuringOption.endDate}`;
                    break;
            }
        }
    }

    onRecurringClick() {
        this.showRecurringOption = true;
    }

    onRecurringOptionSave(option: reservedb.CalendarItemRecurrence) {
        this.currentReccuringOption = option;
        this.currentReccuringOption.startDate = this.eventStart;
        this.updateRecurrenceDesc();
        this.showRecurringOption = false;
    }

    onReccuringOptionClose() {
        this.showRecurringOption = false;
    }

    onRemoveRecurrenceClick() {
        this.currentReccuringOption = null;
    }

    onUpdateReserveClick() {
        this.onReserveClick()

    }

    async onCancelReserveClick() {
        
        if (!await(window as any).confirmAsync(this.$t("정말_취소하시겠습니까").toString())) return

        this.contentLoaded = false
        //취소인데 선택일정일 때만 bookingItem 만 삭제 한다.

        const { bookingId, open } = this.$route.query

        let reqParam: reservedb.RequestCancelParam = {
            bookingId: parseInt(<string>bookingId),
            target: this.isOccurrence == true ? ReserveCancelTarget.Booking : ReserveCancelTarget.Request
        }


        api.reqeustdb.cancelReserveRequest(reqParam).then(async result => {

            if (result == true) {
                await (window as any).alertAsync(this.$t("예약이_취소되었습니다").toString())
                
            } else {
                await (window as any).alertAsync(this.$t("예약이_취소가_실패하였습니다").toString())
                
            }
        }).finally(() => {
            this.contentLoaded = true
            this.onCancelClick()
        })
    }

    onNewWindowClick() {
        let queryStr = ""
        const sysId = <string>this.$route.query.SYSID
        queryStr += `?SYSID=${sysId}`
        if (this.mode == ReserveResourceMode.Write) {

            queryStr += `&baseDate=${this.eventStart}`

        } else if (this.mode == ReserveResourceMode.Read || this.mode == ReserveResourceMode.Edit) {
            const bookingId = <string>this.$route.query.bookingId
            queryStr += `&bookingId=${bookingId}`

            if (this.mode == ReserveResourceMode.Edit) {
                queryStr += `&mode=edit`
            }
        }

        openReserveWindow(queryStr);
    }


    isInit: boolean = true
    @Watch("curResourceFolderGuid")
    async onChangedResourceGroup() {
        this.filteredResourceItems = this.resourceItems.filter(res => {
            return res.guid == this.curResourceFolderGuid
        })

        if (this.filteredResourceItems.length > 0) {
            this.curResourceItem = <number>this.filteredResourceItems[0].reserveResourceId

        } else {
            //폴더에 자원이 없는 경우가 있음
            //선택된 자원 null 로 변경
            // 이후 저장 시점에 validation 할 때 걸러 냄
            this.curResourceItem = null

            if (!this.isInit) {

                await (window as any).alertAsync(this.$t("선택된_폴더에_자원이_없습니다").toString())
                

            }
        }

        this.isInit = false



    }

    @Watch("curResourceItem")
    @Watch("mode")
    async onChangedResourceItem(newVal: any) {


        //시점 상 문제로 잘못 체크 됨
        if (this.requestInfo != null) {
            if (this.requestInfo.reserveResourceId != null) {
                this.curResourceItem = this.requestInfo.reserveResourceId
            }
        }

        this.checkUseApprovalResource()


        if (this.mode == ReserveResourceMode.Edit && this.curResourceUseApproval == true) {
            await (window as any).alertAsync(this.$t("예약변경_승인_안내멘트").toString())
            
        }

        const openQueryVal = this.$route.query.open
        if (this.mode == ReserveResourceMode.Edit && this.isOccurrence == true) {
            await (window as any).alertAsync( this.$t("반복일정_수정_안내멘트").toString())
           
        }
    }

    //reservedb 예약
    checkUseApprovalResource() {
        //debugger

        var selectedResource = this.filteredResourceItems.filter(item => {
            return item.reserveResourceId == this.curResourceItem
        })

        if (selectedResource.length > 0) {
            if (selectedResource[0].useApproval == true) {
                this.curResourceUseApproval = true
                return
            }
        }

        this.curResourceUseApproval = false
    }

    onChangedAlldayEvent() {
        this.isAlldayEvent
    }

    async valueValidation() {
        if (this.confSubject == "") {
            await (window as any).alertAsync(this.$t("제목을_입력해주세요").toString())
            
            return false
        }

        if (this.curResourceItem == null) {
            await (window as any).alertAsync( this.$t("선택된_자원이_없습니다").toString())
           
            return false
        }


        if (this.requiredRecipients.length == 0) {
            await (window as any).alertAsync( this.$t("필수_참석자는_1명이상_지정되어야_합니다").toString())
           
            return false
        }

        //시간 체크
        const startDatetime = new Date(`${this.eventStart}T${this.eventStartTime}`)
        const endDatetime = new Date(`${this.eventEnd}T${this.eventEndTime}`)

        if (startDatetime >= endDatetime) {
            await (window as any).alertAsync( this.$t("시작일시는_종료일시보다_크거나_같을_수_없습니다").toString())           
            return false
        }

        return true
    }





}