import Vue from 'vue'
import { invalidateUrl } from '@appbase/util';
import { Component, Prop } from 'vue-property-decorator'

@Component({

})
export default class Attachments  extends Vue {
    @Prop({ type: String }) uniqueId!: string;
    @Prop({ type: Array }) ewsFiles!: ewsResource.EwsAttachment[];

    download(file: ewsResource.EwsAttachment, idx: number) {
        var iframeDownloads = this.$refs.refAttachment as HTMLIFrameElement[];
        var iframeDownload = iframeDownloads[idx];

        const url = invalidateUrl('/cm/api/ews/Attachment');
        const itemId = encodeURIComponent(this.uniqueId);
        const fileId = encodeURIComponent(file.id);
        const downloadurl = `${url}?itemId=${itemId}&attachmentID=${fileId}`;
        iframeDownload.src = downloadurl;
    }
}