import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';
import { getAmpm, getDayText } from '@reservedb/disp-helper';
import { mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'
import modalHelper from '@appbase/util/modal-helper';
import { openReserveCalendarItem } from '@reservedb/popup-handler';

import GridView from '@appbase/components/GridView/GridView.vue'
import OccurrenceSelect from '@/modules/reservedb/components/OccurrenceSelectDb.vue';


@Component({
    components: {
        GridView
    },

    computed: mapGetters({
        bookingList : types.RESOURCE_BOOKING_LIST,
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
    }) 
})
export default class ListViewDb  extends Vue {
    bookingList! : Array<reservedb.ResponseBookingItem>
    colorMapItems! : Array<reservedb.ColorMapItem>
    displayItems : Array<reservedb.ResponseBookingItem> = []
    reserveHeaders: gridView.Header[] = [];

    @Watch('bookingList')
    onEventListChanged(newVal: Array<reservedb.ResponseBookingItem>) {
         
       this.initBookingList()
    }

    initBookingList(){
         

        //참조 관계 문제로 loop 발생할 이거 store 에서 한다면?
        this.displayItems = this.bookingList

        // //순서정렬
        // this.displayItems.sort((a:reservedb.ResponseBookingItem,b:reservedb.ResponseBookingItem)=>{
        //     return +new Date(`${a.startDate}T${a.startTime}+09:00`) - +new Date(`${b.startDate}T${b.startTime}+09:00`)
        // })
    }

    created() {     
        this.reserveHeaders = [
            { displayName: this.$t('제목').toString(), rowKey: 'reserveSubject', align: 'left', slotName: 'reserveTitle' },
            { displayName: this.$t('예약자명').toString(), rowKey: 'requesterDisplayName', align: 'left', slotName: 'reserveOrganizer', width: '160' },
            { displayName: this.$t('예약시작일시').toString(), rowKey: 'startDate', align: 'left', width: '180', slotName: 'reserveStart' },
            { displayName: this.$t('예약종료일시').toString(), rowKey: 'endDate', align: 'left', width: '180', slotName: 'reserveEnd' },
            { displayName: this.$t('자원명').toString(), rowKey: 'resourceName', align: 'left', width: '160' },
           // { displayName: this.$t('파일첨부').toString(), rowKey: 'hasAttachments', align: 'left', width: '80', slotName: 'reserveAttachment' }
        ]      
        
        this.initBookingList()
    }
 

    getColorClass(event: reservedb.ResponseBookingItem) {
        var colorClass: any = {};
        
        let filteredItems = this.colorMapItems.filter(item=>{
            return item.resourceId == event.resourceId
        })

        if(filteredItems.length> 0){
            colorClass[filteredItems[0].colorClass] = true
        }





        return colorClass;
    }

    getTimeText(date: string, time:string) {
        var datetime = new Date(`${date}T${time}+09:00`);
        
        
        return `${uGetDateFormat('yyyy-MM-dd', datetime)} (${getDayText(`${date}T${time}+09:00`)[0]}) ${getAmpm(`${date}T${time}+09:00`)} ${uGetDateFormat('H:i', datetime)}`;
    }

    onReserveDblClick(item: gridView.ModelItem<reservedb.ResponseBookingItem>) {
        if (item.isRecursive == true) {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: item });
        } else {
            openReserveCalendarItem(item);
        }   
    }
}