import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import * as types from '@survey/store/types'
import * as dateUtil from '@appbase/util/date-helper'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import { ApprovalStatus } from '@reservedb/types/enums'
import {  openReserveWindow } from '../../popup-handler';

@Component({
    components: {
        PageNavigationBar,
        
    },
    computed: {
        ...mapGetters({
            
        }),
    }
})

export default class ApprovalGrid extends Vue {

    @Prop({ default: 5, type: Number }) pageRange!: number
    @Prop({ default: 20, type: Number }) rowCount!: number
    @Prop({ default: 1, type: Number }) currentPageNum!: number
    @Prop({ type: Object }) pageData!: reservedb.ResponseApprovalPageSet

 
    isSelectAll: boolean = false
    


    showEditTag(rowData: reservedb.ApprovalPageData) {
        if (rowData.updatedDate != null && rowData.approvalStatus == ApprovalStatus.Wating)
            return true
        else
            return false

    }

    convertDate(date: string) {
        return dateUtil.uGetDateFormat_UTC("yyyy.MM.dd", new Date(date))
    }




    pageMove(pageNum: number) {
       

        this.$emit('search', pageNum)
    }

    selectAll(evt: any) {

         

        this.$emit("selectAll", evt.target.checked)

    }

    unCheckSelecAll() {
        this.isSelectAll = false
    }

    convertStatus(status: ApprovalStatus, isClass: boolean) {
        let returnString = ""
        switch (status) {
            case ApprovalStatus.Wating:
                returnString = isClass ? "badge-yellow" : this.$t('대기').toString()
                break
            case ApprovalStatus.Accept:
                returnString = isClass ? "badge-green" : this.$t('승인').toString()
                break
            case ApprovalStatus.Reject:
                returnString = isClass ? "badge-lightgray" : this.$t('반려').toString()
                break
        }

        return returnString
    }

    doApproval(id: number) {

        this.$emit("selectedRequstId", id)
        this.$emit('showModal')
    }

    openPreviewWindow(requestId : number){
        let queryStr =`requestId=${requestId}`
        openReserveWindow(queryStr)
    }
}
