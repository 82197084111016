import { i18n, IEDate, uGetDateFormat } from "@appbase/util";

const dayTextHash: any = {
    0: i18n.t('W_Schedule_Sunday').toString(), 1: i18n.t('W_Schedule_Monday').toString(), 2:i18n.t('W_Schedule_Tuesday').toString(), 3: i18n.t('W_Schedule_Wednesday').toString(), 4: i18n.t('W_Schedule_Thursday').toString(), 5: i18n.t('W_Schedule_Friday').toString(), 6: i18n.t('W_Schedule_Saturday').toString() 
}

export const getTimetext = function(eventTime: string) {
    var dateTime = IEDate(eventTime);

    if(dateTime.getHours() > 12) {
        dateTime.setHours(dateTime.getHours() - 12);
    }

    return uGetDateFormat('H:i', IEDate(dateTime));
}

export const getAmpm = function(eventTime: string) {
    var dateTime = IEDate(eventTime);
    return dateTime.getHours() > 12 ? i18n.t('W_PM').toString() : i18n.t('W_AM').toString();
}

export const getDayText = function(eventTime: string) {
    var dateTime = IEDate(eventTime);
    return dayTextHash[dateTime.getDay()];
}



export const getFullTimetext = function(event: reservedb.CalendarItem) {
    var startTime = IEDate(event.start);
    return `${uGetDateFormat('yyyy년 MM월DD일', startTime)} ${dayTextHash[startTime.getDay()]} ${getAmpm(event.start)} ${getTimetext(event.start)}~${getAmpm(event.end)} ${getTimetext(event.end)}`
}

export const getFullTimetextDb = function(strStartDatetime: string, strEndDatetime : string) {
    var startTime = IEDate(strStartDatetime);
    return `${uGetDateFormat('yyyy년 MM월DD일', startTime)} ${dayTextHash[startTime.getDay()]} ${getAmpm(strStartDatetime)} ${getTimetext(strStartDatetime)}~${getAmpm(strEndDatetime)} ${getTimetext(strEndDatetime)}`
}