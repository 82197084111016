import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { getAmpm, getFullTimetextDb, getTimetext } from '@reservedb/disp-helper';
import { openReserveCalendarItem } from '@reservedb/popup-handler';
import modalHelper from '@appbase/util/modal-helper';

import OccurrenceSelect from '@/modules/reservedb/components/OccurrenceSelectDb.vue';
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reservedb/store/types'

@Component({
    computed: mapGetters({     
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
    }),
})
export default class WeekItemDb  extends Vue {
    @Prop({ type: Object }) item!: reservedb.ResponseBookingItem;
    
    colorMapItems! : Array<reservedb.ColorMapItem>
 
    created() {
         
    }

    getColorClass(event: reservedb.ResponseBookingItem) {
        var colorClass: any = {};
        
        const mapItems = this.colorMapItems.filter(mapItem=>{
            return mapItem.resourceId == event.resourceId
        })

        if(mapItems.length > 0){
            colorClass[mapItems[0].colorClass] = true;
        }

        return colorClass;
    }

    getTimetext(eventTime: string) {
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        return getTimetext(strStartDatetime);
    }

    getAmpm(eventTime: string) {
        return getAmpm(eventTime);
    }

    getFullTimetext() {        
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        const strEndDatetime = `${this.item.endDate}T${this.item.endTime}+09:00`
        return getFullTimetextDb(strStartDatetime, strEndDatetime)
    }

    onEventDblClick() {
        if (this.item.isRecursive == true) {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: this.item });
        } else {
            openReserveCalendarItem(this.item);
        }  
    }


    
    getItemHeight(){
        const strStartDatetime = `${this.item.startDate}T${this.item.startTime}+09:00`
        const strEndDatetime = `${this.item.endDate}T${this.item.endTime}+09:00`

        const gapMin = ((new Date(strEndDatetime)).getTime() - (new Date(strStartDatetime)).getTime()) / 1000
        
        const diffMin = gapMin / 60
        
        return diffMin / 30 
    }
}