import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import * as types from '@reserve/store/types'
import * as accountTypes from '@account/store/types'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util'

import ResourceEventList from './ResourceEventList.vue'
import { LoadingBar } from '@appbase/components/loading'

@Component({    
    components: {
        ResourceEventList,
        LoadingBar
    },

    computed: mapGetters({
        current: accountTypes.CURRENT,
        isLoading: types.CALENDAR_DAILYEVENT_LOADING,
        currentDate: types.CALENDAR_DATE_CURRENT,
        rightPaneOpen: types.CALENDAR_RIGHTPANE_OPEN
    })
})
export default class RightPane  extends Vue {
    current!: account.TCurrent;
    isLoading!: boolean;
    currentDate!: reserve.DateItem;
    rightPaneOpen!: boolean;
    
    getTimetext() {
        return this.current.userConfig.language === 'ko-KR' ? 
            `${uGetDateFormat('MM월 dd일', IEDate(this.currentDate.date))}` :
            `${uGetDateFormat('dd/MM', IEDate(this.currentDate.date))}`;
    }
}