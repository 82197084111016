import accountStore from '@account/store'

export const GetMenuPermission = (menuId: number): any => {
     
    let current = accountStore.state.current
    if(current){
        const menu = current.permissions.menues.filter(menu =>{
            return menu.id == menuId
        })
      

        if(menu.length > 0){
            return menu.map(item => {
                return {
                    Read: item.r,
                    Write: item.c,
                    Update: item.u,
                    Delete: item.d,
                    Print: item.p,
                    IsAdmin: item.manager,
                    MenuId : menuId
                }
            })[0]  
        }
    } 

    return {} as any
}

export const IsSuperadmin = () : boolean => {
    const current = accountStore.state.current

    if(current)
        return current.permissions.isSuperAdmin
    else
        return false
}

export const HasEditPermission = (menuId: number) : boolean =>{
    //작성자,담당자(회사) ,관리자 만 수정, 삭제 가능
    //체크
    if(IsSuperadmin()){
        return true
    }

    const menu = GetMenuPermission(menuId)
    if(menu.IsAdmin == true){
        return true
    }

    return false
}

 