import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { IEDate, uGetDateFormat } from '@appbase/util';
import { openReserveResource } from '@reservedb/popup-handler';

import DayItem from './DayItemDb.vue'

@Component({
    components: {
        DayItem
    }
})
export default class DayRawItemDb  extends Vue {
    @Prop({ type: Object }) item!: reservedb.DateItem;

    eventsModel: reservedb.ResponseBookingItem[] = [];

    @Watch('item')
    itemChanged(newVal: reservedb.DateItem) {
         
        if(newVal.events) {
           // this.eventsModel = newVal.events.filter(e => e.resourceBookingId != null); 
            this.eventsModel =<Array<reservedb.ResponseBookingItem>>newVal.events          
        }
    }

    created() {
        if(this.item != null)
            this.itemChanged(this.item);
    }

    onSelectDblClick() {
        var target = IEDate(this.item.date);
        openReserveResource(target);
    }
}