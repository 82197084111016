import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import TimePicker from '@reservedb/components/TimePicker.vue'
import { uGetDateFormat } from '@appbase/util'

@Component({
    components: {
        ModalLayout,
        DatePicker,
        TimePicker
    }
})
export default class RecurringOptionDb  extends Vue {    
    @Prop({ type: Object }) initOption!: reservedb.CalendarItemRecurrence | null;

    recurringStart: string = uGetDateFormat('yyyy-MM-dd', new Date());
    recurringEnd: string = uGetDateFormat('yyyy-MM-dd', new Date());
    recurringType: string = "1";    
    reccuringEndType: string = "1";
    numberOfRecurrence: string = "";
    daysOfTheWeekArray: boolean[] = [false, false, false, false, false, false, false];
    weekStringArray: string[] = [ this.$t("일").toString(), this.$t("월").toString() ,this.$t("화").toString()  , this.$t("수").toString() , this.$t("목").toString() , this.$t("금").toString() , this.$t("토").toString() ];
    dayOfMonth: string = "";
    monthValue: string = "1";
    interval: string = "";

    restrictionMessage : string = "";
    
    setRestrictionMessage(){
    
        const maxInterval = this.getAppConfig("ReserveMaxRecuringInterval")
        const maxCount = this.getAppConfig("ReserveMaxRecuringCount")

        const msg = this.$t("반복일정_안내멘트").toString().replace("{{COUNT}}", <string>maxCount).replace("{{INTERVAL}}", <string>maxInterval)
        // this.restrictionMessage = `반복일정은 최대 ${maxInterval} 개월 또는 최대 ${maxCount} 회 등록 되며 초과분은 등록되지 않습니다.`
        this.restrictionMessage = msg
    }

    created() {
        //제한 값 조회
        this.setRestrictionMessage()

         



        this.weekStringArray = [
            this.$t('W_Schedule_Sunday').toString().substr(0, 1), 
            this.$t('W_Schedule_Monday').toString().substr(0, 1), 
            this.$t('W_Schedule_Tuesday').toString().substr(0, 1), 
            this.$t('W_Schedule_Wednesday').toString().substr(0, 1), 
            this.$t('W_Schedule_Thursday').toString().substr(0, 1), 
            this.$t('W_Schedule_Friday').toString().substr(0, 1), 
            this.$t('W_Schedule_Saturday').toString().substr(0, 1)
        ]

        var timeNow = new Date();
        timeNow.setMonth(timeNow.getMonth() + 1);
        this.recurringEnd = uGetDateFormat('yyyy-MM-dd', timeNow);
        this.mountOption();       
    }  

    mountOption() {
        if(this.initOption != null) {
            this.recurringStart = this.initOption.startDate;
            this.recurringEnd = this.initOption.endDate || uGetDateFormat('yyyy-MM-dd', new Date());
            this.reccuringEndType = this.initOption.ui_endType;
            this.recurringType = this.initOption.ui_recurringType.toString();
            this.numberOfRecurrence = this.initOption.numberOfOccurrences ? 
                this.initOption.numberOfOccurrences.toString() : "";
            this.dayOfMonth = this.initOption.dayOfMonth ?
                this.initOption.dayOfMonth.toString() : "";
            this.monthValue = this.initOption.monthValue ?
                this.initOption.monthValue.toString() : "";
            this.interval = this.initOption.interval.toString();
            
            if(this.initOption.daysOfTheWeekArray) {
                this.initOption.daysOfTheWeekArray.forEach(d => {
                    this.daysOfTheWeekArray[d] = true;
                });
            }
        }
    }

    async validateOption() {    
        if(this.recurringType === '1') {
            if(this.interval.trim().length == 0 || Number(this.interval) == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_LowInterval').toString());
                return false;
            }
        }         
        
        if(this.recurringType === '2') {
            if(this.interval.trim().length == 0 || Number(this.interval) == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_LowInterval').toString());
                return false;
            }

            if(this.daysOfTheWeekArray.filter(d => d).length == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_SelectLowDay').toString());
                return false;
            }
        }

        if(this.recurringType === '3') {
            if(this.interval.trim().length == 0 || Number(this.interval) == 0) {
                await (window as any).alertAsync(this.$t('M_Schedule_LowInterval').toString());
                return false;
            }

            if(this.dayOfMonth.trim().length == 0 || Number(this.dayOfMonth) == 0 || Number(this.dayOfMonth) > 31) {
                await (window as any).alertAsync(this.$t('M_Schedule_SelectMonthDay').toString());
                return false;
            }
        }

        if(this.recurringType === '4') {
            if(this.dayOfMonth.trim().length == 0 || Number(this.dayOfMonth) == 0 || Number(this.dayOfMonth) > 31) {
                await (window as any).alertAsync(this.$t('M_Schedule_SelectMonthDay').toString());
                return false;
            }
        }

        return true;
    }

    async onSaveClick() {
        var returnOption: reservedb.CalendarItemRecurrence = {
            startDate: this.recurringStart,
            endDate: this.reccuringEndType === '3' ? this.recurringEnd : null,
            ui_endType: this.reccuringEndType,
            ui_useRecurringButton: true,
            ui_useDefaultDate: false,
            ui_useRecurringDisplay: true,
            ui_useRecurring: true,
            ui_recurringType: Number(this.recurringType),            
            monthValue: Number(this.monthValue),
            firstDayOfWeekValue: 1,
            daysOfTheWeekArray: this.daysOfTheWeekArray.map((d, idx) => d ? idx : -1).filter(f => f >= 0),
            dayOfMonth: Number(this.dayOfMonth),
            interval: Number(this.interval),
        } as reservedb.CalendarItemRecurrence;

        if(this.reccuringEndType === '2') {
            returnOption.numberOfOccurrences = Number(this.numberOfRecurrence);
        }

        if(await this.validateOption()) {
            this.$emit('save', returnOption);     
        }           
    }

    onCancelClick() {
        this.$emit('close');
    }
}