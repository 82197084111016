import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as appbaseTypes from '@appbase/store/types';
import api from '@hr/api/index'
//import ModalSlotLayout from './ModalSlotLayout.vue';
import ModalSlotLayout from '@appbase/containers/ModalContainer/ModalSlotLayout.vue'
import modalHelper from '@appbase/util/modal-helper'
import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import {FormGroup, TextBoxField} from '@appbase/components/forms/index'
import CheckSlider from '@appbase/components/checkbox/CheckSlider.vue';
import * as accountTypes from '@account/store/types';
import { dispatchEventBus } from '@appbase/util/eventBus';

@Component({
    components: {
        ModalSlotLayout,
        ModalLayout,
        FormGroup,
        TextBoxField,
        CheckSlider
    },
    computed: {
        ...mapGetters({
            isTeams: appbaseTypes.TEAMS,
            user: accountTypes.CURRENT
        })
    } 
})
export default class Add extends Vue {

    @Prop({ type: Object }) value!: any;

    user!: account.TCurrent

    isModal?: boolean = false;
    isTeams!: boolean
    
    targetId: string = ''

    data: any = {}
    leaveCategoryList: Array<string> = [];
    comGroupCodeList: Array<Leave.comGroup> = [];

    async save(){
        let _this = this;

        if(!this.data.name){
            await (window as any).alertAsync(this.$t('기본이름입력'));
            return;
        }
        var max = Number(this.data.max);
        if(isNaN(max)){
            await (window as any).alertAsync(this.$t('M_Common_DateMsg1'));
            return;
        }

        this.$nextTick(()=>{
            api.leave.addLeaveCode({
                ComCode: this.user.company.comCode,
                ComGroupCode: this.data.comGroupCode,
                LeaveCategory: this.data.leaveCategory,
                Name: this.data.name,
                NameEng: this.data.nameEng,
                NameJpn: this.data.nameJpn,
                NameChi: this.data.nameChi,
                IsDeduct: this.data.isDeduct,
                IsUsing: this.data.isUsing,
                UsableLeaveHalfDay: this.data.usableLeaveHalfDay,
                UsableLeaveQuarterDay: this.data.usableLeaveQuarterDay,
                Max: max,
                CreateUser: ''
            }).then(async res=>{
                if(res==0){
                    await (window as any).alertAsync(_this.$t('휴가코드정보등록성공'));
                    if((window as any).parent){
                        dispatchEventBus((window as any).parent, 'refreshLeaveCode', undefined);
                    }else{
                        dispatchEventBus(window, 'refreshLeaveCode', undefined);
                    }
                    this.handleClose()
                }else{
                    await (window as any).alertAsync(_this.$t('휴가코드정보등록실패')+'\n' + res);
                }
            })
        })

    }

    handleClose() {
        if(this.isModal){
            modalHelper.close();
        }
        else{
            window.close()
        }
    }

    mounted() {
        // localStorage에서 휴가구분, 계열사 리스트 가져오기
        let leaveCategoryJsonData: string | null = localStorage.getItem('leaveCategoryList');
        let comGroupCodeJsonData: string | null = localStorage.getItem('comGroupCodeList');

        if (leaveCategoryJsonData && comGroupCodeJsonData) {
            this.leaveCategoryList = JSON.parse(leaveCategoryJsonData);
            this.comGroupCodeList = JSON.parse(comGroupCodeJsonData);
        }

        this.data = {
            name:'',
            nameEng:'',
            nameJpn:'',
            nameChi:'',
            comCode:this.user.company.comCode,
            comGroupCode: this.comGroupCodeList[0].comGroupCode,
            description: this.comGroupCodeList[0].description,
            leaveCategory: this.leaveCategoryList[0],
            isDeduct:true,
            isUsing:true,
            usableLeaveHalfDay: true,
	        usableLeaveQuarterDay: true
        }
    }
}