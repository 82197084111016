import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'
import { EnumCalendarViewType } from '@reservedb/types'

import WeekAlldayItem from './WeekAlldayItemDb.vue';
import WeekRowItem from './WeekRowItemDb.vue';
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';
import { debounce } from '@appbase/util'
import { BookingRequestType } from '@reservedb/types/enums'

@Component({
    components: {
        WeekAlldayItem,
        WeekRowItem
    },

    computed: mapGetters({
        viewMode: types.CALENDAR_VIEWMODE,
        calendarSetting: types.CALENDAR_SETTING,
        currentDate: types.CALENDAR_DATE_CURRENT,
    
        weekMatrix: types.CALENDAR_WEEK_MATRIX_LIST,
        selectedResourceIds: types.RESOURCE_SELECTED_RESOURCEIDS,
        allDayEvents: types.RESOURCE_ALLDAY_EVENTS
    }),

    methods: mapActions({

        setViewMode: types.CALENDAR_VIEWMODE,
        getBookingList: types.RESOURCE_BOOKING_LIST
    })
})
export default class WeekViewDb extends Vue {

    setViewMode!: (viewType: EnumCalendarViewType) => void;
    getBookingList!: (param: reservedb.RequestBookingParam) => Array<Promise<Array<reservedb.ResponseBookingItem>>>
    viewMode!: EnumCalendarViewType
    currentDate!: reservedb.DateItem;
    allDayEvents!: Array<reservedb.ResponseBookingItem>
    filteredAlldayEvents: Array<Array<reservedb.ResponseBookingItem>> = []

    calendarSetting!: reservedb.CalendarSetting;
  
    weekMatrix!: reservedb.DateItem[][];

    weekOfMonthText: string = "";
    weekLabel: any = {
        0: this.$t("일").toString(),
        1: this.$t("월").toString(),
        2: this.$t("화").toString(),
        3: this.$t("수").toString(),
        4: this.$t("목").toString(),
        5: this.$t("금").toString(),
        6: this.$t("토").toString(),
    }
   
    weeklist: any[] = [];


    //database 
    selectedResourceIds!: Array<number>
    //


    @Watch('selectedResourceIds')
    @Watch('currentDate.date')
    @Watch('viewMode')
    onSelectedResourcesChanged() {

        this.getBookingItems(this.selectedResourceIds, this.currentDate.date)
    }


    getBookingItems = debounce((ids: Array<number>, date: Date) => {

        this.getBookingList({
            resourceIds: ids,
            requestType: BookingRequestType.Weekly,
            baseDatetime: date ? date : new Date()

        })

    }, 200)

    @Watch('weekMatrix')
    onWeekMatrixChanged(newVal: reservedb.DateItem[][]) {
        this.mountWeekList();
        this.weekOfMonthText = `${uGetDateFormat("MM", IEDate(this.currentDate.date))}월 ${this.getWeekNum()}주`

    }

    created() {
        this.weekLabel = [
            this.$t('W_Schedule_Sunday').toString().substr(0, 1),
            this.$t('W_Schedule_Monday').toString().substr(0, 1),
            this.$t('W_Schedule_Tuesday').toString().substr(0, 1),
            this.$t('W_Schedule_Wednesday').toString().substr(0, 1),
            this.$t('W_Schedule_Thursday').toString().substr(0, 1),
            this.$t('W_Schedule_Friday').toString().substr(0, 1),
            this.$t('W_Schedule_Saturday').toString().substr(0, 1)
        ];
        this.setViewMode(EnumCalendarViewType.Week);

    }

    getWeekNum() {
        var weekNumDate = IEDate(this.currentDate.date);
        weekNumDate.setDate(1);

        var firstDaynum = weekNumDate.getDay();
        return Math.floor((this.currentDate.date.getDate() + firstDaynum) / 7) + 1;
    }

    mountWeekList() {
        this.weeklist = this.weekMatrix[0].filter(f => f.date != null).map(x => <any>{
            text: `${x.date.getDate()} ${this.weekLabel[x.date.getDay()]}`,
            day: x.date.getDay(),
            date: `${x.date.getDate().toString().length == 1 ? "0" : ""}${x.date.getDate()}`
        });


    }

    getAlldayEvent(index : number){
       var result=  this.filteredAlldayEvents[index]
       return result
    }

    @Watch("allDayEvents")
    filteredEvent() {
        this.filteredAlldayEvents = []

        if (this.allDayEvents.length > 0) {
            this.weeklist.forEach(item => {

                var inputData = this.allDayEvents.filter(evt => {
                    return evt.startDate.substring(8, 10) == item.date
                })

                this.filteredAlldayEvents.push(inputData)
            })
        }
 
    }
}