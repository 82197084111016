import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as types from '@reservedb/store/types'
import {  mapGetters } from 'vuex'
import OccurrenceSelect from '@/modules/reservedb/components/OccurrenceSelectDb.vue';
import modalHelper from '@appbase/util/modal-helper';
import { openReserveCalendarItem } from '@reservedb/popup-handler';


@Component({
    computed: mapGetters({
       
     
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
    }),

})
export default class WeekAlldayItemDb  extends Vue {
    @Prop({ type: Array }) items!: Array<reservedb.ResponseBookingItem>

    colorMapItems! : Array<reservedb.ColorMapItem>
    // allDayEventsModel: reservedb.CalendarItem[] = [];

    // @Watch('items')
    // itemChaged(newVal: reservedb.DateItem) {
    //     // this.allDayEventsModel = newVal.events.filter(e => e.isAllDayEvent && e.id !== 'null');        
    // }

    created() {
        // if(this.item != null) {
        //     this.itemChaged(this.item);
        // }
    }

    getColorClass(event: reservedb.CalendarItem) {
        var colorClass: any = {};
        
        const mapItems = this.colorMapItems.filter(mapItem=>{
            return mapItem.resourceId == event.resourceId
        })

        if(mapItems.length > 0){
            colorClass[mapItems[0].colorClass] = true;
        }

        return colorClass;
    }

    onEventDblClick(item:reservedb.ResponseBookingItem) {
        if (item.isRecursive == true) {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: item });
        } else {
            openReserveCalendarItem(item);
        }  
    }
}