import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
 




@Component({
    components: {
        
    },

    computed: mapGetters({
       
    }),

    methods: mapActions({
       
    })
})
export default class ResourceDetailOptionItem  extends Vue {
    @Prop({ type: Object }) value!: reservedb.ResponseOptionMapItem
}