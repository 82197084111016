import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reserve/store/types'

import MonthItem from './MonthItem.vue'
import { EnumCalendarViewType } from '@reserve/types'

@Component({
    components: {
        MonthItem
    },

    computed: mapGetters({                
        calendarSetting: types.CALENDAR_SETTING,
        monthMatrix: types.CALENDAR_MONTH_MATRIX_LIST,
        selectedResources: types.SELECTED_RESOURCE_LIST        
    }),

    methods: mapActions({
        loadEvents: types.CALENDAR_EVENTLIST_LOAD,
        setViewMode: types.CALENDAR_VIEWMODE
    })
})
export default class MonthView  extends Vue {
    loadEvents!: () => Promise<any[]>;
    setViewMode!: (viewType: EnumCalendarViewType) => void;
    
    calendarSetting!: reserve.CalendarSetting;
    monthMatrix!: reserve.DateItem[][];
    selectedResources!: ewsResource.EwsResourceItem[];

    weekLabel: any = {
        0: { key: 0, text: "일" },
        1: { key: 1, text: "월" },
        2: { key: 2, text: "화" },
        3: { key: 3, text: "수" },
        4: { key: 4, text: "목" },
        5: { key: 5, text: "금" },
        6: { key: 6, text: "토" }
    }
    
    weeklist: any[] = [];

    @Watch('selectedResources')
    onSelectedResourcesChanged(newVal: ewsResource.EwsResourceItem[]) {
        this.loadEvents();
    }

    created() {      
        this.setViewMode(EnumCalendarViewType.Month);
        this.mountWeekList();        
        this.loadEvents();        
    }

    mountWeekList() {
        this.weeklist = [];
        this.weekLabel = {
            0: { key: 0, text: this.$t('W_Schedule_Sunday').toString().substr(0, 1) },
            1: { key: 1, text: this.$t('W_Schedule_Monday').toString().substr(0, 1) },
            2: { key: 2, text: this.$t('W_Schedule_Tuesday').toString().substr(0, 1) },
            3: { key: 3, text: this.$t('W_Schedule_Wednesday').toString().substr(0, 1) },
            4: { key: 4, text: this.$t('W_Schedule_Thursday').toString().substr(0, 1) },
            5: { key: 5, text: this.$t('W_Schedule_Friday').toString().substr(0, 1) },
            6: { key: 6, text: this.$t('W_Schedule_Saturday').toString().substr(0, 1) }
        }

        for(var i = 0; i < 7; i++) {
            var idx = (this.calendarSetting.firstDay + i) % 7;
            this.weeklist.push(this.weekLabel[idx]);            
        }         
    }
}