import ewsCalendar from '@reserve/api/ews-calendar'
import accountStore from '@account/store'

class ReserveHelper {
    async checkDetailViewAuth (id: string, resourceUpn: string): Promise<boolean> {
        let user = accountStore.state.current?.user;
        var res = await ewsCalendar.getAttendeesList(id, resourceUpn);
        var item = res.item as reserve.CalendarItem;
        if (item) {
            // organizer
            if (item.organizer.address.toLowerCase() == user?.email.toLowerCase()) {
                return true;
            } 

            // requiredAttendees
            if (item.requiredAttendees.filter(x => x.address.toLowerCase() == user?.email.toLowerCase()).length > 0) {
                return true;
            }
           
            // optionalAttendees
            if (item.optionalAttendees.filter(x => x.address.toLowerCase() == user?.email.toLowerCase()).length > 0) {
                return true;
            }
        }

        return false;
    }
}

const reserveHelper = new ReserveHelper()

export default reserveHelper