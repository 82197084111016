import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { IEDate, uGetDateFormat } from '@appbase/util';
import { openReserveResource } from '@reservedb/popup-handler';

import WeekItem from './WeekItemDb.vue';

@Component({
    components: {
        WeekItem
    }
})
export default class WeekRowItemDb  extends Vue {
    @Prop({ type: Object }) item!: reservedb.DateItem;

    eventsModel: reservedb.ResponseBookingItem[] = [];

    @Watch('item')
    itemChanged(newVal: reservedb.DateItem) {
        if(newVal.events) {
            // this.eventsModel = newVal.events.filter(e => e.id !== 'null'); 
            this.eventsModel = <Array<reservedb.ResponseBookingItem>>newVal.events        
        }
    }

    created() {
        if(this.item != null)
            this.itemChanged(this.item);
    }

    onSelectDblClick() {
        var target = IEDate(this.item.date);
        openReserveResource(target);
    }
}