import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as appbaseTypes from '@appbase/store/types';
import api from '@reserve/api/index'
//import ModalSlotLayout from './ModalSlotLayout.vue';
import ModalSlotLayout from '@/modules/@appbase/containers/ModalContainer/ModalSlotLayout.vue'
import modalHelper from '@/modules/@appbase/util/modal-helper'
import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import { findParent } from '../../../@appbase/util/component-helper';
import * as usertypes from '@account/store/types';

@Component({
    components: {
        ModalSlotLayout,
        ModalLayout
    },
    computed: {
        ...mapGetters({
            isTeams: appbaseTypes.TEAMS,
            user: usertypes.USER,
        })
    },
})
export default class ReserveResource extends Vue {
    @Prop({ type: Object }) value!: any;

    user!: account.TUser

    isModal?: boolean = false;
    isTeams!: boolean
    
    targetResourceGuid: string = ''
    bookmarkId: number = 0

    info: reserve.ResourceInfo = {
        resourceId: 0,
        resourceType: '',
        isUse: true,
        isUseApproval: false,
        createUser: '',
        imageUrl: '',
    }

    isBookmark: boolean = false;

    get getResourceImageSource() {
        return `/cm/CommonReserve/Resource/resourceImage?fileName=${encodeURIComponent(this.info.imageUrl)}`;
    }

    get getResourceLayoutSource() {
        return `/cm/CommonReserve/Resource/resourceImage?fileName=${encodeURIComponent(this.info.layoutUrl!)}`;
    }

    BookmarkClick(bookmarkType: string){
        if (bookmarkType == 'ON'){
            api.resource.insertResourceBookmark(this.targetResourceGuid, this.user.personCode, this.user.comCode, this.user.displayName).then(res => {
                this.isBookmark = true;
                this.CheckBookmark();
            });
        }
        else {
            if (this.bookmarkId > 0){
                api.resource.deleteResourceBookmark(this.bookmarkId, this.user.personCode, this.user.comCode).then(res => {
                    this.isBookmark = false;
                    this.bookmarkId = 0;
                });
            }
        }
    }

    CheckBookmark(){
        api.resource.checkResourceBookmark(this.user.personCode, this.user.comCode, this.targetResourceGuid).then(res => {
            if (res){
                this.bookmarkId = res.bookmarkId;
                this.isBookmark = true;
            }
        });      
    }

    handleClose() {
        if(this.isModal){
            modalHelper.close();
        }
        else{
            window.close()
        }
        
    }

    created(){
        var paramValue;
        if(this.value) {
            paramValue = this.value;
            this.isModal = true;
        }
        else{
            paramValue = this.$route.query
        }        
    }

    mounted(){
        this.targetResourceGuid = this.$route.query.resourceGuid as string;

        api.resource.getResourceInfo(this.targetResourceGuid).then(res=>{
            this.info = res;
        });

        // Bookmark 체크 여부
        this.CheckBookmark();
    }
}