import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import * as types from '@reserve/store/types'
import { mapActions, mapGetters } from 'vuex'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';
import { EnumCalendarViewType } from '@reserve/types'

import WeekAlldayItem from '@reserve/components/WeekView/WeekAlldayItem.vue'
import DayRawItem from './DayRawItem.vue';

@Component({
    components: {
        WeekAlldayItem,
        DayRawItem
    },

    computed: mapGetters({
        currentDate: types.CALENDAR_DATE_CURRENT,
        dateMatrix: types.CALENDAR_DATE_MATRIX_LIST,
        monthMatrix: types.CALENDAR_MONTH_MATRIX_LIST,
        selectedResources: types.SELECTED_RESOURCE_LIST 
    }),

    methods: mapActions({
        loadEvents: types.CALENDAR_EVENTLIST_LOAD,
        setViewMode: types.CALENDAR_VIEWMODE
    })
})
export default class DayView  extends Vue {
    loadEvents!: () => Promise<any[]>;
    setViewMode!: (viewType: EnumCalendarViewType) => void;

    currentDate!: reserve.DateItem;
    dateMatrix!: reserve.DateItem[][];
    monthMatrix!: reserve.DateItem[][];
    selectedResources!: ewsResource.EwsResourceItem[];

    dateOfMonthText: string = "";
    weekOfMonthText: string = "";
    dateAlldayModel: reserve.DateItem | null = null;

    @Watch('selectedResources')
    onSelectedResourcesChanged(newVal: ewsResource.EwsResourceItem[]) {
        this.loadEvents();      
    }


    @Watch('dateMatrix')
    onDateMatrixChanged(newVal: reserve.DateItem[][]) {
        this.dateOfMonthText = uGetDateFormat(`dd${this.$t('W_Day')}`, IEDate(this.currentDate.date));
        this.weekOfMonthText = `${uGetDateFormat(`MM${this.$t('W_Schedule_Month')}`, IEDate(this.currentDate.date))} ${this.getWeekNum()}${this.$t('W_Week')}`;        
        this.dateAlldayModel = this.monthMatrix[0].find(m => uGetDateFormat('yyyyMMdd', IEDate(m.date)) === uGetDateFormat('yyyyMMdd', IEDate(this.currentDate.date))) || null;        
    }

    created() {
        this.setViewMode(EnumCalendarViewType.Daily);
        this.loadEvents().then(() => this.adjustScroll());                  
    }

    adjustScroll() {
        this.$nextTick(() => {
            var divTimeline = this.$refs.divTimeline as HTMLDivElement;
            divTimeline.scrollTo(0, 478);        
        })
    }

    getWeekNum() {
        var weekNumDate = IEDate(this.currentDate.date);
        weekNumDate.setDate(1);

        var firstDaynum = weekNumDate.getDay();
        return Math.floor((this.currentDate.date.getDate() + firstDaynum) / 7) + 1;
    }    
}