import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reservedb/store/types'
import { IEDate, uGetDateFormat } from '@appbase/util';
import { openReserveCalendarItem } from '@reservedb/popup-handler';
import modalHelper from '@appbase/util/modal-helper';

import OccurrenceSelect from '@/modules/reservedb/components/OccurrenceSelectDb.vue';

@Component({
    computed: mapGetters({
       
        currentDate: types.CALENDAR_DATE_CURRENT,
        bookingList : types.RESOURCE_BOOKING_LIST,
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
    }),

    methods: mapActions({
        // loadDailyEvents: types.CALENDAR_DAILY_EVENT_LOAD
    })
})
export default class ResourceEventListDb  extends Vue {
    currentDate!: reservedb.DateItem;
    bookingList! : Array<reservedb.ResponseBookingItem>
    colorMapItems! : Array<reservedb.ColorMapItem>
    displayItems : Array<reservedb.ResponseBookingItem> = []
 
 

    

    getAmpm(eventTime: string) {
        var dateTime = IEDate(eventTime);
        return dateTime.getHours() > 12 ? this.$t('W_PM').toString() : this.$t('W_AM').toString();
    }

    getTimetext(event: reservedb.ResponseBookingItem) {
        const strDatetime =  `${event.startDate}T${event.startTime}+09:00`
         

       

        if(this.isAllDayEvent(event)) {
            return this.$t('W_Schedule_Allday').toString();
        } else {
            return `${this.getAmpm(strDatetime)} ${uGetDateFormat('H:i', IEDate(strDatetime))}`;
        } 
        //const strDatetime =  `${event.startDate}T${event.startTime}+09:00`
       

    }

    isAllDayEvent(event: reservedb.ResponseBookingItem){
        let isAllDayEvent = false

        if(event.startTime == "00:00:00" && event.endTime =="23:59:59")
            isAllDayEvent = true

        return isAllDayEvent
    }

    getDuration(event: reservedb.ResponseBookingItem) {
        if(this.isAllDayEvent(event)) {
            return "";
        } else {
            const startDatetime = new Date(`${event.startDate}T${event.startTime}+09:00`)
            const endDatetime = new Date(`${event.endDate}T${event.endTime}+09:00`)
          
            var duration = endDatetime.getTime() - startDatetime.getTime();

            return `${(duration / (1000 * 60 * 60)).toFixed(1)} ${this.$t('W_Hour').toString()}`;
        }        
    }

    getColorClass(event: reservedb.ResponseBookingItem) {
        var colorClass: any = {};
        


        const mapItems = this.colorMapItems.filter(mapItem=>{
            return mapItem.resourceId == event.resourceId
        })

        if(mapItems.length > 0){
            colorClass[mapItems[0].colorClass] = true;
        }

        return colorClass;
    }

    onEventDblClick(event: reservedb.ResponseBookingItem) {
 
        //반복일정인 경우 모달창 popup
        if (event.isRecursive == true) {
            modalHelper.show({ component: OccurrenceSelect, force: true, value: event });
        } else {
            openReserveCalendarItem(event);
        }        
    }



    ///database
    @Watch("currentDate.date")
    @Watch("bookingList")
    onChangedCurrentDate(){
        // alert('changed date')

        const strBaseDatetime = uGetDateFormat('yyyy-MM-DD', IEDate(this.currentDate.date))
        
        this.displayItems = this.bookingList.filter(item=>{
            return item.startDate.startsWith(strBaseDatetime)
        }) 


        // sumResult.sort((a :reservedb.ResponseBookingItem,b : reservedb.ResponseBookingItem)=>{
        //     return +new Date(a.startDate) - +new Date(b.startDate)
        // })
    }

}