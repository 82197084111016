import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
 
import { ModalLayout } from '@appbase/templates'
import api from '@reservedb/api'
import ResourceOptionItem from '@reservedb/components/ReserveResource/ResourceDetailOptionItem.vue'

@Component({
    components: {
        ModalLayout,
  
         
    },

})
export default class ResourceApprovalModalView extends Vue {
    @Prop({ type: Boolean }) show!: boolean
    // @Prop({ type: Object }) value!: reservedb.ResponseResourceDetailItem

    approvalMessage : string =""
    selectedStatus : number = 0
    
    created() {
        // alert(`resourceId is ${this.value}`)   
         
    }

    proccess(){
        //진행전 값 유효성 체크해야됨
        this.$emit('approval', this.approvalMessage, this.selectedStatus)
        this.close()
    }
     
    close() {

        this.$emit('close')


    }
 
    
}
