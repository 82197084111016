import Vue from 'vue'
import { invalidateUrl } from '@appbase/util';
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class AttachmentsDb  extends Vue {
    @Prop({ type: Array }) files!: Array<any>;
    

    // @Watch("files")
    // onChangedFiles(){
    //     let files = this.files
    //     debugger
    // }
    // created(){
        
    // }

    download(url: string) {
       
        window.open(url);
    }
}