import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import * as types from '@reservedb/store/types'
import { mapActions, mapGetters } from 'vuex'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';
import { EnumCalendarViewType } from '@reservedb/types'

import WeekAlldayItem from '@reservedb/components/WeekView/WeekAlldayItemDb.vue'
import DayRawItem from './DayRawItemDb.vue';

import { BookingRequestType} from '@reservedb/types/enums'

import api from '@reservedb/api'
import { debounce } from '@appbase/util'

@Component({
    components: {
        WeekAlldayItem,
        DayRawItem
    },

    computed: mapGetters({
        currentDate: types.CALENDAR_DATE_CURRENT,
        dateMatrix: types.CALENDAR_DATE_MATRIX_LIST,
        //monthMatrix: types.CALENDAR_MONTH_MATRIX_LIST,
        selectedResourceIds : types.RESOURCE_SELECTED_RESOURCEIDS,
        viewMode: types.CALENDAR_VIEWMODE,
        allDayEvents : types.RESOURCE_ALLDAY_EVENTS
    }),

    methods: mapActions({
       
        setViewMode: types.CALENDAR_VIEWMODE,
        getBookingList : types.RESOURCE_BOOKING_LIST
    })
})
export default class DayViewDb  extends Vue {
    
    setViewMode!: (viewType: EnumCalendarViewType) => void;
    getBookingList!: (param:reservedb.RequestBookingParam) => Array<Promise<Array<reservedb.ResponseBookingItem>>>
    viewMode!: EnumCalendarViewType
    allDayEvents! : Array<reservedb.ResponseBookingItem>

    currentDate!: reservedb.DateItem;
    dateMatrix!: reservedb.DateItem[][];
    //monthMatrix!: reservedb.DateItem[][];
  

    dateOfMonthText: string = "";
    weekOfMonthText: string = "";
    dateAlldayModel: reservedb.DateItem | null = null;


    //database 
    selectedResourceIds! : Array<number>
    //


    



    @Watch('selectedResourceIds')
    @Watch('currentDate.date')
    @Watch('viewMode')
    onSelectedResourcesChanged( ) {
 
        this.getBookingItems(this.selectedResourceIds,this.currentDate.date)
        
    }


    getBookingItems = debounce((ids:Array<number>, date: Date)=>{
  
        this.getBookingList({
            resourceIds : ids,
            requestType : BookingRequestType.Daily,
            baseDatetime : date ? date : new Date()
            
        })

    },200)




    // @Watch('selectedResources')
    // onSelectedResourcesChanged(newVal: ewsResource.EwsResourceItem[]) {
    //     debugger
    //     this.loadEvents();      
    // }


    @Watch('dateMatrix')
    onDateMatrixChanged(newVal: reservedb.DateItem[][]) {
         
        this.dateOfMonthText = uGetDateFormat(`dd${this.$t('W_Day')}`, IEDate(this.currentDate.date));
        this.weekOfMonthText = `${uGetDateFormat(`MM${this.$t('W_Schedule_Month')}`, IEDate(this.currentDate.date))} ${this.getWeekNum()}${this.$t('W_Week')}`;        
        
        //종일 일정 찾기
        //시작 : 00:00:00 종료가 23:59:59 면 종일 이벤트
        
        //this.dateAlldayModel = this.monthMatrix[0].find(m => uGetDateFormat('yyyyMMdd', IEDate(m.date)) === uGetDateFormat('yyyyMMdd', IEDate(this.currentDate.date))) || null;        
    }

    findAlldayEvent(){
        
    }

    created() {
        this.setViewMode(EnumCalendarViewType.Daily);
                     
    }

    getWeekNum() {
        var weekNumDate = IEDate(this.currentDate.date);
        weekNumDate.setDate(1);

        var firstDaynum = weekNumDate.getDay();
        return Math.floor((this.currentDate.date.getDate() + firstDaynum) / 7) + 1;
    }    
}