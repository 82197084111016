import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import api from '@reserve/api'
import { uGetDateFormat, uGetTimeArray, IEDate } from '@appbase/util/date-helper'
import { FlowFileMeta } from '@appbase/components/common/FlowFileUploader/flow-file-meta'
import { invalidateUrl } from '@/modules/@appbase/util';
import * as types from '@reserve/store/types'
import * as accountTypes from '@account/store/types'
import { ReserveResourceMode } from '@reserve/types';
import { getFullTimetext } from '@reserve/disp-helper';
import { FlowFile } from '@/modules/@appbase/libs/flowjs';

import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import TimePicker from '@reserve/components/TimePicker.vue'
import RecipientField from '@reserve/components/RecipientField.vue';
import RecurringOption from './RecurringOption.vue';
import Attachments from './Attachments.vue';
import { FlowFileUploader } from '@appbase/components/common'
import { LoadingBar } from '@appbase/components/loading';
import { handleClose, openReserveWindow } from '../../popup-handler';
import { EumEditor } from '@appbase/components/editor'
import { transpileModule } from 'typescript';



@Component({
    components: {
        DatePicker,
        TimePicker,
        RecipientField,
        FlowFileUploader,
        RecurringOption,
        EumEditor,
        Attachments,
        LoadingBar
    },

    computed: mapGetters({
        user: accountTypes.USER,
        resourceList: types.EWS_RESOURCEGROUPS_LIST,
        selectedResources: types.SELECTED_RESOURCE_KEYS
    }),

    methods: mapActions({
        createEvent: types.CALENDAR_EVENT_CREATE,
        updateEvent: types.CALENDAR_EVENT_UPDATE,
        cancelEvent: types.CALENDAR_EVENT_CANCEL,
        loadEvents: types.CALENDAR_EVENTLIST_LOAD
    })
})
export default class ReserveResource extends Vue {
    @Prop({ type: Boolean, default: false }) isPopup!: boolean;

    createEvent!: (item: reserve.CalendarItem) => Promise<any>;
    updateEvent!: (item: reserve.CalendarItem) => Promise<any>;
    cancelEvent!: (id: string) => Promise<any>;
    loadEvents!: () => Promise<any[]>;

    user!: account.User;
    resourceList!: ewsResource.EwsResourceItem[];
    selectedResources!: string[];

    mode: ReserveResourceMode = ReserveResourceMode.Write;
    contentLoaded: boolean = false;
    showRecurringOption: boolean = false;
    currentReccuringOption: reserve.CalendarItemRecurrence | null = null;
    recurrenceDesc: string = "";
    recurrenceIntervalDesc: string = "";
    dispResourceName: string = "";
    confSubject: string = "";

    myCalendarFolderId: string | null = null;
    currentItem: reserve.CalendarItem | null = null;
    curResourceGroup: string = "";
    curResourceItem: string = "";
    resourceGroup: ewsResource.EwsResourceItem[] = [];
    resourceItem: ewsResource.EwsResourceItem[] = [];

    displayResourceItem: ewsResource.EwsResourceItem[] = [];


    ewsResourceList: ewsResource.EwsResourceItem[] = [];
    masterOccurrence: string | null = null;

    startTimeArray: any[] = [];
    endTimeArray: any[] = [];

    isAlldayEvent: boolean = false;

    eventStart: string = uGetDateFormat('yyyy-MM-dd', new Date());
    eventStartTime: string = "";
    eventEnd: string = uGetDateFormat('yyyy-MM-dd', new Date());
    eventEndTime: string = "";
    dispEventTime: string = "";

    requiredRecipients: orgmap.UserItem[] = [];
    optionalRecipients: orgmap.UserItem[] = [];

    uploadUrl: string = invalidateUrl('/file/store/flow');
    deleteUrl: string = invalidateUrl('/file/store/deleteFile');
    sizeLimit: number | null = null;
    bannedExtentions: string[] = [];
    files: FlowFile[] = [];
    ewsAttachments: ewsResource.EwsAttachment[] = [];
    isFileUploading: boolean = false;
    isCheckResource: boolean = false;
    isLoading: boolean = false;
    uploaderConfig: FlowFileMeta = {
        code: '',
        codeValues: [],
        type: 'file'
    };

    reserveContent: string = "";
    tinyopts: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,
        height: 500,
        inline: false,
        menubar: false,
        language: 'ko_KR',
    }

    @Watch('eventStart')
    @Watch('eventStartTime')
    onEventStartChanged(newVal: string) {
        if (this.contentLoaded) {
            this.handleStartChanged();
        }
    }

    @Watch('eventEnd')
    @Watch('eventEndTime')
    async onEventEndChanged(newVal: string) {
        if (this.contentLoaded) {
            var curStart = IEDate(`${this.eventStart} ${this.eventStartTime}`);
            var curEnd = IEDate(`${this.eventEnd} ${this.eventEndTime}`);

            if (curEnd.getTime() <= curStart.getTime()) {
                await (window as any).alertAsync(this.$t('시간선택_오류').toString());
                this.$nextTick(() => this.handleStartChanged());
            } else {
                this.updateAvailableResources(curStart, curEnd);
            }
        }
    }

    created() {
        var { reserveId, resource, open } = this.$route.query;

        if (reserveId && resource) {
            var curResource = this.resourceList.find(r => r.address === resource.toString());
            this.dispResourceName = curResource ? curResource.displayName : "";
            this.curResourceItem = resource.toString();
            this.masterOccurrence = open ? open.toString() : null;

            this.mountEditMode(resource.toString(), reserveId.toString())
        } else {
            this.mountWriteMode()
        }
    }

    mountWriteMode() {
        this.mode = ReserveResourceMode.Write;
        const uid = `uid-${Date.now()}`;
        this.uploaderConfig.codeValues = [this.user.comCode, 'reserve', this.user.email, uid];
        this.uploaderConfig.code = `RESERVE_${this.user.id}_${uid}`;

        api.resource.getMyCalendarFolderId()
            .then(res => {
                this.myCalendarFolderId = res;
                this.mountTimeArray();
                this.mountResources().then(res => this.contentLoaded = true);
            });
    }

    mountEditMode(resource: string, reserveId: string) {
        Promise.all([
            api.ewsCalendar.getDetail(reserveId, resource),
            api.ewsCalendar.getContent(reserveId, resource),
            // api.ewsCalendar.getSubject(reserveId)
        ]).then(res => {
            this.currentItem = res[0].item as reserve.CalendarItem;
            this.confSubject = this.currentItem.subject;


            this.mode = this.currentItem.organizer.address.toLowerCase() === this.user.email.toLowerCase() ?
                ReserveResourceMode.Edit : ReserveResourceMode.Read;

            var postProcess: Promise<any> = Promise.resolve();

            if (this.mode == ReserveResourceMode.Edit) {
                var iCalUid = res[0].item.iCalUid;

                if (this.masterOccurrence != null && this.masterOccurrence === 'occurrence') {
                    postProcess = api.ewsCalendar.getOriginalAppointment(iCalUid, res[0].item.start, res[0].item.end);
                } else {
                    postProcess = api.ewsCalendar.getOriginalAppointment(iCalUid);
                }
            }

            postProcess.then(postRes => {
                var mountItem = res[0].item;

                if (this.mode == ReserveResourceMode.Edit) {
                    if (postRes == null) {
                        this.mode = ReserveResourceMode.Read
                    } else {
                        mountItem = postRes.item;
                        this.currentItem = mountItem;
                        this.ewsAttachments = mountItem.attachments;
                    }
                }

                this.mountEditCallback(mountItem, res[1]);
            }).catch(async(err) => {
                await (window as any).alertAsync(this.$t('예약자의_사서함에_존재하지않아_수정할_수_없습니다').toString());
                this.mode = ReserveResourceMode.Read
                this.mountEditCallback(res[0].item, res[1]);
            });
        }).catch(async (err) => {
            await (window as any).alertAsync(this.$t('취소된_일정').toString());
            handleClose(this.isPopup);
        })
    }

    mountEditCallback(mountItem: any, content: string) {
        this.mountTimeArray(mountItem);
        this.mountAttendees(mountItem);

        if (typeof (mountItem.recurrence) === 'object') {
            this.mountRecurrence(mountItem.recurrence);
        }

        this.reserveContent = content;
        this.contentLoaded = true;
    }

    mountResources() {
        return api.resource.getAvailableRooms({
            start: IEDate(`${this.eventStart} ${this.eventStartTime}`),
            end: IEDate(`${this.eventEnd} ${this.eventEndTime}`),
            includeCategory: true
        }).then(res => {
            //최초 res 에서 이름 정렬함
            //정렬 진행
            //2022-06-16 리소스 이름 정렬 진행
            res.sort((item, item2) => {

                return item.displayName.toLocaleLowerCase() < item2.displayName.toLocaleLowerCase() ? -1 : 1
            })


            var selectDefined = false;

            if (this.selectedResources.length == 1) {
                this.ewsResourceList = res;
                var selectedResource = this.ewsResourceList.find(r => r.uniqueId === this.selectedResources[0]);

                if (selectedResource) {
                    this.resourceGroup = this.ewsResourceList.filter(g => g.mailboxType === 'PublicGroup');
                    this.curResourceGroup = this.resourceGroup.find(g => g.uniqueId === selectedResource?.parentId)?.uniqueId || this.resourceGroup[0].uniqueId;
                    this.resourceItem = this.ewsResourceList.filter(i => i.mailboxType === 'Mailbox' && i.parentId === this.curResourceGroup);
                    this.curResourceItem = selectedResource.uniqueId;
                    selectDefined = true;
                }
            }

            if (!selectDefined) {
                this.ewsResourceList = res;
                this.resourceGroup = this.ewsResourceList.filter(g => g.mailboxType === 'PublicGroup')
                this.curResourceGroup = this.resourceGroup[0].uniqueId;

                this.resourceItem = this.ewsResourceList.filter(i => i.mailboxType === 'Mailbox' && i.parentId === this.curResourceGroup)
                this.curResourceItem = this.resourceItem[0].uniqueId;
            }






            return res;
        });
    }

    mountTimeArray(item?: reserve.CalendarItem) {
        this.startTimeArray = uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString()
        ]);
        this.endTimeArray = uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString()
        ]);

        if (item) {
            this.eventStart = uGetDateFormat('yyyy-MM-dd', IEDate(item.start));
            this.eventEnd = uGetDateFormat('yyyy-MM-dd', IEDate(item.end));
            this.eventStartTime = uGetDateFormat('H:i:s', IEDate(item.start));
            this.eventEndTime = uGetDateFormat('H:i:s', IEDate(item.end));
            this.dispEventTime = getFullTimetext(item);
        } else {
            var { param } = this.$route.query;

            if (param) {
                var decode = atob(decodeURIComponent(param.toString()));
                var reserveParam: reserve.ReserveResourceParam = JSON.parse(decode);

                this.eventStart = reserveParam.eventStart;
                this.eventEnd = reserveParam.eventEnd;
                this.eventStartTime = reserveParam.eventStartTime;
                this.eventEndTime = reserveParam.eventEndTime;
            } else {
                var timeNow = new Date();
                if (timeNow.getMinutes() >= 30) {
                    timeNow.setHours(timeNow.getHours() + 1);
                    this.eventStartTime = uGetDateFormat("H:00:00", timeNow);
                    timeNow.setHours(timeNow.getHours() + 1);
                    this.eventEndTime = uGetDateFormat("H:00:00", timeNow);
                } else {
                    this.eventStartTime = uGetDateFormat("H:30:00", timeNow);
                    timeNow.setHours(timeNow.getHours() + 1);
                    this.eventEndTime = uGetDateFormat("H:30:00", timeNow);
                }
            }
        }
    }

    mountAttendees(item: reserve.CalendarItem) {
        this.requiredRecipients = item.requiredAttendees.map(a => <orgmap.UserItem>{
            displayName: a.name,
            titleName: "",
            deptName: a.address,
            email: a.address
        });

        this.optionalRecipients = item.optionalAttendees.map(a => <orgmap.UserItem>{
            displayName: a.name,
            titleName: "",
            deptName: a.address,
            email: a.address
        });
    }

    mountRecurrence(recurrence: any) {
        var recurringEndType = '1';

        if (recurrence.hasEnd) {
            if (recurrence.numberOfOccurrences != null) {
                recurringEndType = '2'
            } else {
                recurringEndType = '3'
            }
        }

        this.currentReccuringOption = <reserve.CalendarItemRecurrence>{
            daysOfTheWeekArray: recurrence.daysOfTheWeekArray,
            startDate: uGetDateFormat('yyyy-MM-dd', IEDate(recurrence.startDate)),
            endDate: recurrence.endDate != null ? uGetDateFormat('yyyy-MM-dd', IEDate(recurrence.endDate)) : null,
            interval: recurrence.interval,
            numberOfOccurrences: recurrence.numberOfOccurrences,
            ui_endType: recurringEndType,
            ui_recurringType: this.currentItem?.pidLidRecurrenceType || 1,
            dayOfMonth: recurrence.dayOfMonth || "",
            monthValue: recurrence.monthValue || 1
        }

        this.updateRecurrenceDesc();
    }

    handleStartChanged() {
        var setStart = IEDate(`${this.eventStart} ${this.eventStartTime}`);
        var setEnd = IEDate(`${this.eventStart} ${this.eventStartTime}`);
        setEnd.setHours(setEnd.getHours() + 1);

        this.eventEnd = uGetDateFormat('yyyy-MM-dd', IEDate(setEnd));
        this.eventEndTime = uGetDateFormat('H:i:00', IEDate(setEnd));

        this.updateAvailableResources(setStart, setEnd);
    }

    updateAvailableResources(start: Date, end: Date) {
        if (this.mode != ReserveResourceMode.Write)
            return;

        this.isCheckResource = true;
        api.resource.getAvailableRooms({
            start: start,
            end: end,
            includeCategory: true
        }).then(res => {
            this.ewsResourceList = res;
            var prevItem = this.ewsResourceList.find(r => r.uniqueId === this.curResourceItem);

            if (prevItem) {
                this.resourceGroup = this.ewsResourceList.filter(g => g.mailboxType === 'PublicGroup');
                this.curResourceGroup = this.resourceGroup.find(g => g.uniqueId === prevItem?.parentId)?.uniqueId || this.resourceGroup[0].uniqueId;
                this.resourceItem = this.ewsResourceList.filter(i => i.mailboxType === 'Mailbox' && i.parentId === this.curResourceGroup);
                this.curResourceItem = prevItem.uniqueId;
            } else {
                this.resourceGroup = this.ewsResourceList.filter(g => g.mailboxType === 'PublicGroup');
                this.curResourceGroup = this.resourceGroup[0].uniqueId;
                this.resourceItem = this.ewsResourceList.filter(i => i.mailboxType === 'Mailbox' && i.parentId === this.curResourceGroup);
                this.curResourceItem = this.resourceItem[0].uniqueId;
            }
            this.isCheckResource = false;
        });
    }

    fileUploadStatusChaged(data: any) {
        if (data === 'uploading') {
            this.isFileUploading = true
        } else {
            this.isFileUploading = false
        }
    }

    onCancelClick() {
        this.loadEvents();
        handleClose(this.isPopup);
    }

    async checkRequestValidate(requestResource: string, requestItem: reserve.CalendarItem): Promise<boolean> {
        var current = new Date();
        var { reserveId } = this.$route.query;
        var checkStart = IEDate(`${requestItem.start} ${requestItem.starttime}`);
        var checkEnd = IEDate(`${requestItem.end} ${requestItem.endtime}`);


        if (!requestItem.subject) {
            await (window as any).alertAsync(this.$t('회의_제목을_입력하세요').toString());
            return false;
        }


        if (checkStart.getTime() <= current.getTime()) {
            await (window as any).alertAsync(this.$t('회의실_과거예약').toString());
            return false;
        }

        var availability = await api.resource.getResourceAvailable({
            resource: requestResource,
            start: checkStart,
            end: checkEnd,
            originalId: this.mode == ReserveResourceMode.Edit ? reserveId.toString() : undefined
        })

        if (!availability.available) {
            await (window as any).alertAsync(this.$t('회의실_사용시간중복').toString());
            return false;
        }

        return true;
    }

    async onReserveClick() {
        this.isLoading = true;
        this.reserveContent = this.getEditorContent();
        var requestResource = this.ewsResourceList.find(r => r.uniqueId === this.curResourceItem);
        var requestItem: reserve.CalendarItem = {
            parentId: '',
            //attachments: [] as any,
            hasAttachments: false,
            body: '',
            location: '',
            subject: '',
            status: 'S0',
            appointmentType: 'Single',
            pidLidRecurrenceType: 1,
            ui_resourceArray: [] as Array<any>,
            resourceId: null,
            resources: [] as Array<any>,
            recurrence: {
                startDate: uGetDateFormat('yyyy-MM-dd', new Date()),
                endDate: uGetDateFormat('yyyy-MM-dd', new Date()),
                ui_useRecurringButton: true,
                ui_useDefaultDate: true,
                ui_useRecurringDisplay: false,
                ui_useRecurring: false,
                ui_recurringType: 1,
                monthValue: (new Date().getMonth() + 1),
                firstDayOfWeekValue: 1,
                daysOfTheWeekArray: [] as number[],
                ui_endType: '1',
                interval: 1,
                numberOfOccurrences: 0,
            } as reserve.CalendarItemRecurrence,
            code: this.uploaderConfig.code,
            codeValues: this.uploaderConfig.codeValues,
            importance: 'Normal',
            isCancelled: false,
            isRecurring: false,
            isAllDayEvent: false,
            isAllowUpdate: true,
            start: uGetDateFormat('yyyy-MM-DDTH:i:00', new Date()),
            end: uGetDateFormat('yyyy-MM-DDTH:i:00', new Date()),
            requiredAttendees: [] as any,
            optionalAttendees: [] as any,
            isReadyData: false,
            delegateUser: '',
            reminderMinutesBeforeStart: 15,
        } as reserve.CalendarItem;

        if (requestResource && this.myCalendarFolderId) {
            requestItem.subject = this.confSubject;
            requestItem.body = this.reserveContent;
            requestItem.parentId = this.myCalendarFolderId;
            requestItem.importance = "Normal";
            requestItem.appointmentType = 'Single';
            requestItem.isRecurring = false;
            requestItem.reminderMinutesBeforeStart
            requestItem.isAllDayEvent = this.isAlldayEvent;
            requestItem.start = this.eventStart;
            requestItem.starttime = this.isAlldayEvent ? "00:00:00" : this.eventStartTime;
            // requestItem.end = this.isAlldayEvent ? "00:00:00" : this.eventEnd;
            // requestItem.endtime = this.eventEndTime;
            requestItem.end = this.eventEnd;
            requestItem.endtime = this.isAlldayEvent ? "00:00:00" : this.eventEndTime;
            requestItem.location = requestResource.displayName;
            requestItem.requiredAttendees = this.requiredRecipients.map(r => <ewsResource.EwsRecipient>{ name: r.displayName, address: r.email });
            requestItem.optionalAttendees = this.optionalRecipients.map(r => <ewsResource.EwsRecipient>{ name: r.displayName, address: r.email });
            requestItem.requiredAttendees.push({
                name: requestResource.displayName,
                address: requestResource.address
            });

            if (requestItem.isAllDayEvent && (requestItem.start === requestItem.end)) {
                var changeEnd = IEDate(requestItem.end);
                changeEnd.setDate(changeEnd.getDate() + 1);
                requestItem.end = uGetDateFormat('yyyy-MM-dd', changeEnd);
            }

            if (this.currentReccuringOption != null) {
                requestItem.isRecurring = true;
                requestItem.recurrence = this.currentReccuringOption;
            }

            var validate = await this.checkRequestValidate(requestResource.address, requestItem);

            if (validate) {
                this.createEvent(requestItem).then(async res => {
                    await (window as any).alertAsync(this.$t('예약신청_완료').toString());
                    handleClose();
                })
            } else {
                this.isLoading = false;
            }
        }
    }

    updateRecurrenceDesc() {
        if (this.currentReccuringOption != null) {
            var constMonthArray: string[] = [
                this.$t('W_1M').toString(),
                this.$t('W_2M').toString(),
                this.$t('W_3M').toString(),
                this.$t('W_4M').toString(),
                this.$t('W_5M').toString(),
                this.$t('W_6M').toString(),
                this.$t('W_7M').toString(),
                this.$t('W_8M').toString(),
                this.$t('W_9M').toString(),
                this.$t('W_10M').toString(),
                this.$t('W_11M').toString(),
                this.$t('W_12M').toString()
            ];

            var constWeekArray: string[] = [
                this.$t('W_Schedule_Sunday').toString(),
                this.$t('W_Schedule_Monday').toString(),
                this.$t('W_Schedule_Tuesday').toString(),
                this.$t('W_Schedule_Wednesday').toString(),
                this.$t('W_Schedule_Thursday').toString(),
                this.$t('W_Schedule_Friday').toString(),
                this.$t('W_Schedule_Saturday').toString()
            ];

            var dispTimeArray = uGetTimeArray([
                this.$t('W_AM').toString(),
                this.$t('W_PM').toString()
            ]);

            var idxStartTime = dispTimeArray.findIndex(t => t.value === this.eventStartTime);
            var idxEndTime = dispTimeArray.findIndex(t => t.value === this.eventEndTime);
            var dispHour = (idxEndTime - idxStartTime) * 0.5;
            var timeSpan = this.isAlldayEvent ?
                this.$t('W_Schedule_Allday').toString() :
                `${dispTimeArray[idxStartTime].text} ~ ${dispTimeArray[idxEndTime].text} (${dispHour}${this.$t('W_Hour').toString()})`;
            var prefix = "";

            switch (this.currentReccuringOption.ui_recurringType) {
                case 1:
                    prefix = this.$t('매N일마다').toString().replace('{INTERVAL}', this.currentReccuringOption.interval.toString());
                    break;
                case 2:
                    var dayJoin = this.currentReccuringOption.daysOfTheWeekArray?.map(i => constWeekArray[i]).join(",");
                    prefix = this.$t('매N주').toString()
                        .replace('{INTERVAL}', this.currentReccuringOption.interval.toString())
                        .replace('{DAYJOINS}', dayJoin || "");
                    break;
                case 3:
                    prefix = this.$t('매N월').toString()
                        .replace('{INTERVAL}', this.currentReccuringOption.interval.toString())
                        .replace('{DAYOFMONTH}', this.currentReccuringOption.dayOfMonth?.toString() || "");
                    break;
                case 4:
                    prefix = this.$t('매년N월').toString()
                        .replace('{MONTH}', constMonthArray[this.currentReccuringOption.monthValue - 1])
                        .replace('{DAYOFMONTH}', this.currentReccuringOption.dayOfMonth?.toString() || "");
                    break;
            }

            this.recurrenceDesc = this.$t('반복일정_설명').toString().replace('{PREFIX}', prefix).replace('{TIMESPAN}', timeSpan);

            switch (this.currentReccuringOption.ui_endType) {
                case "1":
                    this.recurrenceIntervalDesc = this.$t('N부터_계속반복됩니다').toString().replace('{START}', this.currentReccuringOption.startDate);
                    break;
                case "2":
                    this.recurrenceIntervalDesc = this.$t('N회_반복후종료').toString().replace('{OCCURRENCE_NUMBER}', this.currentReccuringOption.numberOfOccurrences?.toString() || "");
                    break;
                case "3":
                    this.recurrenceIntervalDesc = `${this.currentReccuringOption.startDate} ~ ${this.currentReccuringOption.endDate}`;
                    break;
            }
        }
    }

    onRecurringClick() {
        this.showRecurringOption = true;
    }

    onRecurringOptionSave(option: reserve.CalendarItemRecurrence) {
        this.currentReccuringOption = option;
        this.currentReccuringOption.startDate = this.eventStart;
        this.updateRecurrenceDesc();
        this.showRecurringOption = false;
    }

    onReccuringOptionClose() {
        this.showRecurringOption = false;
    }

    onRemoveRecurrenceClick() {
        this.currentReccuringOption = null;
    }

    async onUpdateReserveClick() {
        this.isLoading = true;

        var requestItem = { ...this.currentItem } as reserve.CalendarItem;
        requestItem.subject = this.confSubject;
        requestItem.status = 'S0',
            requestItem.isAllDayEvent = this.isAlldayEvent;
        requestItem.start = this.eventStart;
        requestItem.body = this.reserveContent;
        requestItem.starttime = this.isAlldayEvent ? "00:00:00" : this.eventStartTime;
        // requestItem.end = this.isAlldayEvent ? "00:00:00" : this.eventEnd;
        // requestItem.endtime = this.eventEndTime;
        requestItem.end = this.eventEnd;
        requestItem.endtime = this.isAlldayEvent ? "00:00:00" : this.eventEndTime;
        requestItem.requiredAttendees = this.requiredRecipients.map(r => <ewsResource.EwsRecipient>{ name: r.displayName, address: r.email });
        requestItem.optionalAttendees = this.optionalRecipients.map(r => <ewsResource.EwsRecipient>{ name: r.displayName, address: r.email });

        if (requestItem.isAllDayEvent && (requestItem.start === requestItem.end)) {
            var changeEnd = IEDate(requestItem.end);
            changeEnd.setDate(changeEnd.getDate() + 1);
            requestItem.end = uGetDateFormat('yyyy-MM-dd', changeEnd);
        }

        if (this.currentReccuringOption != null) {
            requestItem.isRecurring = true;
            requestItem.recurrence = this.currentReccuringOption;
        }

        var validate = await this.checkRequestValidate(this.curResourceItem, requestItem);

        if (validate) {
            this.updateEvent(requestItem).then(async res => {
                await(window as any).alertAsync(this.$t('예약신청_업데이트').toString())
                await handleClose();
            })
        } else {
            this.isLoading = false;
        }
    }

    async onCancelReserveClick() {

        if (this.currentItem && await(window as any).confirmAsync(this.$t('예약삭제_확인').toString())) {
            this.isLoading = true;

            await this.cancelEvent(this.currentItem.id)
                .then(async res => {

                    await(window as any).alertAsync(this.$t('예약삭제_안내').toString())

                    await handleClose();
                })
        }
    }

    onNewWindowClick() {
        openReserveWindow();
    }


    @Watch("curResourceGroup")
    onChangeResourceGroup() {
        //alert("changed")

        this.resourceItem = this.ewsResourceList.filter(item => {
            return item.parentId == this.curResourceGroup
        })

        // 선택된 항목이 1가지인 경우
        if (this.selectedResources.length == 1){
            this.curResourceItem = this.selectedResources[0];
        }
        else{
            //첫번째 아이템 set
            this.curResourceItem = this.resourceItem[0].uniqueId
        }
    }

    getEditorContent(){
        const editor: editor.IEditorProxy = this.$refs.editor as any
        return editor.getEditor().getBodyValue();
    }
}