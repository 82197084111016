import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types';
import * as types from '@reserve/store/types'
import { EnumCalendarViewType } from '../types';
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';

import DatePicker from '@appbase/components/DatePicker/DatePicker.vue';


@Component({
    components: {
        DatePicker
    },

    computed: mapGetters({
        current: accountTypes.CURRENT,
        isLoading: types.CALENDAR_LOADING,
        viewMode: types.CALENDAR_VIEWMODE,
        currentDate: types.CALENDAR_DATE_CURRENT,
        rightPaneOpen: types.CALENDAR_RIGHTPANE_OPEN,
        leftPaneOpen: types.CALENDAR_LEFTPANE_OPEN,
        searchSet: types.CALENDAR_SEARCHSET,
        listViewOpen: types.MAIN_LISTVIEW_OPEN
    }),

    methods: mapActions({
        selectDate: types.CALENDAR_DATE_SELECTRELOAD,
        toggleRightPane: types.CALENDAR_TOGGLE_RIGHTPANE,
        toggleLeftPane: types.CALENDAR_TOGGLE_LEFTPANE,
        searchEvents: types.CALENDAR_SEARCH_EVENTS_LOAD,
        searchSetUpdate: types.CALENDAR_SEARCHSET_UPDATE,
        toggleListView: types.TOGGLE_MAIN_LISTVIEW
    })
})
export default class ViewHeader  extends Vue {     
    selectDate!: (dateItem: reserve.DateItem) => Promise<any>;
    toggleRightPane!: (payload: boolean) => void;
    toggleLeftPane!: (payload: boolean) => void;
    searchEvents!: (payload?: reserve.ReserveSearchSet) => Promise<any>;
    searchSetUpdate!: (payload: reserve.ReserveSearchSet) => void;
    toggleListView!: (payload: boolean) => void;
    
    current!: account.TCurrent;
    isLoading!: boolean;
    currentDate!: reserve.DateItem;
    viewMode!: EnumCalendarViewType;
    rightPaneOpen!: boolean;
    leftPaneOpen!: boolean;
    searchSet!: reserve.ReserveSearchSet;
    listViewOpen!: boolean;

    showSearchModal: boolean = false;
    modalSearchSet: reserve.ReserveSearchSet = {
        reserveContent: "",
        reserveTitle: "",
        reserverName: "",
        searchStart: uGetDateFormat('yyyy-MM-dd', IEDate(new Date())),
        searchEnd: uGetDateFormat('yyyy-MM-dd', IEDate(new Date())),
    }
    
    get routeName() {
        return this.$route.name;
    }

    get currentMonthText() {
        return this.current.userConfig.language === 'ko-KR' ?
            uGetDateFormat('yyyy년 MM월', IEDate(this.currentDate.date)) :
            uGetDateFormat('MM/yyyy', IEDate(this.currentDate.date));
    }
    
    created() {        
        var today = new Date();    
        this.modalSearchSet.searchStart = uGetDateFormat('yyyy-MM-dd', IEDate(today));
    
        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(endDate.getDate() - 1);
        this.modalSearchSet.searchEnd = uGetDateFormat('yyyy-MM-dd', IEDate(endDate));
    
        if (this.viewMode == EnumCalendarViewType.Month){
            this.toggleRightPane(true);
        }
        else{
            this.toggleRightPane(false);
        }    
    }

    mounted() {    
        this.$nextTick(() => {
            this.syncLeftSideBar();
        })       
    }

    syncLeftSideBar() {        
        var navElem = document.getElementById('snb') as HTMLDivElement;

        if(this.leftPaneOpen) {
            navElem.classList.add('sidenav-on');
            navElem.classList.remove('sidenav-off');
        } else {
            navElem.classList.add('sidenav-off');
            navElem.classList.remove('sidenav-on');
        }   
    }

    changeView(type: string) {
        if(this.isLoading)
            return;
            
        // MENUID 파라메터가 있으면 전달한다.
        const { MENUID } = this.$route.query;
        if (MENUID){
            this.$router.push({ name: type, query: { MENUID: MENUID } });
        }
        else {
            this.$router.push({ name: type });
        }

        if (type == 'reserve-month'){
            this.toggleRightPane(true);
        }
        else{
            this.toggleRightPane(false);
        }
    }

    onToggleLeftPane(toggle: boolean) {  
        this.toggleLeftPane(toggle);   
        this.syncLeftSideBar();
    }

    goToday() {
        if(this.isLoading)
            return;

        var todayItem: reserve.DateItem = {
            ...this.currentDate,
            date: new Date()
        }

        this.selectDate(todayItem);
    }

    goNext() {
        if(this.isLoading)
            return;

        var nextItem: reserve.DateItem = {
            ...this.currentDate
        }

        switch(this.viewMode) {
            case EnumCalendarViewType.Month:
                nextItem.date.setMonth(nextItem.date.getMonth() + 1);
                break;
            case EnumCalendarViewType.Week:
                nextItem.date.setDate(nextItem.date.getDate() + 7);
                break;
            case EnumCalendarViewType.Daily:
                nextItem.date.setDate(nextItem.date.getDate() + 1);
                break;    
        }

        this.selectDate(nextItem);
    }

    goPrev() {
        if(this.isLoading)
            return;

        var prevItem: reserve.DateItem = {
            ...this.currentDate
        }

        switch(this.viewMode) {
            case EnumCalendarViewType.Month:
                prevItem.date.setMonth(prevItem.date.getMonth() - 1);
                break;
            case EnumCalendarViewType.Week:
                prevItem.date.setDate(prevItem.date.getDate() - 7);
                break;
            case EnumCalendarViewType.Daily:
                prevItem.date.setDate(prevItem.date.getDate() - 1);
                break;    
        }

        this.selectDate(prevItem);
    }

    onToggleRighePane(toggle: boolean) {
        this.toggleRightPane(toggle);
    }

    onSearchClick() {
        this.searchSetUpdate(this.modalSearchSet);
        this.searchEvents()
            .then(res => this.$router.push({ name: 'reserve-search' }));
    }

    toggleSearchModal() {
        this.showSearchModal = !this.showSearchModal;
    }

    onListViewClick() {
        this.toggleListView(!this.listViewOpen);
    }
}