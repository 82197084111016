import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { FullMasterTemplate} from '@appbase/templates'
import { LeftMenu } from '@appbase/components/common';

@Component ({
    components: {
        FullMasterTemplate,
        LeftMenu
    }
})
export default class ReserveMaster extends Vue {
}
