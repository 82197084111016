import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapGetters, mapMutations } from 'vuex'
import * as types from '@reservedb/store/types'

import ViewHeader from '@reservedb/components/ViewHeader.vue'
import RightPane from '@/modules/reservedb/components/RightPaneDb.vue'
import ListView from '@/modules/reservedb/components/ListView/ListViewDb.vue'
import { LoadingBar } from '@appbase/components/loading'
import SideMenu from '@reservedb/components/SideMenu/SideMenuDb.vue'


import SubContentComponent from '@appbase/templates/SubContentTemplate.vue'


@Component({
    components: {
        SubContentComponent,
        ViewHeader,
        RightPane,
        ListView,
        LoadingBar,
        SideMenu
    },

    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        listViewOpen: types.MAIN_LISTVIEW_OPEN
    }),

    methods: mapMutations({
        initPreviousPayload: types.PREVIOUSE_PAYLOAD
    })
})
export default class CalendarMaster extends Vue {
    isLoading!: boolean;
    listViewOpen!: boolean;
    initPreviousPayload!: (data: reservedb.RequestBookingParam) => void

    destroyed() {
        //자원 예약 요청 파람이 기록되어 있기 때문에 calendar 컴포넌트가 destorey 될 때 해당 값 초기화 진행
        //로드가 안되는 케이스가 발생 할 수 있음

        this.initPreviousPayload(Object.create(null))
    }




    async beforeCreate() {
        // SYSID 가 없는 경우 모두 잘못된 접근으로 간주함
        const sysId = this.$route.query.SYSID

        if (!sysId) {
            await (window as any).alertAsync(this.$t("잘못_된_접근입니다").toString())
            this.$router.push({
                name:"Home"
            })
        }


    }
}