import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'
import modalHelper from '@appbase/util/modal-helper';

import SearchPanel from '@reservedb/components/SearchView/SearchPanel.vue'
import GridView from '@appbase/components/GridView/GridView.vue'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';
import { getAmpm, getDayText } from '@reservedb/disp-helper';
import { openReserveCalendarItem } from '@reservedb/popup-handler';
import OccurrenceSelect from '@reservedb/components/OccurrenceSelect.vue';

import api from '@reservedb/api'

@Component({
    components: {
        SearchPanel,
        GridView
    },

    computed: mapGetters({
        events: types.CALENDAR_SEARCH_EVENTS,
        selectedResourceId : types.RESOURCE_SELECTED_RESOURCEIDS,
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
        searchSet: types.CALENDAR_SEARCHSET
        
    }),

    methods: mapActions({

    })
         
})
export default class SearchView  extends Vue {
    selectedResourceId! : Array<number> 
   
    colorMapItems! : Array<reservedb.ColorMapItem>
    reserveModel: Array<reservedb.ResponseBookingSearchItem> = [];
    reserveHeaders: gridView.Header[] = [];
    searchSet!: reservedb.ReserveSearchSet;
     

    async created() {     
        //여기로 바로 올 수도 있으니 선택된 자원을 체크해야곘지
        if(this.selectedResourceId.length == 0){
            await (window as any).alertAsync(this.$t("선택된_자원이_없습니다").toString())
            this.$router.push({
                name : "Home"
            })
        }


        this.reserveHeaders = [
            { displayName: this.$t('제목').toString(), rowKey: 'subject', align: 'left', width:'*', slotName: 'reserveTitle' },
            { displayName: this.$t('예약자명').toString(), rowKey: 'organizer', align: 'left', slotName: 'reserveOrganizer', width: '250' },
            { displayName: this.$t('W_Schedule_Start').toString(), rowKey: 'start', align: 'left', width: '250', slotName: 'reserveStart' },
            { displayName: this.$t('W_Schedule_End').toString(), rowKey: 'end', align: 'left', width: '250', slotName: 'reserveEnd' },
            { displayName: this.$t('자원명').toString(), rowKey: 'location', align: 'left', width: '300', slotName:'reserveResource' },
            
        ]



        this.doSearch(this.searchSet)
    }

    getColorClass(resourceId : number) {
        var colorClass: any = {};
        
       

        const mapItems = this.colorMapItems.filter(mapItem=>{
            return mapItem.resourceId == resourceId
        })

        if(mapItems.length > 0){
            colorClass[mapItems[0].colorClass] = true;
        }

        return colorClass;
    }

    doSearch(searchSet: reservedb.ReserveSearchSet) {
         
  
         api.booking.SearchBookingItems({
             startDatetime : new Date(`${searchSet.searchStart}T00:00:00+09:00`) ,
             endDatetime : new Date(`${searchSet.searchEnd}T23:59:59+09:00`),
             title : searchSet.reserveTitle,
             content : searchSet.reserveContent,
             bookerName : searchSet.reserverName,
             resourceIds : this.selectedResourceId
         }).then(result =>{
             this.reserveModel = result
         })
       
    }

    onClickItem(bookingId : number){
        //alert(bookingId)


        
       
    
        this.$router.push({
            name: 'reservedb-write',
            query: {
                ...this.$route.query,
                bookingId: bookingId.toString(),
                open : 'occurrence',
                from: 'search'
            }
        });
    }


    convertDisplayInfo(item : reservedb.ResponseBookingSearchItem){
        return `[${item.requesterDeptName}] ${item.requesterDisplayName} ${item.requesterTitleName}`
    }
}