import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reserve/store/types'
import { getResourceColor } from '@reserve/store/consts'
import * as popupHelper from '@appbase/util/popup-helper';
import modalHelper from '@appbase/util/modal-helper';
import * as appbaseTypes from '@appbase/store/types';
import ResourceInfo from '../ResourceInfo/ResourceInfo.vue';
import * as usertypes from '@account/store/types';

@Component({
    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        selectedResourceKeys: types.SELECTED_RESOURCE_KEYS,
        isTeams: appbaseTypes.TEAMS,
        user: usertypes.USER,
    }),

    methods: mapActions({
        selectResource: types.TOGGLE_RESOURCE_SELECT,
        loadResourceBookmark: types.EWS_RESOURCEBOOKMARK_LOAD
    })
})
export default class ResourceItem  extends Vue {
    @Prop({ type: Object }) item!: ewsResource.EwsResourceItem;

    selectResource!: (payload: any) => void;
    loadResourceBookmark!: (payload: object) => Promise<reserve.ResourceBookmark[]>;

    user!: account.TUser

    selectedResourceKeys!: string[];
    isLoading!: boolean;
    isTeams!: boolean;

    selected: boolean = false;
    colorCodeClass: any = {};

    @Watch('selectedResourceKeys')
    OnSelectChanged(newVal: string[]) {
        this.selected = newVal.indexOf(this.item.uniqueId) >= 0;
        this.colorCodeClass[this.item.colorCode] = this.selected;
    }

    openInfoPopup(){
        popupHelper.openPopup(`resourceInfo_${this.item.uniqueId}`, "/reservePopup/info",
            {
                resourceGuid: this.item.uniqueId
            },
            {
                width: 620,
                height: 800,
                isFrame: this.isTeams,
                top: 50
            },
            this.ReloadBookmarkList
        )      

        // Teams에서 예약목록 Tree의 z-index 값이 1000으로 되어 있어 해상도가 작은 곳에서는 뒤로 숨어버림
        if (this.isTeams){
            setTimeout(() => {
                $('#dvlayerPopup').css("z-index", 2000)
            }, 600)
        }
    }

    // Bookmark 목록 조회
    ReloadBookmarkList(){
        this.loadResourceBookmark({ personCode: this.user.personCode, comCode: this.user.comCode });
    }

    created() {
        this.selected = this.item.selected || false;        
        this.colorCodeClass[this.item.colorCode] = this.selected;

        this.OnSelectChanged(this.selectedResourceKeys);
    }

    selectChanged() {
        this.selectResource({ id: this.item.uniqueId, select: this.selected });
    }

    getColorCode(code: string | null) {
        if(this.selected) {
            return code == null ?
                getResourceColor(this.item.uniqueId) : code;
        } else {    
            return null;
        }
    }
}