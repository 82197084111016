import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'
import { getResourceColor } from '@reservedb/store/consts'
import * as evtBus from '@appbase/util/eventBus';



@Component({
    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        colorMapItems : types.RESOURCE_COLOR_MAPITEM,
    }),

    methods: mapActions({
         
    })
})
export default class ResourceItemDb  extends Vue {
    @Prop({ type: Object }) item!: reservedb.ResponseResourceBoardMapItem;
    colorMapItems! : Array<reservedb.ColorMapItem>
     

    selectedResourceKeys!: string[];
    isLoading!: boolean;

    selected: boolean = false;
    colorCodeClass: any = {};
 
    created() {
        this.mapColor()
    }

    selectChanged() {
        //선택
        this.$emit("update",[{
            id: this.item.reserveResourceId,
            selected : this.selected
        }])
       
    }
    
    mapColor(){
        let filteredItems = this.colorMapItems.filter(item=>{
            return item.resourceId == this.item.reserveResourceId
        })

        if(filteredItems.length > 0){
            this.colorCodeClass[filteredItems[0].colorClass] = true
            
        }
    }

    // getColorCode(code: string | null) {
    //     if(this.selected) {
    //        return this.colorCodeClass
    //     } else {    
    //         return null;
    //     }
    // }

    
// const getHashCode = function(plainText: string) {
//     var hash = 0, i, chr;
//     if (plainText.length === 0) return hash;
//     for (i = 0; i < plainText.length; i++) {
//       chr   = plainText.charCodeAt(i);
//       hash  = ((hash << 5) - hash) + chr;
//       hash |= 0; // Convert to 32bit integer
//     }
//     return hash;
// };

// export const getResourceColor = function(uniqueId: string) {
//     return COLOR_ARRAY[Math.abs(getHashCode(uniqueId)) % COLOR_ARRAY.length];
// }

    showResourceDetail(){
 
       evtBus.dispatchEventBus(window,types.EVTBUS_RESOURCE_DETAIL_SHOW,this.item.reserveResourceId)
    }
}