var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c('div',{staticClass:"item",class:_vm.colorClass,style:({
        height: [((_vm.item.ui_week_height_count * 30) - 1)+'px'],        
        opacity: [_vm.item.isCancelled == true ? 0.1 : 1]
    }),on:{"dblclick":function($event){$event.stopPropagation();return _vm.onEventDblClick.apply(null, arguments)}}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.getAmpm(_vm.item.start))),_c('time',[_vm._v(_vm._s(_vm.getTimetext(_vm.item.start)))])]),_c('span',{staticClass:"contents"},[_vm._v(" "+_vm._s(_vm.item.subject))])]),_c('div',{staticClass:"item-detail direction-right"},[_vm._m(0),_c('table',{attrs:{"width":"100%"}},[_vm._m(1),_c('tbody',[_c('tr',[_c('th',{staticClass:"align-left"},[_c('span',{staticClass:"schedule-category",class:_vm.colorClass})]),_c('td',[_c('h6',{staticClass:"title ft-basic ellipsis line-clamp-2"},[_vm._v(_vm._s(_vm.item.subject))])])]),_c('tr',[_vm._m(2),_c('td',[_c('span',[_vm._v(_vm._s(_vm.getFullTimetext()))])])]),_c('tr',[_vm._m(3),_c('td',[_c('span',[_vm._v(_vm._s(_vm.item.location))])])]),_c('tr',[_vm._m(4),_c('td',[_c('span',[_vm._v(_vm._s(_vm.item.organizer.name))])])])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"arrow-left"},[_c('div',{staticClass:"empty"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('colgroup',[_c('col',{attrs:{"width":"30px"}}),_c('col',{attrs:{"width":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"align-left"},[_c('i',{staticClass:"icon-fux-time"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"align-left"},[_c('i',{staticClass:"icon-fux-location"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"align-left"},[_c('i',{staticClass:"icon-fux-user"})])
}]

export { render, staticRenderFns }