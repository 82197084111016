import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reserve/store/types'
import { EnumCalendarViewType } from '@reserve/types'

import WeekAlldayItem from './WeekAlldayItem.vue';
import WeekRowItem from './WeekRowItem.vue';
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';

@Component({
    components: {
        WeekAlldayItem,
        WeekRowItem
    },

    computed: mapGetters({
        calendarSetting: types.CALENDAR_SETTING,
        currentDate: types.CALENDAR_DATE_CURRENT,
        monthMatrix: types.CALENDAR_MONTH_MATRIX_LIST,
        weekMatrix: types.CALENDAR_WEEK_MATRIX_LIST,
        selectedResources: types.SELECTED_RESOURCE_LIST 
    }),

    methods: mapActions({
        loadEvents: types.CALENDAR_EVENTLIST_LOAD,
        setViewMode: types.CALENDAR_VIEWMODE
    })
})
export default class WeekView  extends Vue {
    loadEvents!: () => Promise<any[]>;
    setViewMode!: (viewType: EnumCalendarViewType) => void;

    currentDate!: reserve.DateItem;
    selectedResources!: ewsResource.EwsResourceItem[];
    calendarSetting!: reserve.CalendarSetting;
    monthMatrix!: reserve.DateItem[][];
    weekMatrix!: reserve.DateItem[][];

    weekOfMonthText: string = "";
    weekLabel: any = {
        0: "일",
        1: "월",
        2: "화",
        3: "수",
        4: "목",
        5: "금",
        6: "토",
    }

    weeklist: any[] = [];

    @Watch('selectedResources')
    onSelectedResourcesChanged(newVal: ewsResource.EwsResourceItem[]) {
        this.loadEvents();
    }

    @Watch('weekMatrix')
    onWeekMatrixChanged(newVal: reserve.DateItem[][]) {
        this.mountWeekList();
        this.weekOfMonthText = `${uGetDateFormat(`MM${this.$t('W_Schedule_Month')}`, IEDate(this.currentDate.date))} ${this.getWeekNum()}${this.$t('W_Week')}`;        
    }

    created() {        
        this.weekLabel = [
            this.$t('W_Schedule_Sunday').toString().substr(0, 1), 
            this.$t('W_Schedule_Monday').toString().substr(0, 1), 
            this.$t('W_Schedule_Tuesday').toString().substr(0, 1), 
            this.$t('W_Schedule_Wednesday').toString().substr(0, 1), 
            this.$t('W_Schedule_Thursday').toString().substr(0, 1), 
            this.$t('W_Schedule_Friday').toString().substr(0, 1), 
            this.$t('W_Schedule_Saturday').toString().substr(0, 1)
        ];
        this.setViewMode(EnumCalendarViewType.Week);        
        this.loadEvents().then(() => this.adjustScroll());     
                            
    }

    adjustScroll() {
        this.$nextTick(() => {
            var divTimeline = this.$refs.divTimeline as HTMLDivElement;
            divTimeline.scrollTo(0, 478);            
        })
    }

    getWeekNum() {
        var weekNumDate = IEDate(this.currentDate.date);
        weekNumDate.setDate(1);

        var firstDaynum = weekNumDate.getDay();
        return Math.floor((this.currentDate.date.getDate() + firstDaynum) / 7) + 1;
    }

    mountWeekList() {
        this.weeklist = this.weekMatrix[0].filter(f => f.date != null).map(x => <any>{
            text: `${x.date.getDate()} ${this.weekLabel[x.date.getDay()]}`,
            day: x.date.getDay()
        });
    }
}