import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class WeekAlldayItem  extends Vue {
    @Prop({ type: Object }) item!: reserve.DateItem;

    allDayEventsModel: reserve.CalendarItem[] = [];

    @Watch('item')
    itemChaged(newVal: reserve.DateItem) {
        this.allDayEventsModel = newVal.events.filter(e => e.isAllDayEvent && e.id !== 'null');        
    }

    created() {
        if(this.item != null) {
            this.itemChaged(this.item);
        }
    }

    getColorClass(event: reserve.CalendarItem) {
        var colorClass: any = {};
        colorClass[event.color] = true;
        return colorClass;
    }
}