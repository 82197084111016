import { uGetTimeArray } from '@/modules/@appbase/util';
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class TimePicker  extends Vue {
    @Prop({ type: String }) value!: string;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;

    timeModel: string = "";
    timeArray: any[] = [];

    @Watch('value')
    onValueChanged(newVal: string) {
        this.timeModel = newVal;
    }

    created() {
        this.timeArray = uGetTimeArray([
            this.$t('W_AM').toString(),
            this.$t('W_PM').toString()
        ]);
        
        this.timeModel = this.value;
    }

    onChange() {
        this.$emit('input', this.timeModel);
        this.$emit('change', this.timeModel);
    }
}