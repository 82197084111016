import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

export interface FilterObject{
    value: number
    text: string
}

@Component({
    components: {},
})


export default class ApprovalSearchFilter extends Vue {
    // @Prop({ type: Number }) selectedStatus!: number  
    // @Prop({ type: Number }) selectedFilter!: number  

    selectedStatus: number = 999
    selectedFilter: number = 0
    keyword: string = ""
    approvalStatus: Array<FilterObject> = [
        {
            value: 999,
            text: this.$t('전체').toString()
        },
        {
            value: 0,
            text: this.$t('대기중').toString()
        },
        {
            value: 1,
            text: this.$t('승인').toString()
        },
        {
            value: 2,
            text: this.$t('반려').toString()
        }
    ]

    filter: Array<FilterObject> = [
        {
            value: 0,
            text: this.$t('제목').toString()
        },
        {
            value: 1,
            text: this.$t('자원명').toString()
        },
        {
            value: 2,
            text: this.$t('요청자').toString()
        }
    ]


    onSearch() {
        //alert('search!')
        this.$emit('search', {
            keyword: this.keyword,
            filter: this.selectedFilter,
            status: this.selectedStatus
        })
    }
 
}
