import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reserve/store/types'
import * as usertypes from '@account/store/types';

import MiniCalendar from '@reserve/components/SideMenu/MiniCalendar.vue'
import ResourceGroup from '@reserve/components/SideMenu/ResourceGroup.vue'
import BookmarkItem from '@reserve/components/SideMenu/ResourceBookmarkItem.vue'

@Component({
    components: {
        MiniCalendar,
        ResourceGroup,
        BookmarkItem
    },

    computed: mapGetters({
        resourceGroups: types.EWS_RESOURCEGROUPS_TREE,
        selectedResourceKeys: types.SELECTED_RESOURCE_KEYS,
        user: usertypes.USER,
        bookmarkItems: types.EWS_RESOURCEBOOKMARK_LIST
    }),

    methods: mapActions({
        loadEwsResource: types.EWS_RESOURCEGROUPS_LOAD,
        selectResource: types.TOGGLE_RESOURCE_SELECT,
        loadResourceBookmark: types.EWS_RESOURCEBOOKMARK_LOAD
    })    
})
export default class SideMenu  extends Vue {
    loadEwsResource!: () => Promise<ewsResource.EwsResourceItem[]>;
    selectResource!: (payload: any) => void;    
    loadResourceBookmark!: (payload: object) => Promise<reserve.ResourceBookmark[]>;

    user!: account.TUser;

    resourceGroups!: ewsResource.EwsResourceItem[];
    bookmarkItems!: reserve.ResourceBookmark[];
    selectedResourceKeys!: string[];   
    
    selectedTabType: string = 'F';

    created() {
        this.loadEwsResource().then(res => {
            //2022-06-15 요청으로 인하여 최초 기본 선택 값 제거
            //this.selectOnFirst(res);
        });
    
        // 즐겨찾기 목록 조회
        this.loadResourceBookmark({ personCode: this.user.personCode, comCode: this.user.comCode }).then(res => {
            if (res && res.length > 0){
                this.selectedTabType = 'F';
            }
            else {
                this.selectedTabType = 'R';
            }
        });
    }

    selectOnFirst(resources: ewsResource.EwsResourceItem[]) {
        var select = resources.filter(r => r.mailboxType === 'Mailbox');

        if(this.selectedResourceKeys.length == 0 && select.length > 0) {
            this.selectResource({ id: select[0].uniqueId, select: true });
        }
    }

    reserveResource() {
        this.$router.push({
            name: 'reserve-write'
        })
    }

    // 탭 클릭 이벤트
    onResourceTabClick(tabType: string){
        this.selectedTabType = tabType;
    }
}