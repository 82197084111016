import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as accountTypes from '@account/store/types';
import * as types from '@reservedb/store/types'
import { EnumCalendarViewType } from '../types';
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';

import DatePicker from '@appbase/components/DatePicker/DatePicker.vue';

@Component({
    components: {
        DatePicker
    },

    computed: mapGetters({
        current: accountTypes.CURRENT,
        isLoading: types.CALENDAR_LOADING,
        viewMode: types.CALENDAR_VIEWMODE,
        currentDate: types.CALENDAR_DATE_CURRENT,
        rightPaneOpen: types.CALENDAR_RIGHTPANE_OPEN,
        leftPaneOpen: types.CALENDAR_LEFTPANE_OPEN,
        selectedResourceId: types.RESOURCE_SELECTED_RESOURCEIDS,
        listViewOpen: types.MAIN_LISTVIEW_OPEN
    }),

    methods: {
        ...mapActions({
            selectDate: types.CALENDAR_DATE_SELECTRELOAD,
            toggleRightPane: types.CALENDAR_TOGGLE_RIGHTPANE,
            toggleLeftPane: types.CALENDAR_TOGGLE_LEFTPANE,
            toggleListView: types.TOGGLE_MAIN_LISTVIEW
        }),
        ...mapMutations({
            searchSetUpdate: types.CALENDAR_SEARCHSET_UPDATE,
        })
    }

})
export default class ViewHeader extends Vue {
    selectDate!: (dateItem: reservedb.DateItem) => Promise<any>;
    toggleRightPane!: (payload: boolean) => void;
    toggleLeftPane!: (payload: boolean) => void;
    // searchEvents!: (payload?: reserve.ReserveSearchSet) => Promise<any>;
    searchSetUpdate!: (payload: reservedb.ReserveSearchSet) => void;
    toggleListView!: (payload: boolean) => void;

    selectedResourceId!: Array<number>
    current!: account.TCurrent;
    isLoading!: boolean;
    currentDate!: reservedb.DateItem;
    viewMode!: EnumCalendarViewType;
    rightPaneOpen!: boolean;
    leftPaneOpen!: boolean;
    // searchSet!: reserve.ReserveSearchSet;
    listViewOpen!: boolean;

    showSearchModal: boolean = false;
    modalSearchSet: reservedb.ReserveSearchSet = {
        reserveContent: "",
        reserveTitle: "",
        reserverName: "",
        searchStart: uGetDateFormat('yyyy-MM-dd', IEDate(new Date())),
        searchEnd: uGetDateFormat('yyyy-MM-dd', IEDate(new Date())),
    }

    get routeName() {
        return this.$route.name;
    }

    get currentMonthText() {
        return this.current.userConfig.language === 'ko-KR' ?
            uGetDateFormat('yyyy월 MM월', IEDate(this.currentDate.date)) :
            uGetDateFormat('MM/yyyy', IEDate(this.currentDate.date));
    }

    created() {
        var today = new Date();
        // this.modalSearchSet.searchStart = uGetDateFormat('yyyy-MM-dd', IEDate(today));

        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(endDate.getDate() - 1);
        // this.modalSearchSet.searchEnd = uGetDateFormat('yyyy-MM-dd', IEDate(endDate));
    }

    mounted() {
        this.$nextTick(() => {
            this.syncLeftSideBar();
        })
    }

    syncLeftSideBar() {
        var navElem = document.getElementById('snb') as HTMLDivElement;

        if (this.leftPaneOpen) {
            navElem.classList.add('sidenav-on');
            navElem.classList.remove('sidenav-off');
        } else {
            navElem.classList.add('sidenav-off');
            navElem.classList.remove('sidenav-on');
        }
    }

    changeView(type: string) {
        if (this.isLoading)
            return;

        // MENUID 파라메터가 있으면 전달한다.
        const query = this.$route.query;
        if (query) {
            this.$router.push({ name: type, query: { ...query } });
        }
        else {
            this.$router.push({ name: type });
        }
    }

    onToggleLeftPane(toggle: boolean) {
        this.toggleLeftPane(toggle);
        this.syncLeftSideBar();
    }

    goToday() {
        if (this.isLoading)
            return;

        var todayItem: reservedb.DateItem = {
            ...this.currentDate,
            date: new Date()
        }

        this.selectDate(todayItem);
    }

    goNext() {

        if (this.isLoading)
            return;

        var nextItem: reservedb.DateItem = {
            ...this.currentDate
        }

        switch (this.viewMode) {
            case EnumCalendarViewType.Month:
                nextItem.date.setMonth(nextItem.date.getMonth() + 1);
                break;
            case EnumCalendarViewType.Week:
                nextItem.date.setDate(nextItem.date.getDate() + 7);
                break;
            case EnumCalendarViewType.Daily:
                nextItem.date.setDate(nextItem.date.getDate() + 1);
                break;
        }

        this.selectDate(nextItem);
    }

    goPrev() {

        if (this.isLoading)
            return;

        var prevItem: reservedb.DateItem = {
            ...this.currentDate
        }

        switch (this.viewMode) {
            case EnumCalendarViewType.Month:
                prevItem.date.setMonth(prevItem.date.getMonth() - 1);
                break;
            case EnumCalendarViewType.Week:
                prevItem.date.setDate(prevItem.date.getDate() - 7);
                break;
            case EnumCalendarViewType.Daily:
                prevItem.date.setDate(prevItem.date.getDate() - 1);
                break;
        }

        this.selectDate(prevItem);
    }

    onToggleRighePane(toggle: boolean) {
        this.toggleRightPane(toggle);
    }

    async onSearchClick() {

        if (this.selectedResourceId.length == 0) {
            await (window as any).alertAsync(this.$t("선택된_자원이_없습니다").toString())
            return
        }


        this.searchSetUpdate(this.modalSearchSet);
        // this.searchEvents()
        //     .then(res => this.$router.push({ name: 'reserve-search' }));

        this.$router.push({
            name: 'reservedb-search',
            query: this.$route.query
        })
    }

    toggleSearchModal() {
        // modalSearchSet: reserve.ReserveSearchSet = {
        //     reserveContent: "",
        //     reserveTitle: "",
        //     reserverName: "",
        //     searchStart: uGetDateFormat('yyyy-MM-dd', IEDate(new Date())),
        //     searchEnd: uGetDateFormat('yyyy-MM-dd', IEDate(new Date())),
        // }
        //검색조건 초기화

        const baseDate = new Date()
        const now = new Date()
        const endDate = baseDate.setMonth(baseDate.getMonth() + 1)


        this.modalSearchSet.reserveContent = ""
        this.modalSearchSet.reserveTitle = ""
        this.modalSearchSet.reserverName = ""
        this.modalSearchSet.searchStart = uGetDateFormat('yyyy-MM-dd', IEDate(now))
        this.modalSearchSet.searchEnd = uGetDateFormat('yyyy-MM-dd', IEDate(endDate))



        this.showSearchModal = !this.showSearchModal;
    }

    onListViewClick() {
        this.toggleListView(!this.listViewOpen);
    }
}