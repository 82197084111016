import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as appbaseTypes from '@appbase/store/types';
import api from '@hr/api/index'
//import ModalSlotLayout from './ModalSlotLayout.vue';
import ModalSlotLayout from '@appbase/containers/ModalContainer/ModalSlotLayout.vue'
import modalHelper from '@appbase/util/modal-helper'
import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import {FormGroup, TextBoxField} from '@appbase/components/forms/index'
import { createEventBus, removeEventBus } from '@appbase/util/eventBus';
import { openPopup } from '@appbase/util';

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items? : orgmap.TWishContainerItem[]
}

export interface TWishContainerOptions {
    addableDept: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const LEAVEMANAGE_ORG_POP = '$leavemanage/org/popup';

@Component({
    components: {
        ModalSlotLayout,
        ModalLayout,
        FormGroup,
        TextBoxField
    },
    computed: {
        ...mapGetters({
            isTeams: appbaseTypes.TEAMS,
        })
    } 
})
export default class Modify extends Vue {

    @Prop({ type: Object }) value!: any;

    isModal?: boolean = false;
    isTeams!: boolean
    
    targetId: string = ''

    leavemasterData: any = {
    }

    personId: string = '';

    get isCNUser(){
        return this.leavemasterData.personCode.startsWith('C04')
    }

    get isINNIUser(){
        return this.leavemasterData.personCode.startsWith('C05')
    }
    
    // 조직도 오픈
    onOpenOrg(){
        const orgmapOption: TWishContainerOptions = {
            addableDept: false,
            containers: [
               { id: 0, name: this.$t('사용자').toString(), height: '1' }               
            ] 
        }

        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode', 'title'],
        }

        openPopup('reserveRecipients', '/orgmap', 
            { event: `${LEAVEMANAGE_ORG_POP}`, option: orgmapOption, props }, 
            { width: 1024, height: 768 }
        );
    }

    handlePopupResult(data: any){
        if (data && data.length > 0){
            var orgDataResult: any[] = data[0].items;
            if (orgDataResult && orgDataResult.length > 0){
                this.leavemasterData.personCode = orgDataResult[0].personCode;
                this.leavemasterData.displayName = orgDataResult[0].name;
                this.leavemasterData.deptName = orgDataResult[0].deptName;
            }
        }

        let personCode: string = this.leavemasterData.personCode;
        let index: number = personCode.indexOf('_')
        this.personId = personCode.substring(index + 1);
    }

    save(){
        let _this = this;

        this.$nextTick(async ()=>{
            // 유효성 검사

            if (_this.targetId != '0'){
                if(_this.leavemasterData.assignDay===''||_this.leavemasterData.extraSpend===''){
                    await (window as any).alertAsync(_this.$t('숫자를 입력하세요'));
                    return;
                }
            }
            else {
                if (_this.leavemasterData.personCode === '' || _this.leavemasterData.year === '' || 
                    _this.leavemasterData.assignDay === '' || _this.leavemasterData.extraSpend === '' )
                {
                    await (window as any).alertAsync('필수입력 값이 누락되었습니다.');
                    return;
                }
            }

            if (_this.targetId == '0') {
                // 등록
                let payload: Leave.leaveManageReq = {
                    personCode: _this.leavemasterData.personCode,
                    year: parseInt(_this.leavemasterData.year),
                    assignDay: parseFloat(_this.leavemasterData.assignDay),
                    extraSpend: parseFloat(_this.leavemasterData.extraSpend),
                    paidLeave: parseFloat(_this.leavemasterData.paidLeave),
                    paidExtraSpend: parseFloat(_this.leavemasterData.paidExtraSpend),
                    seniorityLeave: parseFloat(_this.leavemasterData.seniorityLeave),
                    seniorityExtraSpend: parseFloat(_this.leavemasterData.seniorityExtraSpend),
                    rewardLeave: parseFloat(_this.leavemasterData.rewardLeave),
                    rewardLeaveExtraSpend: parseFloat(_this.leavemasterData.rewardLeaveExtraSpend)
                }
                api.leave.addLeaveManage(payload)
                .then((res) => {
                    if (res) {
                        alert(_this.$t('저장_하였습니다'));
                        _this.handleClose()
                    }
                    else {
                        alert(_this.$t('저장에_실패하였습니다'));
                    }
                })
            } 
            else {
                // 수정
                api.leave.setLeaveManageAdmin({
                    Id: _this.leavemasterData.id,
                    AssignDay: parseFloat(_this.leavemasterData.assignDay),
                    ExtraSpend: parseFloat(_this.leavemasterData.extraSpend),
                    PaidLeave: parseFloat(_this.leavemasterData.paidLeave),
                    PaidExtraSpend: parseFloat(_this.leavemasterData.paidExtraSpend),
                    SeniorityLeave: parseFloat(_this.leavemasterData.seniorityLeave),
                    SeniorityExtraSpend: parseFloat(_this.leavemasterData.seniorityExtraSpend),
                    RewardLeave: parseFloat(_this.leavemasterData.rewardLeave),
                    RewardLeaveExtraSpend: parseFloat(_this.leavemasterData.rewardLeaveExtraSpend),
                    ModifyUser: ''
                }).then(async res=>{
                    if(res==0){
                        await (window as any).alertAsync(_this.$t('휴가정보수정성공'));
                        _this.handleClose()
                    }else{
                        await (window as any).alertAsync(_this.$t('휴가정보수정실패')+'\n' + res);
                    }
                })
            }             
        })

    }

    handleClose() {
        if(this.isModal){
            modalHelper.close();
        }
        else{
            window.close()
        }
        
    }

    created(){
        var paramValue;
        if(this.value) {
            paramValue = this.value;
            this.isModal = true;
        }
        else{
            paramValue = this.$route.query
        }

        createEventBus(window, `${LEAVEMANAGE_ORG_POP}`, this.handlePopupResult);
    }

    beforeDestroy() {
        removeEventBus(window, `${LEAVEMANAGE_ORG_POP}`, this.handlePopupResult);
    }

    mounted(){
        this.targetId = this.$route.query.id as string;

        if (this.targetId != '0')
        {
            api.leave.getLeaveManageAdmin(this.targetId).then(res=>{
                this.leavemasterData = res;

                let personCode: string = this.leavemasterData.personCode;
                let index: number = personCode.indexOf('_')
                this.personId = personCode.substring(index + 1);
            });
        }
        else {
            this.leavemasterData = {
                personCode: '',
                displayName: '',
                deptName: '',
                year: (new Date().getFullYear()),
                assignDay: 0,
                spendDay: 0,
                extraSpend: 0,
                remainDay: 0,

                paidLeave: 0,
                paidLeaveSpendDay: 0,
                paidExtraSpend: 0,
                paidLeaveRemainDay: 0,
                seniorityLeave: 0,
                seniorityLeaveSpendDay: 0,
                seniorityExtraSpend: 0,
                seniorityLeaveRemainDay: 0,
                rewardLeave: 0,
                rewardLeaveSpendDay: 0,
                rewardLeaveExtraSpend: 0,
                rewardLeaveRemainDay: 0
            }
        }
    }
}