import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapMutations, mapGetters } from 'vuex';
import * as types from '@reservedb/store/types'

import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'


@Component({
    components: {
        DatePicker
    },

    computed: mapGetters({
        searchSet: types.CALENDAR_SEARCHSET
    }),

    methods: mapMutations({
        searchSetUpdate: types.CALENDAR_SEARCHSET_UPDATE
    })
})
export default class SearchPanel  extends Vue {
    searchSetUpdate!: (payload: reservedb.ReserveSearchSet) => void;

    searchSet!: reservedb.ReserveSearchSet;

    searchStart: string = "";
    searchEnd: string = "";
    mReserverName: string = "";
    mTitle: string = "";
    mContent: string = "";

    created() {
        this.searchStart = this.searchSet.searchStart;
        this.searchEnd = this.searchSet.searchEnd;
        this.mReserverName = this.searchSet.reserverName;
        this.mTitle = this.searchSet.reserveTitle;
        this.mContent = this.searchSet.reserveContent;
    }

    onSearchClick() {
        var searchSet = <reservedb.ReserveSearchSet> {
            searchStart: this.searchStart,
            searchEnd: this.searchEnd,
            reserverName: this.mReserverName, 
            reserveTitle: this.mTitle,
            reserveContent: this.mContent
        }

        this.searchSetUpdate(searchSet);
        this.$emit('search', searchSet);
    }
}