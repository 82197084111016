import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { getAmpm, getFullTimetext, getTimetext } from '@reserve/disp-helper';
import { openReserveCalendarItem } from '@reserve/popup-handler';
import modalHelper from '@appbase/util/modal-helper';
import ReserveHelper from '@reserve/reserve-helper';

import OccurrenceSelect from '@reserve/components/OccurrenceSelect.vue';

@Component({
})
export default class WeekItem  extends Vue {
    @Prop({ type: Object }) item!: reserve.CalendarItem;

    colorClass: any = {};    

    created() {
        this.colorClass[this.item.color] = true;
    }

    getTimetext(eventTime: string) {
        return getTimetext(eventTime);
    }

    getAmpm(eventTime: string) {
        return getAmpm(eventTime);
    }

    getFullTimetext() {        
        return getFullTimetext(this.item);
    }

    async onEventDblClick() {
        var isUseAuth = await ReserveHelper.checkDetailViewAuth(this.item.id, this.item.resourceUpn);
        if (isUseAuth){
            if (this.item.appointmentType == 'Occurrence' || this.item.appointmentType == 'Exception') {
                modalHelper.show({ component: OccurrenceSelect, force: true, value: this.item });
            } else {
                openReserveCalendarItem(this.item);
            }
        }
    }
}