import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reserve/store/types'

import ResourceItem from './ResourceItem.vue';

@Component({
    components: {
        ResourceItem
    },

    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        selectedResourceKeys: types.SELECTED_RESOURCE_KEYS
    }),

    methods: mapActions({
        selectGroup: types.TOGGLE_RESOURCEGROUP_SELECT
    })
})
export default class ResourceGroup  extends Vue {
    @Prop({ type: Object }) item!: ewsResource.EwsResourceItem;

    selectGroup!: (payload: any) => void;

    selectedResourceKeys!: string[];
    isLoading!: boolean;
    selected: boolean = false;
    isCollapsed : boolean = false

    @Watch('selectedResourceKeys')
    OnSelectChanged(newVal: string[]) {
        this.selected = newVal.indexOf(this.item.uniqueId) >= 0;
    }

    created() {
        this.selected = this.item.selected || false;
    }

    selectChanged() {
        this.selectGroup({ id: this.item.uniqueId, select: this.selected });
    }


     

}