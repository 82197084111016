import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as appbaseTypes from '@appbase/store/types';
import api from '@hr/api/index'
//import ModalSlotLayout from './ModalSlotLayout.vue';
import ModalSlotLayout from '@appbase/containers/ModalContainer/ModalSlotLayout.vue'
import modalHelper from '@appbase/util/modal-helper'
import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'
import {FormGroup, TextBoxField} from '@appbase/components/forms/index'
import * as dateutil from '@appbase/util/date-helper';
import { dispatchEventBus, openPopup } from '@appbase/util';


@Component({
    components: {
        ModalSlotLayout,
        ModalLayout,
        FormGroup,
        TextBoxField
    },
    computed: {
        ...mapGetters({
            isTeams: appbaseTypes.TEAMS,
        })
    } 
})
export default class Detail extends Vue {

    @Prop({ type: Object }) value!: any;

    isModal?: boolean = false;
    isTeams!: boolean
    
    targetId: string = ''

    status:string='';
    startDate:string = '';
    endDate:string = '';

    EVENT_OPEN_APM: string = 'EVENT_OPEN_APM';

    data: any = {}

    personId: string = '';

    async openApmPopup(){
        if(window.opener){
            dispatchEventBus(window.opener, this.EVENT_OPEN_APM, '');
            window.close();
        }
        else if(window.parent){
            dispatchEventBus(window.parent, this.EVENT_OPEN_APM, '');
            window.close();
        }
    }

    handleClose() {
        if(this.isModal){
            modalHelper.close();
        }
        else{
            window.close()
        }
        
    }

    @Watch('data')
    onChangedItem(){
      if(this.data.isCancel){
        this.status = this.$t('취소') as string;
      }else if(this.data.isConfirm){
        this.status = this.$t('승인완료') as string;
      }else{
        this.status = this.$t('결재진행중') as string;
      }
  
      this.startDate = this.data.startDate.split(' ')[0];
      this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD H:i', dateutil.IEDate(this.data.startDate));
      this.endDate = this.data.endDate.split(' ')[0];
      this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD H:i', dateutil.IEDate(this.data.endDate));
    }

    created(){
        var paramValue;
        if(this.value) {
            paramValue = this.value;
            this.isModal = true;
        }
        else{
            paramValue = this.$route.query
        }

    }

    mounted(){
        this.targetId = this.$route.query.id as string;

        api.leave.getLeaveAdmin(this.targetId).then(res=>{
            this.data = res;

            let personCode: string = this.data.personCode;
            let index: number = personCode.indexOf('_')
            this.personId = personCode.substring(index + 1);
        });

    }
}