import router from '@/router'
import { IEDate, openPopup, uGetDateFormat } from '@appbase/util';
import { EnumCalendarViewType } from './types';
import store from '@reservedb/store'
import { Dictionary } from 'vue-router/types/router';

export const openReserveResource = function (reserveDate: Date) {
    var calcStartTime = uGetDateFormat("H:00:00", reserveDate);
    var calcEndTime = uGetDateFormat("H:30:00", reserveDate);

    if (reserveDate.getMinutes() >= 30) {
        calcStartTime = uGetDateFormat("H:30:00", reserveDate);
        reserveDate.setHours(reserveDate.getHours() + 1);
        calcEndTime = uGetDateFormat("H:00:00", reserveDate);
    }

    var param: reservedb.ReserveResourceParam = {
        eventStart: uGetDateFormat('yyyy-MM-dd', IEDate(reserveDate)),
        eventEnd: uGetDateFormat('yyyy-MM-dd', IEDate(reserveDate)),
        eventStartTime: calcStartTime,
        eventEndTime: calcEndTime
    }

    router.push({
        name: 'reserve-write',
        query: {
            param: encodeURIComponent(btoa(JSON.stringify(param)))
        }
    })
}

export const openReserveCalendarItem = function (reserve: reservedb.ResponseBookingItem, masterOccurrence?: string) {
    // var openQuery = masterOccurrence ? 
    //     { reserveId: reserve.id, resource: reserve.resourceUpn, open: masterOccurrence } :
    //     { reserveId: reserve.id, resource: reserve.resourceUpn };

    var currentQuery = router.currentRoute.query
    let openQuery = masterOccurrence ?
    { ...currentQuery, bookingId: reserve.resourceBookingId.toString(), open: masterOccurrence } : { ...currentQuery, bookingId: reserve.resourceBookingId.toString() }  


    router.push({
        name: 'reservedb-write',
        query: openQuery
    });
}

export const handleClose = function (quertStr: Dictionary<string> | null, isPopup?: boolean) {
    if (isPopup) {
        window.close();
    } else {
        if(quertStr != null){
            switch (store.state.calendarMode) {
                case EnumCalendarViewType.Month:
                    router.push({ name: 'reservedb-month', query: quertStr });
                    break;
                case EnumCalendarViewType.Week:
                    router.push({ name: 'reservedb-week', query: quertStr });
                    break;
                default:
                    router.push({ name: 'reservedb-day', query: quertStr });
                    break;
            }
        }
       
    }
}

export const openReserveWindow = function (query: string) {
    
    openPopup('reserve-write', `/reserveDbPopup/write?${query}`, {}, {
        width: 850,
        height: 700
    })
}