import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@reserve/store/types'
import { handleClose, openReserveCalendarItem, openReserveResource } from '@reserve/popup-handler';
import { getAmpm, getFullTimetext, getTimetext } from '@reserve/disp-helper';
import ReserveHelper from '@reserve/reserve-helper';
import modalHelper from '@appbase/util/modal-helper';
import { IEDate } from '@appbase/util';

import OccurrenceSelect from '@reserve/components/OccurrenceSelect.vue';

@Component({
    computed: mapGetters({
        isLoading: types.CALENDAR_LOADING,
        isDailyEventLoading: types.CALENDAR_DAILYEVENT_LOADING
    }),
    methods: mapActions({
        selectDate: types.CALENDAR_DATE_SELECT
    })
})
export default class MonthItem  extends Vue {
    @Prop({ type: Object }) item!: reserve.DateItem;

    selectDate!: (payload: any) => Promise<any>;

    isLoading!: boolean;
    isDailyEventLoading!: boolean;

    eventsModel: reserve.CalendarItem[] = [];
    dayTextHash: any = {
        0: "일요일", 1: "월요일", 2: "화요일", 3: "수요일", 4: "목요일", 5: "금요일", 6: "토요일" 
    }

    @Watch('item')
    itemChanged(newVal: reserve.DateItem) {
        this.eventsModel = newVal.events.filter(e => e.id !== 'null');
    }

    created() {
        this.dayTextHash = {
            0: this.$t('W_Schedule_Sunday').toString(),
            1: this.$t('W_Schedule_Monday').toString(),
            2: this.$t('W_Schedule_Tuesday').toString(),
            3: this.$t('W_Schedule_Wednesday').toString(),
            4: this.$t('W_Schedule_Thursday').toString(),
            5: this.$t('W_Schedule_Friday').toString(),
            6: this.$t('W_Schedule_Saturday').toString()            
        }

        if(this.item != null)
            this.itemChanged(this.item);
    }

    getDate() {
        return this.item.date.getDate();
    }

    getTimetext(eventTime: string) {
        return getTimetext(eventTime);
    }

    getAmpm(eventTime: string) {
        return getAmpm(eventTime);
    }

    getFullTimetext(event: reserve.CalendarItem) {        
        return getFullTimetext(event);
    }

    onSelectDateClick() {
        if(this.isDailyEventLoading)
            return;

        this.selectDate(this.item);
    }

    onSelectDblClick() {
        if(this.isLoading)
            return;
        
        var today = new Date();
        var openDate = IEDate(this.item.date);
        openDate.setHours(today.getHours());

        openReserveResource(openDate);
    }

    async onEventDblClick(event: reserve.CalendarItem) {
        var isUseAuth = await ReserveHelper.checkDetailViewAuth(event.id, event.resourceUpn);
        if (isUseAuth){
            if (event.appointmentType == 'Occurrence' || event.appointmentType == 'Exception') {
                modalHelper.show({ component: OccurrenceSelect, force: true, value: event });
            } else {
                openReserveCalendarItem(event);
            }
        }
    }

    getColorClass(event: reserve.CalendarItem) {
        var colorClass: any = {};
        colorClass[event.color] = true; 

        return colorClass;
    }

    onMoreClick() {
        this.selectDate(this.item).then(res => {
                handleClose();
            });
    }
}