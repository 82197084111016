import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@reserve/store/types'
import * as accountTypes from '@account/store/types'
import { IEDate, uGetDateFormat } from '@/modules/@appbase/util';

@Component({
    computed: mapGetters({
        current: accountTypes.CURRENT,
        isLoading: types.CALENDAR_LOADING,
        isDailyEventLoading: types.CALENDAR_DAILYEVENT_LOADING,
        monthMatrix: types.MINI_CALENDAR_MONTH_MATRIX_LIST,
        calendarSetting: types.CALENDAR_SETTING,
        currentDate: types.CALENDAR_DATE_CURRENT,
    }),

    methods: mapActions({
        loadMiniCalendar: types.MINI_CALENDAR_MONTH_MATRIX_LOAD,
        selectDate: types.CALENDAR_DATE_SELECT,
        selectDateLoad: types.CALENDAR_DATE_SELECTRELOAD
    })
})
export default class MiniCalendar  extends Vue {
    loadMiniCalendar!: () => void;
    selectDate!: (payload: any) => Promise<any>;
    selectDateLoad!: (payload: any) => Promise<any>;

    current!: account.TCurrent;
    isLoading!: boolean;
    isDailyEventLoading!: boolean;
    currentDate!: reserve.DateItem;
    calendarSetting!: reserve.CalendarSetting;
    monthMatrix!: Array<Array<reserve.DateItem>>;

    weekLabel: any = {
        0: { key: 0, text: "일" },
        1: { key: 1, text: "월" },
        2: { key: 2, text: "화" },
        3: { key: 3, text: "수" },
        4: { key: 4, text: "목" },
        5: { key: 5, text: "금" },
        6: { key: 6, text: "토" }
    }
    
    weeklist: any[] = [];

    get currentMonthText() {
        return this.current.userConfig.language === 'ko-KR' ?
            uGetDateFormat('yyyy월 MM월', IEDate(this.currentDate.date)) :
            uGetDateFormat('MM/yyyy', IEDate(this.currentDate.date))
    }

    created() {
        this.initWeekLabel()
        this.mountWeekList();
        this.loadMiniCalendar();
    }

    initWeekLabel(){
        //달력 다국어 처리
        this.weekLabel = {
            0: { key: 0, text: this.$t("일").toString() },
            1: { key: 1, text: this.$t("월").toString() },
            2: { key: 2, text: this.$t("화").toString() },
            3: { key: 3, text: this.$t("수").toString() },
            4: { key: 4, text: this.$t("목").toString() },
            5: { key: 5, text: this.$t("금").toString() },
            6: { key: 6, text: this.$t("토").toString() }
        }
    }

    mountWeekList() {
        this.weeklist = [];

        for(var i = 0; i < 7; i++) {
            var idx = (this.calendarSetting.firstDay + i) % 7;
            this.weeklist.push(this.weekLabel[idx]);            
        }         
    }

    onSelectDate(item: reserve.DateItem) {
        if(this.isLoading || this.isDailyEventLoading)
            return;

        this.selectDate(item);
    }

    onPrevMonthClick() {
        if(this.isLoading || this.isDailyEventLoading)
            return;

        var prevItem: reserve.DateItem = {
            ...this.currentDate
        }

        prevItem.date.setMonth(prevItem.date.getMonth() - 1);
        this.selectDateLoad(prevItem);
    }

    onNextMonthClick() {
        if(this.isLoading || this.isDailyEventLoading)
            return;

        var nextItem: reserve.DateItem = {
            ...this.currentDate
        }

        nextItem.date.setMonth(nextItem.date.getMonth() + 1);
        this.selectDateLoad(nextItem);
    }

    onTodayClick() {
        if(this.isLoading || this.isDailyEventLoading)
            return;

        var todayItem: reserve.DateItem = {
            ...this.currentDate,
            date: new Date()
        }

        this.selectDateLoad(todayItem);
    }
}