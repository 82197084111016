import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import modalHelper from '@appbase/util/modal-helper'
import { openReserveCalendarItem } from '@reserve/popup-handler'

import ModalLayout from '@appbase/templates/ModalLayout/ModalLayout.vue'

@Component({
    components: {
        ModalLayout
    }
})
export default class OccurrenceSelect  extends Vue {
    @Prop({ type: Object }) value!: reserve.CalendarItem

    masterOccurrence: string = "occurrence";

    onOpenClick() {
        openReserveCalendarItem(this.value, this.masterOccurrence);
        modalHelper.close();
    }

    onCancelClick() {
        modalHelper.close();
    }
}