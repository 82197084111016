import router from '@/router'
import { IEDate, openPopup, uGetDateFormat } from '@appbase/util';
import { EnumCalendarViewType } from './types';
import store from '@reserve/store'

export const openReserveResource = function(reserveDate: Date) {
    var calcStartTime = uGetDateFormat("H:00:00", reserveDate);
    var calcEndTime = uGetDateFormat("H:30:00", reserveDate);        

    if(reserveDate.getMinutes() >= 30) {
        calcStartTime = uGetDateFormat("H:30:00", reserveDate);
        reserveDate.setHours(reserveDate.getHours() + 1);
        calcEndTime = uGetDateFormat("H:00:00", reserveDate);
    } 

    var param: reserve.ReserveResourceParam = {
        eventStart: uGetDateFormat('yyyy-MM-dd', IEDate(reserveDate)),
        eventEnd: uGetDateFormat('yyyy-MM-dd', IEDate(reserveDate)),
        eventStartTime: calcStartTime,
        eventEndTime: calcEndTime
    }

    router.push({
        name: 'reserve-write',
        query: {
            param: encodeURIComponent(btoa(JSON.stringify(param)))
        }
    })
}

export const openReserveCalendarItem = function(reserve: reserve.CalendarItem, masterOccurrence?: string) {   
    var openQuery = masterOccurrence ? 
        { reserveId: reserve.id, resource: reserve.resourceUpn, open: masterOccurrence } :
        { reserveId: reserve.id, resource: reserve.resourceUpn };
    
    router.push({
        name: 'reserve-write',
        query: openQuery
    });    
}

export const handleClose = function(isPopup?: boolean) {
    if(isPopup) {
        window.close();
    } else {
        switch(store.state.calendarMode) {
            case EnumCalendarViewType.Month:
                router.push({ name: 'reserve-month' });
                break;
            case EnumCalendarViewType.Week:
                router.push({ name: 'reserve-week' });
                break;
            default:
                router.push({ name: 'reserve-day' });
                break;
        }
    }
}

export const openReserveWindow = function() {
    openPopup('reserve-write', '/reservePopup/write', {}, {
        width: 1024,
        height: 700
    })
}